import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import "./adultsForm.css";

function PhysicalCharacteristics({ page, setPage, formData, setFormData }) {
  const { t } = useTranslation();
  const [activeButton, setActiveButton] = useState(false);

  useEffect(() => {
    if (
      formData.height !== "" &&
      formData.weight !== "" &&
      formData.gebder !== "" &&
      formData.yearOfBirth !== "" &&
      formData.PAL !== ""
    ) {
      setActiveButton(true);
    } else {
      setActiveButton(false);
    }
  });

  const handleSubmit = (event) => {
    event.preventDefault();
    setPage(page + 1);
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <div className="ruser-profile-font">
          {t("Physical_Characteristics")}{" "}
        </div>
        <div className="section-display">
          <label className="ruser-font">{t("Gender")}:</label>
          <label style={{ display: "block" }}>
            {" "}
            <input
              type="radio"
              name="gender"
              value={formData.gender}
              checked={formData.gender === "male"}
              onChange={(event) => setFormData({ ...formData, gender: "male" })}
              className="ruser-font"
            />{" "}
            {t("Male")}
          </label>
          <label style={{ display: "block" }}>
            <input
              type="radio"
              name="gender"
              value={formData.gender}
              checked={formData.gender === "female"}
              onChange={(event) =>
                setFormData({ ...formData, gender: "female" })
              }
              className="ruser-font"
            />{" "}
            {t("Female")}
          </label>
        </div>
        <div className="section-display">
          <div>
            <label className="ruser-font">{t("Year_of_Birth")}:</label>

            <input
              type="number"
              name="yearOfBirth"
              value={formData.yearOfBirth}
              onChange={(event) =>
                setFormData({ ...formData, yearOfBirth: event.target.value })
              }
              className="upline-field"
              min="1900"
              max="2030"
            />
          </div>
          <div>
            <label className="ruser-font">{t("Height_(m)")}:</label>

            <input
              type="number"
              name="height"
              value={formData.height}
              onChange={(event) =>
                setFormData({ ...formData, height: event.target.value })
              }
              className="upline-field"
              min="0.00"
              max="3.00"
              step="0.01"
            />
          </div>
          <div>
            <label className="ruser-font">{t("Weight_(kg)")}:</label>

            <input
              type="number"
              name="weight"
              value={formData.weight}
              onChange={(event) =>
                setFormData({ ...formData, weight: event.target.value })
              }
              className="upline-field"
              min="0"
              max="400"
              step="0.1"
            />
          </div>
        </div>

        <div className="ruser-profile-font">
          {t("Physical_Activity_Level_(PAL)")}:
        </div>
        <div className="section-display">
          <label style={{ display: "block" }}>
            <input
              type="radio"
              name="PAL"
              value={formData.PAL}
              checked={formData.PAL === "Sedentary"}
              onChange={(event) =>
                setFormData({ ...formData, PAL: "Sedentary" })
              }
            />{" "}
            {t("Sedentary")}{" "}
            <em style={{ fontSize: "small" }}>{t("Sedentary_info")}</em>
          </label>
          <label style={{ display: "block" }}>
            <input
              type="radio"
              name="PAL"
              value={formData.PAL}
              checked={formData.PAL === "Lightly active"}
              onChange={(event) =>
                setFormData({ ...formData, PAL: "Lightly active" })
              }
            />{" "}
            {t("Lightly active")}{" "}
            <em style={{ fontSize: "small" }}>{t("Lightly_active_info")}</em>
          </label>
          <label style={{ display: "block" }}>
            <input
              type="radio"
              name="PAL"
              value={formData.PAL}
              checked={formData.PAL === "Moderately active"}
              onChange={(event) =>
                setFormData({ ...formData, PAL: "Moderately active" })
              }
            />{" "}
            {t("Moderately active")}{" "}
            <em style={{ fontSize: "small" }}>{t("Moderately_active_info")}</em>
          </label>
          <label style={{ display: "block" }}>
            <input
              type="radio"
              name="PAL"
              value={formData.PAL}
              checked={formData.PAL === "Very active"}
              onChange={(event) =>
                setFormData({ ...formData, PAL: "Very active" })
              }
            />{" "}
            {t("Very active")}{" "}
            <em style={{ fontSize: "small" }}>{t("Very_active_info")}</em>
          </label>
          <label style={{ display: "block" }}>
            <input
              type="radio"
              name="PAL"
              value={formData.PAL}
              checked={formData.PAL === "Extra active"}
              onChange={(event) =>
                setFormData({ ...formData, PAL: "Extra active" })
              }
            />{" "}
            {t("Extra active")}{" "}
            <em style={{ fontSize: "small" }}>{t("Extra_active_info")}</em>
          </label>
        </div>
        <div className="nav-bar">
          <button
            className="form-button"
            onClick={() => {
              setPage(page - 1);
            }}
          >
            {t("Prev")}
          </button>
          <button
            className={activeButton ? "form-button" : "form-inactive-button"}
            type="submit"
          >
            {t("Next")}
          </button>
        </div>
      </form>
    </div>
  );
}

export default PhysicalCharacteristics;
