import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import jwt_decode from "jwt-decode";
import "./EdMaterialTopic.css";
import SideBar from "../../components/sideBar.js";
import Footer from "../../components/footer.js";
import EdMat14 from "../../images/EdMaterial/EdMat14.png";
import backgroundImage from "../../images/graphics/about2.jpg";
import LogoutAfterInactivity from "../../components/logoutAfterInactivity.js";
import { useTranslation } from "react-i18next";
import { motion } from "framer-motion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome } from "@fortawesome/free-solid-svg-icons";

function EdMaterialTopic14() {
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const [isMobile, setIsMobile] = useState(false);
  const navigate = useNavigate();
  const [authTokens, setAuthTokens] = useState(() =>
    localStorage.getItem("authTokens")
      ? JSON.parse(localStorage.getItem("authTokens"))
      : null
  );
  const [user, setUser] = useState(() =>
    localStorage.getItem("authTokens")
      ? jwt_decode(localStorage.getItem("authTokens"))
      : null
  );
  const [userProfileId, setUserProfileId] = useState(() =>
    localStorage.getItem("adult") ? localStorage.getItem("adult") : null
  );
  useEffect(() => {
    if (user == null || user.is_active == false || userProfileId == null) {
      navigate("/");
    } else {
      const handleResize = () => {
        setIsMobile(window.innerWidth <= 768);
      };

      handleResize(); // Initial check

      window.addEventListener("resize", handleResize);
      window.scrollTo({ top: 0, behavior: "smooth" });
      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }
  }, []);
  LogoutAfterInactivity();
  const topicNumber = 14;
  // Handlers for the arrows
  const goToPreviousPage = () => {
    const previousPageNumber = topicNumber - 1; // Calculate the previous page number
    navigate(`/topic${previousPageNumber}`); // Use the previous page number in the route
  };

  const goToNextPage = () => {
    const nextPageNumber = topicNumber + 1; // Calculate the next page number
    navigate(`/topic${nextPageNumber}`); // Use the next page number in the route
  };
  const goBack = () => {
    navigate(`/educationalMaterials`); // Use the back button
  };
  return (
    <div className="topic-rightpart">
      <div
        style={{
          backgroundSize: "cover", // This will resize the image to cover the entire element
          backgroundRepeat: "no-repeat", // This will prevent the image from repeating
          backgroundPosition: "center", // This will position the image at the center of the element
        }}
      >
        <div>
          <SideBar />
          <motion.div
            initial={{ x: "40%" }}
            animate={{ x: "0%" }}
            transition={{ duration: 0.3, ease: "easeOut" }}
            className="Topic-Page"
          >
            <div className="header-container">
              {/* Previous Arrow */}
              <button className="prev-arrow" onClick={goToPreviousPage}>
                &larr;
              </button>

              {/* Title and Subtitle */}
              <div className="title-container">
                <h1>{t(`Topic${topicNumber}Title`)}</h1>
                <h3>{t(`Topic${topicNumber}Subtitle`)}</h3>
              </div>

              {/* Next Arrow */}
              <button className="next-arrow" onClick={goToNextPage}>
                &rarr;
              </button>
            </div>
            <br></br>
            {currentLanguage === "en" && (
              <div>
                <p className="TopicDescription-font">
                  <p>
                    Don't eat if you're not really hungry! Always get used to
                    wondering if your desire to eat arises from a real
                    physiological need of your body or is only connected to an
                    emotional state, such as anxiety, fear, anger, stress,
                    boredom, or loneliness. Remember that while in the first
                    case the food introduced in a correct way and to satisfy a
                    sense of real hunger will leave you satisfied and full,
                    otherwise, if your hunger is just "nervous", you will
                    probably feel guilty after eating. Your eating behaviour is
                    regulated not only by physiological mechanisms of hunger and
                    satiety that allow you to achieve a balance between caloric
                    intake and energy consumption, but also by other
                    psychological and social factors that can have a significant
                    influence in determining what, how much and when you eat.
                    Emotional hunger is a short-circuit between emotional states
                    and food ingestion... often over-caloric ones; this is why
                    the need to eat to calm emotions is an obvious risk factor
                    for bulimic behaviours and obesity.{" "}
                  </p>
                </p>
                <img
                  src={EdMat14}
                  style={{ maxWidth: "20%", height: "auto" }}
                />
                <p className="TopicDescription-font">
                  <p>
                    Follow these simple tips to eat more consciously, taking
                    care of your relationship with food.
                  </p>
                  <ul>
                    <li>
                      Look for your "real hunger" and learn to recognize it from
                      "emotional hunger". If the hunger stimulus doesn't really
                      come from your stomach or if you have actually just eaten,
                      then yours is just the desire to eat and not real hunger!
                      Sit down and take a small sip of a glass of water to
                      relax.
                    </li>
                    <li>
                      Think of the consequences! Remember that after temporary
                      gratification always comes guilt and remorse!
                    </li>
                    <li>
                      Don't try to finish everything on your plate at any cost.
                      If you are full, you can save the food leftovers for the
                      following day.
                    </li>
                    <li>
                      Try not to eat while you're walking or before you sit at
                      the table... it's hard to be aware of how much you are
                      eating. While eating, sit down and take your time to enjoy
                      what you have on your plate.
                    </li>
                    <li>
                      Don't open the pantry or the fridge without any reason!
                      When you are in the kitchen, breathe and try to feel your
                      body... Listen to yourself to find out if you are really
                      hungry, thirsty, stressed, sad, or bored. Give yourself a
                      minute to distinguish real needs from desires.
                    </li>
                    <li>
                      When you recognize emotional hunger, don't indulge it but
                      try to distract yourself, for example, take a short walk
                      or have a conversation with some friends.
                    </li>
                    <li>
                      Never, for any reason, eat directly from the pack! If you
                      serve the food on a plate you will be able to better
                      appreciate what and how much you are eating.
                    </li>
                    <li>
                      Using smaller dishes will help you control the portions of
                      what you are eating.
                    </li>
                    <li>
                      Don't lose focus while you're eating, which is why it's
                      important to always turn off the TV and anything with a
                      screen before you start eating.
                    </li>
                    <li>
                      Set a timer and allow yourself at least 20 minutes to eat
                      a meal, or try eating with your non-dominant hand (or
                      chopsticks) to eat more slowly.
                    </li>
                  </ul>
                </p>
              </div>
            )}
            {currentLanguage === "es" && (
              <div>
                <p className="TopicDescription-font">
                  <p>
                    ¡No comas si no estás realmente hambriento! Acostúmbrate
                    siempre a preguntarte si tu deseo de comer surge de una
                    necesidad fisiológica real de tu cuerpo o sólo está
                    conectado a un estado emocional, como ansiedad, miedo, ira,
                    estrés, aburrimiento o soledad. Recuerda que mientras en el
                    primer caso la comida te dejará satisfecho y lleno, en el
                    segundo caso tu hambre es simplemente "nerviosa", y
                    probablemente te sentirás culpable después de comer. Tu
                    conducta alimentaria está regulada no sólo por mecanismos
                    fisiológicos de hambre y saciedad que te permiten alcanzar
                    un equilibrio entre la ingesta calórica y el consumo
                    energético, sino también por otros factores psicológicos y
                    sociales que pueden tener una influencia significativa a la
                    hora de determinar qué, cuánto y cuándo comes. El hambre
                    emocional es un cortocircuito entre los estados emocionales
                    y la ingestión de alimentos, a menudo excesivamente
                    calóricos; es por esto que la necesidad de comer para calmar
                    las emociones es un factor de riesgo evidente para las
                    conductas bulímicas y la obesidad.
                  </p>
                </p>
                <img
                  src={EdMat14}
                  style={{ maxWidth: "20%", height: "auto" }}
                />
                <p className="TopicDescription-font">
                  <p>
                    Sigue estos sencillos consejos para comer de forma más
                    consciente, cuidando tu relación con la comida.
                  </p>
                  <ul>
                    <li>
                      Busca tu "hambre real" y aprende a diferenciarla del
                      "hambre emocional". Si el estímulo del hambre no proviene
                      realmente de tu estómago o si justo acabas de comer,
                      entonces lo que estás experimentando es sólo el deseo de
                      comer y no hambre real. Siéntate y toma un pequeño sorbo
                      de un vaso de agua para relajarte.
                    </li>
                    <li>
                      ¡Piensa en las consecuencias! ¡Recuerda que después de una
                      gratificación temporal siempre viene la culpa y el
                      remordimiento!
                    </li>
                    <li>
                      No sientas la obligación de terminarte todo lo que tienes
                      en el plato. Si estás lleno, puedes guardar los restos de
                      comida para el día siguiente.
                    </li>
                    <li>
                      Intenta no comer mientras caminas o antes de sentarte a la
                      mesa... es difícil ser consciente de cuánto estás
                      comiendo. Mientras comes, siéntate y tómate tu tiempo para
                      disfrutar de lo que tienes en tu plato.
                    </li>
                    <li>
                      ¡No abras la despensa ni la nevera sin ningún motivo!
                      Cuando estés en la cocina, respira y trata de sentir tu
                      cuerpo... Escúchate a ti mismo para saber si realmente
                      tienes hambre, sed, estrés, tristeza o aburrimiento.
                      Tómese un minuto para distinguir las necesidades reales de
                      los deseos.
                    </li>
                    <li>
                      Cuando reconozcas el hambre emocional, no te dejes llevar,
                      pero trata de distraerte, por ejemplo, dando un paseo
                      corto o conversando con algunos amigos.
                    </li>
                    <li>
                      ¡Nunca, por ningún motivo, comas directamente del paquete!
                      Si te sirves la comida en un plato podrás apreciar mejor
                      qué y cuánto estás comiendo.
                    </li>
                    <li>
                      Usar platos más pequeños te ayudará a controlar las
                      porciones de lo que comes.
                    </li>
                    <li>
                      No pierdas la concentración mientras comes, por eso es
                      importante apagar siempre el televisor y cualquier
                      dispositivo con pantalla antes de empezar a comer.
                    </li>
                    <li>
                      Configura un cronómetro y tómate al menos 20 minutos para
                      comer, o intenta comer con la mano no dominante (o con los
                      palillos) para comer más lentamente.
                    </li>
                  </ul>
                </p>
              </div>
            )}{" "}
            {currentLanguage === "tr" && (
              <div>
                <p className="TopicDescription-font">
                  <p>
                    Gerçekten aç değilseniz yemeyin! Yemek yeme arzunuzun
                    vücudunuzun gerçek bir fizyolojik ihtiyacından mı
                    kaynaklandığını yoksa yalnızca kaygı, korku, öfke, stres,
                    can sıkıntısı veya yalnızlık gibi duygusal bir durumla mı
                    bağlantılı olduğunu merak etmeye her zaman alışın. İlk
                    durumda, doğru bir şekilde ve gerçek açlık hissini tatmin
                    edecek şekilde verilen yiyeceklerin sizi tok ve tok
                    bırakacağını unutmayın, aksi takdirde açlığınız sadece
                    duygusal açlık ise, yemekten sonra muhtemelen kendinizi
                    suçlu hissedeceksiniz. Yeme davranışınız, yalnızca kalori
                    alımı ile enerji tüketimi arasında bir denge kurmanıza
                    olanak tanıyan fizyolojik açlık ve tokluk mekanizmaları
                    tarafından değil, aynı zamanda neyin, ne kadar ve ne zaman
                    olduğunun belirlenmesinde önemli etkiye sahip olabilecek
                    diğer psikolojik ve sosyal faktörler tarafından da
                    düzenlenir. Duygusal açlık, duygusal durumlar ile yiyecek
                    alımı arasında bir kısa devredir... Bu yiyecekler genellikle
                    aşırı kalorili olanlardır. Duyguları sakinleştirmek için
                    yemek yeme ihtiyacının bulimik davranışlar ve obezite için
                    bariz bir risk faktörü olmasının nedeni budur.
                  </p>
                </p>
                <img
                  src={EdMat14}
                  style={{ maxWidth: "20%", height: "auto" }}
                />
                <p className="TopicDescription-font">
                  <p>
                    Yiyecekle ilişkinize dikkat ederek daha bilinçli yemek yemek
                    için bu basit ipuçlarını izleyin.
                  </p>
                  <ul>
                    <li>
                      "Gerçek açlığınızı" arayın ve onu "duygusal açlıktan"
                      ayırmayı öğrenin. Açlık uyarısı gerçekten midenizden
                      gelmiyorsa veya gerçekten yeni yemek yediyseniz, o zaman
                      sizinki gerçek açlık değil, sadece yemek yeme arzusudur!
                      Oturun ve rahatlamak için bir bardak sudan küçük bir yudum
                      alın.
                    </li>
                    <li>
                      Sonuçlarını düşünün! Geçici tatminlerden sonra her zaman
                      suçluluk ve pişmanlık geldiğini unutmayın!
                    </li>
                    <li>
                      Ne pahasına olursa olsun tabağınızdaki her şeyi bitirmeye
                      çalışmayın. Eğer toksanız, yemek artıklarını bir sonraki
                      güne saklayabilirsiniz.
                    </li>
                    <li>
                      Yürürken veya ayakta yemek yememeye çalışın... ne kadar
                      yediğinizin farkında olmak zordur. Yemek yerken oturun ve
                      tabağınızdakilerin tadını çıkarmaya zaman ayırın.
                    </li>
                    <li>
                      Kilerinizi veya buzdolabınızı sebepsiz yere açmayın!
                      Mutfağa girdiğinizde nefes alın ve vücudunuzu hissetmeye
                      çalışın... Gerçekten aç, susuz, stresli, üzgün veya
                      sıkılmış olup olmadığınızı öğrenmek için kendinizi
                      dinleyin. Gerçek ihtiyaçları arzulardan ayırmak için
                      kendinize bir dakika verin.
                    </li>
                    <li>
                      Duygusal açlığı fark ettiğinizde, bu duruma boyun eğmeyin,
                      dikkatinizi dağıtmaya çalışın; örneğin kısa bir yürüyüşe
                      çıkın veya birkaç arkadaşınızla sohbet edin.
                    </li>
                    <li>
                      Hiçbir zaman, ne sebeple olursa olsun, doğrudan paketten
                      yemek yemeyin! Yemeği tabakta servis ederseniz neyi, ne
                      kadar yediğinizi daha iyi anlayabilirsiniz.
                    </li>
                    <li>
                      Daha küçük tabaklar kullanmak, yediğiniz porsiyonları
                      kontrol etmenize yardımcı olacaktır.
                    </li>
                    <li>
                      Yemek yerken odağınızı kaybetmeyin. Bu nedenle, yemeğe
                      başlamadan önce TV'yi ve ekranlı herhangi bir şeyi daima
                      kapatmak önemlidir.
                    </li>
                    <li>
                      Bir zamanlayıcı ayarlayın ve yemek yemek için kendinize en
                      az 20 dakika izin verin veya daha yavaş yemek için baskın
                      olmayan elinizle (veya yemek çubuklarıyla) yemeyi deneyin.
                    </li>
                  </ul>
                </p>
              </div>
            )}
            {currentLanguage === "fr" && (
              <div>
                <p className="TopicDescription-font">
                  <p>
                    Ne mangez pas si vous n'avez pas vraiment faim !
                    Habituez-vous toujours à vous demander si votre envie de
                    manger découle d'un véritable besoin physiologique de votre
                    corps ou est simplement liée à un état émotionnel, tel que
                    l'anxiété, la peur, la colère, le stress, l'ennui ou la
                    solitude. Souvenez-vous que tandis que dans le premier cas,
                    la nourriture introduite de manière correcte et pour
                    satisfaire un vrai sentiment de faim vous laissera rassasié
                    et satisfait, sinon, si votre faim est simplement
                    "nerveuse", vous vous sentirez probablement coupable après
                    avoir mangé. Votre comportement alimentaire est régulé non
                    seulement par des mécanismes physiologiques de la faim et de
                    la satiété qui vous permettent d'atteindre un équilibre
                    entre l'apport calorique et la consommation d'énergie, mais
                    aussi par d'autres facteurs psychologiques et sociaux qui
                    peuvent avoir une influence significative sur ce que vous
                    mangez, combien et quand vous mangez. La faim émotionnelle
                    est un court-circuit entre les états émotionnels et
                    l'ingestion de nourriture... souvent des excès caloriques ;
                    c'est pourquoi le besoin de manger pour calmer les émotions
                    est un facteur de risque évident pour les comportements
                    boulimiques et l'obésité.
                  </p>
                </p>
                <img
                  src={EdMat14}
                  style={{ maxWidth: "20%", height: "auto" }}
                />
                <p className="TopicDescription-font">
                  <p>
                    Suivez ces conseils simples pour manger de manière plus
                    consciente, en prenant soin de votre relation avec la
                    nourriture.
                  </p>
                  <ul>
                    <li>
                      Cherchez votre "vraie faim" et apprenez à la distinguer de
                      la "faim émotionnelle". Si le stimulus de la faim ne vient
                      pas vraiment de votre estomac ou si vous venez de manger,
                      alors vous avez simplement envie de manger et ce n'est pas
                      une faim réelle ! Asseyez-vous et prenez une petite gorgée
                      d'un verre d'eau pour vous détendre.
                    </li>
                    <li>
                      Pensez aux conséquences ! Souvenez-vous qu'après la
                      gratification temporaire vient toujours la culpabilité et
                      le remords !
                    </li>
                    <li>
                      N'essayez pas de finir tout ce qui est dans votre assiette
                      à tout prix. Si vous êtes rassasié, vous pouvez garder les
                      restes pour le lendemain.
                    </li>
                    <li>
                      Essayez de ne pas manger en marchant ou avant de vous
                      asseoir à table... il est difficile de prendre conscience
                      de ce que vous mangez. Pendant le repas, asseyez-vous et
                      prenez votre temps pour apprécier ce que vous avez dans
                      votre assiette.
                    </li>
                    <li>
                      N'ouvrez pas le garde-manger ou le réfrigérateur sans
                      raison ! Lorsque vous êtes dans la cuisine, respirez et
                      essayez de ressentir votre corps... Écoutez-vous pour
                      savoir si vous avez vraiment faim, soif, stressé, triste
                      ou ennuyé. Accordez-vous une minute pour distinguer les
                      besoins réels des désirs.
                    </li>
                    <li>
                      Lorsque vous reconnaissez la faim émotionnelle, ne vous y
                      abandonnez pas mais essayez de vous distraire, par
                      exemple, faites une courte promenade ou discutez avec des
                      amis.
                    </li>
                    <li>
                      Ne mangez jamais directement depuis l'emballage ! Si vous
                      servez la nourriture dans une assiette, vous pourrez mieux
                      apprécier ce que vous mangez et combien.
                    </li>
                    <li>
                      Utiliser des assiettes plus petites vous aidera à
                      contrôler les portions de ce que vous mangez.
                    </li>
                    <li>
                      Ne perdez pas de vue pendant que vous mangez, c'est
                      pourquoi il est important d'éteindre toujours la
                      télévision et tout ce qui a un écran avant de commencer à
                      manger.
                    </li>
                    <li>
                      Réglez une minuterie et accordez-vous au moins 20 minutes
                      pour manger un repas, ou essayez de manger avec votre main
                      non dominante (ou des baguettes) pour manger plus
                      lentement.
                    </li>
                  </ul>
                </p>
              </div>
            )}
            {currentLanguage === "ar" && (
              <div>
                <p className="TopicDescription-font-arabic">
                  <p>
                    لا تأكل إذا لم تكن جائعا حقا! عود نفسك دائمًا على التساؤل
                    عما إذا كانت رغبتك في تناول الطعام ناشئة عن حاجة فسيولوجية
                    حقيقية لجسمك أم أنها مرتبطة فقط بحالة عاطفية، مثل القلق أو
                    الخوف أو الغضب أو التوتر أو الملل أو الوحدة. تذكر أنه في
                    الحالة الأولى، فإن تقديم الطعام بطريقة صحيحة ولإشباع الشعور
                    بالجوع الحقيقي سيجعلك راضيًا وشبعانًا، وإلا، إذا كان جوعك
                    "عصبيًا" فقط، فمن المحتمل أن تشعر بالذنب بعد تناول الطعام.
                    يتم تنظيم سلوكك الغذائي ليس فقط من خلال الآليات الفسيولوجية
                    للجوع والشبع التي تسمح لك بتحقيق التوازن بين تناول السعرات
                    الحرارية واستهلاك الطاقة، ولكن أيضًا من خلال عوامل نفسية
                    واجتماعية أخرى يمكن أن يكون لها تأثير كبير في تحديد ماذا وكم
                    ومتى انت تأكل. الجوع العاطفي هو دائرة قصيرة بين الحالات
                    العاطفية وتناول الطعام... في كثير من الأحيان حالات ذات سعرات
                    حرارية زائدة؛ هذا هو السبب في أن الحاجة إلى تناول الطعام
                    لتهدئة المشاعر هي عامل خطر واضح للسلوكيات النهامية والسمنة.
                  </p>
                </p>
                <img
                  src={EdMat14}
                  style={{ maxWidth: "20%", height: "auto" }}
                />
                <p className="TopicDescription-font-arabic">
                  <p>
                    اتبع هذه النصائح البسيطة لتناول الطعام بوعي أكبر، مع
                    الاهتمام بعلاقتك بالطعام.
                  </p>
                  <ul>
                    <li className="TopicDescription-font-arabic">
                      ابحث عن "جوعك الحقيقي" وتعلم كيفية التعرف عليه من "الجوع
                      العاطفي". إذا لم يأت تحفيز الجوع من معدتك أو إذا كنت قد
                      تناولت الطعام للتو، فإن ما تشعر به هو مجرد الرغبة في تناول
                      الطعام وليس الجوع الحقيقي! اجلس وخذ رشفة صغيرة من كوب من
                      الماء للاسترخاء.
                    </li>
                    <li className="TopicDescription-font-arabic">
                      فكر في العواقب! تذكر أنه بعد الإشباع المؤقت يأتي دائمًا
                      الشعور بالذنب والندم!
                    </li>
                    <li className="TopicDescription-font-arabic">
                      لا تحاول إنهاء كل ما في طبقك بأي ثمن. إذا كنت ممتلئًا،
                      يمكنك الاحتفاظ ببقايا الطعام لليوم التالي.
                    </li>
                    <li className="TopicDescription-font-arabic">
                      حاول ألا تأكل أثناء المشي أو قبل الجلوس على الطاولة... فمن
                      الصعب أن تنتبه للكمية التي تأكلها. أثناء تناول الطعام،
                      اجلس وخذ وقتك للاستمتاع بما لديك على طبقك.
                    </li>
                    <li className="TopicDescription-font-arabic">
                      لا تفتح خزانة المؤن أو الثلاجة بدون أي سبب! عندما تكون في
                      المطبخ، تنفس وحاول أن تشعر بجسدك... استمع إلى نفسك لتعرف
                      ما إذا كنت حقاً جائعا، عطشانا، متوترا، حزينا أو تشعر
                      بالملل. امنح نفسك دقيقة للتمييز بين الاحتياجات الحقيقية
                      والرغبات.
                    </li>
                    <li className="TopicDescription-font-arabic">
                      عندما تعترف بالجوع العاطفي، لا تنغمس فيه ولكن حاول تشتيت
                      انتباهك، على سبيل المثال، قم بنزهة قصيرة أو قم بإجراء
                      محادثة مع بعض الأصدقاء.
                    </li>
                    <li className="TopicDescription-font-arabic">
                      لا تأكل أبدًا، لأي سبب من الأسباب، مباشرة من العبوة! إذا
                      قمت بتقديم الطعام على طبق، فستتمكن من تقدير ما تأكله وكم
                      تأكله بشكل أفضل.
                    </li>
                    <li className="TopicDescription-font-arabic">
                      استخدام أطباق أصغر سيساعدك على التحكم في كميات ما تأكله.
                    </li>
                    <li className="TopicDescription-font-arabic">
                      لا تفقد التركيز أثناء تناول الطعام، ولهذا السبب من المهم
                      دائمًا إيقاف تشغيل التلفزيون وأي شيء به شاشة قبل البدء في
                      تناول الطعام.
                    </li>
                    <li className="TopicDescription-font-arabic">
                      اضبط مؤقتًا واسمح لنفسك بـ 20 دقيقة على الأقل لتناول وجبة،
                      أو حاول تناول الطعام بيدك غير المسيطرة (أو عيدان تناول
                      الطعام) لتناول الطعام ببطء أكثر.
                    </li>
                  </ul>
                </p>
              </div>
            )}
            <button className="back-arrow" onClick={goBack}>
              <FontAwesomeIcon icon={faHome} /> {t("Educational_Material_Text")}{" "}
            </button>
          </motion.div>
        </div>
      </div>
      <div className="footer-sticky">{!isMobile && <Footer />} </div>
    </div>
  );
}

export default EdMaterialTopic14;
