import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import jwt_decode from "jwt-decode";
import "./EdMaterialTopic.css";
import SideBar from "../../components/sideBar.js";
import Footer from "../../components/footer.js";
import EdMat9 from "../../images/EdMaterial/EdMat9.png";
import EdMat9_tr from "../../images/EdMaterial/Topic9_tr.jpg";
import backgroundImage from "../../images/graphics/about2.jpg";
import LogoutAfterInactivity from "../../components/logoutAfterInactivity.js";
import { useTranslation } from "react-i18next";
import { motion } from "framer-motion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome } from "@fortawesome/free-solid-svg-icons";

function EdMaterialTopic9() {
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const [isMobile, setIsMobile] = useState(false);
  const navigate = useNavigate();
  const [authTokens, setAuthTokens] = useState(() =>
    localStorage.getItem("authTokens")
      ? JSON.parse(localStorage.getItem("authTokens"))
      : null
  );
  const [user, setUser] = useState(() =>
    localStorage.getItem("authTokens")
      ? jwt_decode(localStorage.getItem("authTokens"))
      : null
  );
  const [userProfileId, setUserProfileId] = useState(() =>
    localStorage.getItem("adult") ? localStorage.getItem("adult") : null
  );
  useEffect(() => {
    if (user == null || user.is_active == false || userProfileId == null) {
      navigate("/");
    } else {
      const handleResize = () => {
        setIsMobile(window.innerWidth <= 768);
      };

      handleResize(); // Initial check

      window.addEventListener("resize", handleResize);
      window.scrollTo({ top: 0, behavior: "smooth" });
      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }
  }, []);
  LogoutAfterInactivity();
  const topicNumber = 9;
  // Handlers for the arrows
  const goToPreviousPage = () => {
    const previousPageNumber = topicNumber - 1; // Calculate the previous page number
    navigate(`/topic${previousPageNumber}`); // Use the previous page number in the route
  };

  const goToNextPage = () => {
    const nextPageNumber = topicNumber + 1; // Calculate the next page number
    navigate(`/topic${nextPageNumber}`); // Use the next page number in the route
  };
  const goBack = () => {
    navigate(`/educationalMaterials`); // Use the back button
  };
  return (
    <div className="topic-rightpart">
      <div
        style={{
          backgroundSize: "cover", // This will resize the image to cover the entire element
          backgroundRepeat: "no-repeat", // This will prevent the image from repeating
          backgroundPosition: "center", // This will position the image at the center of the element
        }}
      >
        <div>
          <SideBar />
          <motion.div
            initial={{ x: "40%" }}
            animate={{ x: "0%" }}
            transition={{ duration: 0.3, ease: "easeOut" }}
            className="Topic-Page"
          >
            <div className="header-container">
              {/* Previous Arrow */}
              <button className="prev-arrow" onClick={goToPreviousPage}>
                &larr;
              </button>

              {/* Title and Subtitle */}
              <div className="title-container">
                <h1>{t(`Topic${topicNumber}Title`)}</h1>
                <h3>{t(`Topic${topicNumber}Subtitle`)}</h3>
              </div>

              {/* Next Arrow */}
              <button className="next-arrow" onClick={goToNextPage}>
                &rarr;
              </button>
            </div>
            <br></br>
            {currentLanguage === "en" && (
              <div>
                <p className="TopicDescription-font">
                  <p>
                    The "Healthy Eating Plate" was developed by nutrition
                    experts of Harvard Medical School in Boston and is a very
                    useful graphic tool that allows everyone to enjoy healthy
                    and balanced meals, encouraging consumers to focus on the
                    quality of their diet. We recommend you to hang a copy of it
                    in the kitchen as a reminder to create healthy and above all
                    balanced meals for the whole family every day.
                  </p>
                  <p>
                    The Healthy Eating Plate has the classic round shape and is
                    divided into sections of different sizes that correspond to
                    the amount of food represented. The most abundant portion of
                    the dish (1⁄2 plate) is made up of vegetables and fruit,
                    while whole grains represent 1⁄4 of the plate. You can
                    change the portion of wholegrain cereals every day by
                    preparing some barley, wheat grains, quinoa, oats, brown
                    rice, brown pasta or many others, which have a high fibre
                    content and a lower glycemic index than traditional white
                    bread, white rice and other refined grains. The remaining
                    part (1⁄4 of the dish) must be composed of proteins such as
                    fish, poultry or legumes. Proteins are very versatile in
                    fact they can be added to salads and they combine well with
                    all vegetables. Remember that it is important to limit the
                    consumption of red and processed meat such as cold cuts and
                    sausages. As a condiment choose healthy vegetable oils, such
                    as extra virgin olive oil. During your meals, drink
                    especially water, avoid sweet drinks, limit milk and dairy
                    products to one or two servings per day, and reduce juices.
                    Last but not least (indeed!) remember to stay active. A
                    fundamental component of the Healthy Eating Plate is in fact
                    the regular physical activity necessary for weight control.
                  </p>
                </p>
                <img
                  src={EdMat9}
                  style={{ maxWidth: "100%", height: "auto" }}
                />
                <br></br> <br></br>
                <br></br>
              </div>
            )}
            {currentLanguage === "es" && (
              <div>
                <p className="TopicDescription-font">
                  <p>
                    El "Plato de Alimentación Saludable" fue desarrollado por
                    expertos en nutrición de la Escuela de Medicina de Harvard
                    en Boston y es una herramienta gráfica muy útil que permite
                    a todos disfrutar de comidas sanas y equilibradas,
                    incentivando a los consumidores a centrarse en la calidad de
                    su dieta. Te recomendamos colgar un ejemplar en la cocina
                    como recordatorio para crear cada día comidas sanas y sobre
                    todo equilibradas para toda la familia.
                  </p>
                  <p>
                    El Plato para Comer Saludable tiene la clásica forma redonda
                    y está dividido en secciones de diferentes tamaños que
                    corresponden a la cantidad de comida representada. La
                    porción más abundante del plato (1⁄2 plato) está compuesta
                    por verduras y frutas, mientras que los cereales integrales
                    representan 1⁄4 del plato. Puedes cambiar la ración de
                    cereales integrales todos los días preparando un poco de
                    cebada, quinoa, avena, arroz integral, pasta integral. o
                    muchos otros, que tienen un alto contenido en fibra y un
                    índice glucémico más bajo que el pan blanco tradicional, el
                    arroz blanco y otros cereales refinados. La parte restante
                    (1⁄4 del plato) debe estar compuesta por proteínas como
                    pescado, aves o legumbres. Las proteínas son muy versátiles,
                    de hecho, se pueden añadir a ensaladas y combinan bien con
                    todas las verduras. Recuerda que es importante limitar el
                    consumo de carnes rojas y procesadas como fiambres y
                    embutidos. Como condimento elige aceites vegetales
                    saludables, como el aceite de oliva virgen extra. Durante
                    las comidas bebe agua o infusiones, evita las bebidas
                    dulces, limita la leche y los productos lácteos a una o dos
                    porciones al día y reduce los zumos y refrescos azucarados.
                    Por último, pero no menos importante, recuerde mantenerse
                    activo. Un componente fundamental del Plato de Alimentación
                    Saludable es, de hecho, la actividad física regular
                    necesaria para controlar el peso.
                  </p>
                </p>
                <img
                  src={EdMat9}
                  style={{ maxWidth: "100%", height: "auto" }}
                />
                <br></br> <br></br>
                <br></br>
              </div>
            )}{" "}
            {currentLanguage === "tr" && (
              <div>
                <p className="TopicDescription-font">
                  <p>
                    "Sağlıklı Beslenme Tabağı", Boston'daki Harvard Tıp
                    Fakültesi'nin beslenme uzmanları tarafından geliştirildi ve
                    herkesin sağlıklı ve dengeli öğünlerden keyif almasına
                    olanak tanıyan, tüketicileri beslenmelerinin kalitesine
                    odaklanmaya teşvik eden çok kullanışlı bir grafik aracıdır.
                    Her gün tüm aile için sağlıklı ve her şeyden önce dengeli
                    yemekler hazırlamanızı hatırlatmak amacıyla bu belgenin bir
                    kopyasını mutfağa asmanızı öneririz.
                  </p>
                  <p>
                    Sağlıklı Beslenme Tabağı klasik yuvarlak şekle sahiptir ve
                    temsil edilen yiyecek miktarına karşılık gelen farklı
                    boyutlarda bölümlere ayrılmıştır. Yemeğin en bol kısmı (1/2
                    tabak) sebze ve meyvelerden oluşurken, tam tahıllar tabağın
                    1/4'ünü oluşturmaktadır. Tam tahılların porsiyonunu
                    geleneksel beyaz ekmek, beyaz pirinç, mısır ve diğer rafine
                    tahıllara kıyasla daha yüksek lif içeriğine ve daha düşük
                    glisemik indekse sahip arpa, buğday taneleri, kinoa, yulaf,
                    kahverengi pirinç, tam tahıllı makarna ile
                    değiştirebilirsiniz. Geriye kalan kısım (yemeğin 1/4'ü)
                    balık, kümes hayvanları veya baklagiller gibi proteinlerden
                    oluşmalıdır. Proteinler çok yönlüdür; salatalara
                    eklenebilirler ve tüm sebzelerle iyi uyum sağlarlar. Salam,
                    sosis gibi kırmızı ve işlenmiş et tüketimini sınırlamanın
                    önemli olduğunu unutmayın. Çeşni olarak sızma zeytinyağı
                    gibi sağlıklı bitkisel yağları seçin. Yemek sırasında
                    özellikle su tüketin, şekerli içeceklerden uzak durun, süt
                    ve süt ürünlerini günde bir veya iki porsiyonla sınırlayın,
                    meyve sularını azaltın. Son olarak (aslında!) aktif kalmayı
                    unutmayın. Sağlıklı Beslenme Düzeninin temel bileşenlerinden
                    biri, kilo kontrolü için gerekli olan düzenli fiziksel
                    aktivitedir.
                  </p>
                </p>
                <img
                  src={EdMat9_tr}
                  style={{ maxWidth: "100%", height: "auto" }}
                />
                <br></br> <br></br>
                <br></br>
              </div>
            )}
            {currentLanguage === "fr" && (
              <div>
                <p className="TopicDescription-font">
                  <p>
                    Le "Plateau de l'Alimentation Saine" a été développé par des
                    experts en nutrition de la Harvard Medical School à Boston
                    et est un outil graphique très utile qui permet à chacun de
                    profiter de repas sains et équilibrés, encourageant les
                    consommateurs à se concentrer sur la qualité de leur
                    alimentation. Nous vous recommandons d'accrocher une copie
                    dans la cuisine comme rappel pour créer des repas sains et
                    surtout équilibrés pour toute la famille chaque jour.
                  </p>
                  <p>
                    Le Plateau de l'Alimentation Saine a la forme classique
                    ronde et est divisé en sections de différentes tailles qui
                    correspondent à la quantité d'aliments représentée. La
                    portion la plus abondante de l'assiette (1⁄2 assiette) est
                    constituée de légumes et de fruits, tandis que les céréales
                    complètes représentent 1⁄4 de l'assiette. Vous pouvez
                    changer la portion de céréales complètes chaque jour en
                    préparant de l'orge, des grains de blé, du quinoa, de
                    l'avoine, du riz brun, des pâtes complètes ou bien d'autres
                    encore, qui ont une teneur élevée en fibres et un indice
                    glycémique plus bas que les pains blancs traditionnels, le
                    riz blanc et les autres grains raffinés. La partie restante
                    (1⁄4 de l'assiette) doit être composée de protéines telles
                    que le poisson, la volaille ou les légumineuses. Les
                    protéines sont très polyvalentes, en effet elles peuvent
                    être ajoutées aux salades et se marient bien avec tous les
                    légumes. Rappelez-vous qu'il est important de limiter la
                    consommation de viandes rouges et transformées comme les
                    charcuteries et les saucisses. Comme condiment, choisissez
                    des huiles végétales saines, telles que l'huile d'olive
                    extra vierge. Pendant vos repas, buvez surtout de l'eau,
                    évitez les boissons sucrées, limitez les produits laitiers à
                    une ou deux portions par jour et réduisez les jus. Enfin (et
                    surtout !), n'oubliez pas de rester actif. Un composant
                    fondamental du Plateau de l'Alimentation Saine est en effet
                    l'activité physique régulière nécessaire pour contrôler le
                    poids.
                  </p>
                </p>
                <img
                  src={EdMat9}
                  style={{ maxWidth: "100%", height: "auto" }}
                />
                <br></br> <br></br>
                <br></br>
              </div>
            )}
            {currentLanguage === "ar" && (
              <div>
                <p className="TopicDescription-font-arabic">
                  <p>
                    تم تطوير "طبق الأكل الصحي" من قبل خبراء التغذية في كلية الطب
                    بجامعة هارفارد في بوسطن، وهو أداة رسومية مفيدة للغاية تتيح
                    للجميع الاستمتاع بوجبات صحية ومتوازنة، مما يشجع المستهلكين
                    على التركيز على جودة نظامهم الغذائي. ننصحك بتعليق نسخة منه
                    في المطبخ للتذكير بإعداد وجبات صحية ومتوازنة لجميع أفراد
                    الأسرة كل يوم.
                  </p>
                  <p>
                    يتميز طبق الأكل الصحي بالشكل الدائري الكلاسيكي وينقسم إلى
                    أقسام بأحجام مختلفة تتناسب مع كمية الطعام الممثلة. يتكون
                    الجزء الأكثر وفرة من الطبق (نصف طبق) من الخضار والفواكه،
                    بينما تمثل الحبوب الكاملة ربع الطبق. يمكنك تغيير حصة الحبوب
                    الكاملة كل يوم عن طريق تحضير بعض الشعير أو حبوب القمح أو
                    الكينوا أو الشوفان أو الأرز البني أو المعكرونة البنية أو
                    غيرها الكثير، والتي تحتوي على نسبة عالية من الألياف ومؤشر
                    نسبة السكر في الدم أقل من الخبز الأبيض التقليدي والأرز
                    الأبيض و الحبوب المكررة الأخرى. أما الجزء المتبقي (ربع
                    الطبق) فيجب أن يتكون من البروتينات مثل الأسماك أو الدواجن أو
                    البقوليات. البروتينات متعددة الاستخدامات، حيث يمكن إضافتها
                    إلى السلطات كما أنها تندمج جيدًا مع جميع الخضروات. تذكر أنه
                    من المهم الحد من استهلاك اللحوم الحمراء والمعالجة مثل اللحوم
                    الباردة والنقانق. كتوابل، اختر الزيوت النباتية الصحية، مثل
                    زيت الزيتون البكر الممتاز. خلال وجبات الطعام، اشرب الماء
                    بشكل خاص، وتجنب المشروبات الحلوة، واقتصر على الحليب ومنتجات
                    الألبان على حصة أو حصتين في اليوم، وقلل من العصائر. أخيرًا
                    وليس آخرًا (في الواقع!) تذكر أن تظل نشطًا. أحد المكونات
                    الأساسية لطبق الأكل الصحي هو في الواقع النشاط البدني المنتظم
                    الضروري للتحكم في الوزن.
                  </p>
                </p>
                <img
                  src={EdMat9}
                  style={{ maxWidth: "100%", height: "auto" }}
                />
                <br></br> <br></br>
                <br></br>
              </div>
            )}
            <button className="back-arrow" onClick={goBack}>
              <FontAwesomeIcon icon={faHome} /> {t("Educational_Material_Text")}{" "}
            </button>
          </motion.div>
        </div>
      </div>
      <div className="footer-sticky">{!isMobile && <Footer />} </div>
    </div>
  );
}

export default EdMaterialTopic9;
