import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import jwt_decode from "jwt-decode";
import userProfileImg1 from "../../images/userProfile/default.png";
import userProfileImg2 from "../../images/userProfile/cat.png";
import userProfileImg3 from "../../images/userProfile/dog.png";
import userProfileImg4 from "../../images/userProfile/sheep.png";
import userProfileImg5 from "../../images/userProfile/bear.png";
import userProfileImg6 from "../../images/userProfile/wolf.png";
import userProfileImg7 from "../../images/userProfile/tiger.png";
import userProfileImg8 from "../../images/userProfile/frog.png";
import userProfileImg9 from "../../images/userProfile/pig.png";
import { useTranslation } from "react-i18next";

function PersonalInfo({
  page,
  setPage,
  formData,
  setFormData,
  maxAdults,
  maxChild,
  errorMessage,
}) {
  const [user, setUser] = useState(() =>
    localStorage.getItem("authTokens")
      ? jwt_decode(localStorage.getItem("authTokens"))
      : null
  );

  const { t } = useTranslation();

  const images = [
    "apim1",
    "apim2",
    "apim3",
    "apim4",
    "apim5",
    "apim6",
    "apim7",
    "apim8",
    "apim9",
  ];

  const imageMap = {
    apim1: userProfileImg1,
    apim2: userProfileImg2,
    apim3: userProfileImg3,
    apim4: userProfileImg4,
    apim5: userProfileImg5,
    apim6: userProfileImg6,
    apim7: userProfileImg7,
    apim8: userProfileImg8,
    apim9: userProfileImg9,
  };

  const [isOn, setIsOn] = useState(false);

  const toggleSwitch = () => {
    var pro = !isOn;
    setIsOn(!isOn);
    if (pro) {
      setFormData({
        ...formData,
        profile: "child",
      });
    } else {
      setFormData({
        ...formData,
        profile: "adult",
      });
    }
  };

  const [usernames, setUsernames] = useState([]);
  const [activeButton, setActiveButton] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  const [validUsername, setValidUsername] = useState();
  const [showValidUsername, setShowValidUsername] = useState(false);
  const navigate = useNavigate();

  const handleImageSelect = (image) => {
    // console.log(image);
    setFormData({ ...formData, img: image });
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${user.user_id}/getUsernames`);
        setUsernames(response.data.usernames);
      } catch {}
    };

    fetchData();
  }, []);

  useEffect(() => {
    // console.log(formData);
    if (formData.username === "") {
      setShowValidUsername(false);
    } else {
      setShowValidUsername(true);
      if (usernames.includes(formData.username)) {
        setValidUsername(false);
      } else {
        setValidUsername(true);
      }
    }
    if (
      formData.username !== "" &&
      formData.img !== "" &&
      validUsername === true
    ) {
      setActiveButton(true);
    } else {
      setActiveButton(false);
    }
  });

  const handleCancelButton = () => {
    if (
      formData.username === "" &&
      formData.img === "" &&
      formData.gender === "" &&
      formData.height === "" &&
      formData.weight === "" &&
      formData.yearOfBirth === "" &&
      formData.PAL === "" &&
      formData.country === "" &&
      formData.countryLanguageCode === ""
    ) {
      navigate("/accounts");
    } else {
      const userConfirmed = window.confirm(t("cancel_message"));
      if (userConfirmed) {
        navigate("/accounts");
      }
    }
  };

  const checkUsername = (username) => {
    if (username === "") {
      setShowValidUsername(false);
    } else {
      setShowValidUsername(true);
      if (usernames.includes(username)) {
        setValidUsername(false);
      } else {
        setValidUsername(true);
      }
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (formData["profile"] === "adult") {
      setPage(page + 1);
    }
    if (formData["profile"] === "child") {
      setPage(page + 1);
    }
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <div className="form-body">
          {!maxAdults && !maxChild ? (
            <>
              <span className="body-context">{t(formData["profile"])}</span>

              <div className="switch" data-isOn={isOn} onClick={toggleSwitch}>
                <motion.div
                  className="handle"
                  layout
                  transition={{ type: "spring", stiffness: 700, damping: 30 }}
                />
              </div>
            </>
          ) : (
            <div className="body">
              <div className="ruser-font" style={{ color: "red" }}>
                {errorMessage}
              </div>
            </div>
          )}
        </div>
        <br></br>
        <div className="ruser-profile-font2">{t("Personal_Info")}</div>
        <div className="section-display">
          <div
            className="ruser-font-v"
            style={{
              color: showValidUsername
                ? validUsername
                  ? "green"
                  : "red"
                : "black",
            }}
          >
            {showValidUsername
              ? validUsername
                ? t("username_avaliable")
                : t("username_exist")
              : ""}
          </div>
          <label className="ruser-font">{t("Username")}:</label>
          <input
            type="username"
            id="username"
            value={formData.username}
            onChange={(event) => {
              setFormData({ ...formData, username: event.target.value });
              checkUsername(event.target.value);
            }}
            className="upline-field"
          ></input>
          <br></br>
          <br></br>
          <div className="ruser-profile-font">{t("profile_image")}</div>
          <div className="section-display">
            <div className="profile-icons">
              {images.map((image, index) => (
                <motion.div
                  whileHover={{ scale: 1.2 }}
                  whileTap={{ scale: 0.9 }}
                  transition={{ type: "spring", stiffness: 400, damping: 17 }}
                  className="profile-icon"
                >
                  <img
                    key={index}
                    src={imageMap[image]}
                    alt={`Select option ${index + 1}`}
                    onClick={() => handleImageSelect(image)}
                    required
                    className={`profile-icon img ${
                      formData.img === image ? "selected" : ""
                    }`}
                  />
                </motion.div>
              ))}
            </div>
          </div>
        </div>
        <div className="nav-bar">
          <button
            type="button"
            className="form-button"
            onClick={() => {
              handleCancelButton();
            }}
          >
            {t("Cancel")}
          </button>
          <button
            className={activeButton ? "form-button" : "form-inactive-button"}
            type="submit"
          >
            {t("Next")}
          </button>
        </div>
      </form>
    </div>
  );
}

export default PersonalInfo;
