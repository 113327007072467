import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import jwt_decode from "jwt-decode";
import "./EdMaterialTopic.css";
import SideBar from "../../components/sideBar.js";
import Footer from "../../components/footer.js";
import imageProteins from "../../images/EdMaterial/Topic4/EdMat4_Proteins.png";
import imageVitamins from "../../images/EdMaterial/Topic4/EdMat4_Vitamins.png";
import imageFats from "../../images/EdMaterial/Topic4/EdMat4_Fats.png";
import imageMinerals from "../../images/EdMaterial/Topic4/EdMat4_Minerals.png";
import ImageCarbohydrates from "../../images/EdMaterial/Topic4/EdMat4_Carbohydrates.png";

import backgroundImage from "../../images/graphics/about2.jpg";
import LogoutAfterInactivity from "../../components/logoutAfterInactivity.js";
import { useTranslation } from "react-i18next";
import { motion } from "framer-motion";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome } from "@fortawesome/free-solid-svg-icons";

function EdMaterialTopic4() {
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const [isMobile, setIsMobile] = useState(false);
  const navigate = useNavigate();
  const [authTokens, setAuthTokens] = useState(() =>
    localStorage.getItem("authTokens")
      ? JSON.parse(localStorage.getItem("authTokens"))
      : null
  );
  const [user, setUser] = useState(() =>
    localStorage.getItem("authTokens")
      ? jwt_decode(localStorage.getItem("authTokens"))
      : null
  );
  const [userProfileId, setUserProfileId] = useState(() =>
    localStorage.getItem("adult") ? localStorage.getItem("adult") : null
  );

  const topicNumber = 4;
  // Handlers for the arrows
  const goToPreviousPage = () => {
    const previousPageNumber = topicNumber - 1; // Calculate the previous page number
    navigate(`/topic${previousPageNumber}`); // Use the previous page number in the route
  };

  const goToNextPage = () => {
    const nextPageNumber = topicNumber + 1; // Calculate the next page number
    navigate(`/topic${nextPageNumber}`); // Use the next page number in the route
  };

  const goBack = () => {
    navigate(`/educationalMaterials`); // Use the back button
  };

  useEffect(() => {
    if (user == null || user.is_active == false || userProfileId == null) {
      navigate("/");
    } else {
      const handleResize = () => {
        setIsMobile(window.innerWidth <= 768);
      };

      handleResize(); // Initial check

      window.addEventListener("resize", handleResize);
      window.scrollTo({ top: 0, behavior: "smooth" });
      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }
  }, []);
  LogoutAfterInactivity();

  return (
    <div className="topic-rightpart">
      <div
        style={{
          backgroundSize: "cover", // This will resize the image to cover the entire element
          backgroundRepeat: "no-repeat", // This will prevent the image from repeating
          backgroundPosition: "center", // This will position the image at the center of the element
        }}
      >
        <div>
          <SideBar />
          <motion.div
            initial={{ x: "40%" }}
            animate={{ x: "0%" }}
            transition={{ duration: 0.3, ease: "easeOut" }}
            className="Topic-Page"
          >
            <div className="header-container">
              {/* Previous Arrow */}
              <button className="prev-arrow" onClick={goToPreviousPage}>
                &larr;
              </button>

              {/* Title and Subtitle */}
              <div className="title-container">
                <h1>{t(`Topic${topicNumber}Title`)}</h1>
                <h3>{t(`Topic${topicNumber}Subtitle`)}</h3>
              </div>

              {/* Next Arrow */}
              <button className="next-arrow" onClick={goToNextPage}>
                &rarr;
              </button>
            </div>

            <br></br>

            <div>
              {currentLanguage === "en" && (
                <div>
                  <p className="TopicDescription-font">
                    Foodstuffs contain many different nutrients, such as
                    protein, fat, carbohydrates, vitamins, and minerals!
                    Proteins, fats, and carbohydrates are indeed not the only
                    nutrients our body needs. Vitamins and minerals are also
                    included among the substances that must be intake with food.
                    Remember that the nutrients our body needs are not all
                    contained in a single food, so we need to eat all kinds of
                    foods varying as much as possible. Find out more about the
                    different nutrients!{" "}
                  </p>

                  <p className="TopicDescription-font">
                    <strong>Proteins: </strong>
                    Proteins are composed of carbon, hydrogen, oxygen, and
                    nitrogen; and a certain amount of sulphur is also present.
                    Proteins are made up of different bricks, called amino
                    acids, linked by special bonds (peptides). Proteins are
                    indispensable to build our organs and to ensure that all the
                    mechanisms of the body work. The right amount of protein
                    should be consumed every day, without exaggeration! Proteins
                    are mostly contained in meat, fish, eggs, and milk, yoghurt,
                    and cheese. Also very important are the vegetable proteins
                    that you can find in legumes such as beans, peas, chickpeas,
                    and lentils. In addition, proteins are energetic nutrients;
                    in fact, they can be used to obtain energy in case of
                    carbohydrate deficiency, as it typically happens when we
                    stay for a long time without eating, or when the amount of
                    protein we ingest exceeds the actual needs of the body.
                  </p>
                  <img
                    src={imageProteins}
                    style={{ maxWidth: "15%", height: "auto" }}
                  />

                  <p className="TopicDescription-font">
                    <strong>Carbohydrates: </strong>
                    From a nutritional point of view, carbohydrates can be
                    classified into simple sugars and complex sugars. Among the
                    most important simple sugars (monosaccharides and
                    disaccharides) are glucose, fructose, galactose, sucrose,
                    lactose, and maltose. Among the complex sugars, the one that
                    has the greatest nutritional importance is starch, typically
                    abundant in cereals, legumes, and potatoes. They are an
                    important energy source at our disposal. In fact, they
                    represent both a ready-to-use energy resource and a useful
                    reserve of energy over time. Carbohydrates should be at the
                    basis of our diet, as they provide at least 45-50% of our
                    daily energy. This does not mean that we have to eat a lot
                    of sugar; it is always better to prefer complex
                    carbohydrates. That's why their consumption is highly
                    recommended at every meal! At breakfast, lunch, and dinner
                    you can never miss bread, pasta, and cereals, preferably
                    wholemeal! However, carbohydrates are also contained in
                    fruit and legumes.
                  </p>
                  <img
                    src={ImageCarbohydrates}
                    style={{ maxWidth: "15%", height: "auto" }}
                  />

                  <p className="TopicDescription-font">
                    <strong>Fats: </strong>
                    The most important fats, also called lipids, from a
                    nutritional point of view are glycerides, phospholipids, and
                    sterols. Glycerides are reserve substances for animals and
                    plants and represent the most abundant lipids in food, whose
                    main function is energetic. Phospholipids, on the other
                    hand, have an essentially structural function, representing
                    the scaffolding of biological membranes. Finally, sterols,
                    including the famous cholesterol, are present in many foods,
                    especially of animal origin. Cholesterol, in addition to its
                    structural function, is used by the body to build up certain
                    types of hormones (steroids) and is the precursor of vitamin
                    D. Furthermore, fats can be divided into three groups
                    according to their structure: saturated fats, which are
                    mainly found in red meat, cured meats, butter, eggs, and
                    whole milk; polyunsaturated fats found mainly in seed oil,
                    fish, and nuts; and finally, monounsaturated fats which are
                    rich in extra virgin olive oil! Try to always prefer
                    unsaturated fats, and use olive oil as a condiment.
                  </p>
                  <img
                    src={imageFats}
                    style={{ maxWidth: "15%", height: "auto" }}
                  />

                  <p className="TopicDescription-font">
                    <strong>Vitamins: </strong>
                    Vitamins represent a group of very different nutrients from
                    a chemical point of view, but they have some common
                    biochemical and physiological characteristics, in fact they
                    cannot be produced directly by the organism (except in some
                    cases and in very small quantities) and do not provide
                    energy. Since the body's demand for them is small, they are
                    called micronutrients. However, vitamins are essential for
                    growth as well as for the health of our whole organism: they
                    strengthen the nervous and immune systems, increase
                    resistance to infections and help the development of
                    cognitive capacities. Vitamins are classified according to
                    their solubility in fat-soluble (vitamins A, D, E, K) and
                    water-soluble (B vitamins and vitamin C).
                  </p>
                  <img
                    src={imageVitamins}
                    style={{ maxWidth: "15%", height: "auto" }}
                  />

                  <p className="TopicDescription-font">
                    <strong>Minerals: </strong>
                    Mineral salts are fundamental substances for our organism
                    and, like vitamins, they have no energy value. Minerals are
                    also called micronutrients, as the body needs them in small
                    quantities. Minerals are classified into two categories,
                    called macroelements and microelements. The macroelements
                    (calcium, phosphorus, magnesium, sodium, potassium,
                    chlorine, and sulphur) must be ingested in greater
                    quantities than the microelements (iron, manganese, copper,
                    iodine, zinc, fluorine, selenium, and chromium). Remember,
                    however, that this distinction does not indicate a greater
                    importance of the former compared to the latter; in fact,
                    each mineral has its own specific function. All mineral
                    salts are important for our body and help to keep it healthy
                    by performing various functions, in particular a
                    bioregulatory function (they participate in regulating
                    metabolic reactions, nerve impulse transmission, heartbeat,
                    muscle contraction and in controlling the body's pH) and
                    plastic function (they participate in the formation of
                    bones, teeth, and blood).
                  </p>
                  <img
                    src={imageMinerals}
                    style={{ maxWidth: "15%", height: "auto" }}
                  />
                  <br></br>
                  <br></br>
                </div>
              )}
            </div>
            <div>
              {currentLanguage === "es" && (
                <div>
                  <p className="TopicDescription-font">
                    ¡Los alimentos contienen muchos nutrientes diferentes, como
                    proteínas, grasas, carbohidratos, vitaminas y minerales! De
                    hecho, las proteínas, las grasas y los carbohidratos no son
                    los únicos nutrientes que nuestro cuerpo necesita. Las
                    vitaminas y minerales también se incluyen entre las
                    sustancias que deben ingerirse con los alimentos. Recuerda
                    que los nutrientes que nuestro cuerpo necesita no están
                    todos contenidos en un solo alimento, por lo que debemos
                    comer todo tipo de alimentos variando lo máximo posible.
                    ¡Descubre más sobre los diferentes nutrientes!{" "}
                  </p>

                  <p className="TopicDescription-font">
                    <strong>Proteínas: </strong>
                    las proteínas están compuestas por carbono, hidrógeno,
                    oxígeno y nitrógeno; y también está presente una cierta
                    cantidad de azufre. Las proteínas están formadas por
                    diferentes ladrillos, llamados aminoácidos, unidos por
                    enlaces especiales (péptidos). Las proteínas son
                    indispensables para construir nuestros órganos y garantizar
                    que todos los mecanismos del cuerpo funcionen. Se debe
                    consumir la cantidad adecuada de proteínas todos los días,
                    ¡sin exagerar! Las proteínas se encuentran principalmente en
                    la carne, el pescado, los huevos, la leche, el yogur y el
                    queso. También son muy importantes las proteínas vegetales
                    que puedes encontrar en legumbres como las judías, los
                    guisantes, los garbanzos y las lentejas. Además, las
                    proteínas son nutrientes energéticos, de hecho, pueden
                    utilizarse para obtener energía en caso de deficiencia de
                    carbohidratos, como suele ocurrir cuando permanecemos mucho
                    tiempo sin comer, o cuando la cantidad de proteínas que
                    ingerimos supera las necesidades reales de nuestro cuerpo.
                  </p>
                  <img
                    src={imageProteins}
                    style={{ maxWidth: "15%", height: "auto" }}
                  />

                  <p className="TopicDescription-font">
                    <strong>Hidratos de carbono: </strong>
                    desde el punto de vista nutricional, los hidratos de carbono
                    se pueden clasificar en azúcares simples y azúcares
                    complejos. Entre los azúcares simples (monosacáridos y
                    disacáridos) más importantes se encuentran la glucosa, la
                    fructosa, la galactosa, la sacarosa, la lactosa y la
                    maltosa. Entre los azúcares complejos, el que tiene mayor
                    importancia nutricional es el almidón, típicamente abundante
                    en cereales, legumbres y patatas. Son una importante fuente
                    de energía a nuestra disposición. De hecho, representan a la
                    vez un recurso energético listo para usar y una reserva de
                    energía útil a lo largo del tiempo. Los carbohidratos deben
                    estar en la base de nuestra dieta, ya que aportan al menos
                    el 45-50% de nuestra energía diaria. Esto no significa que
                    tengamos que comer mucha azúcar, siempre es mejor preferir
                    los carbohidratos complejos. ¡Por eso es muy recomendable su
                    consumo en cada comida! En el desayuno, comida y cena nunca
                    puede faltar el pan, la pasta y los cereales,
                    ¡preferiblemente integrales! Sin embargo, las frutas y las
                    legumbres también contienen carbohidratos.
                  </p>
                  <img
                    src={ImageCarbohydrates}
                    style={{ maxWidth: "15%", height: "auto" }}
                  />

                  <p className="TopicDescription-font">
                    <strong>Grasas: </strong>
                    las grasas más importantes, también llamadas lípidos, desde
                    el punto de vista nutricional son los glicéridos, los
                    fosfolípidos y los esteroles. Los glicéridos son sustancias
                    de reserva para animales y plantas y representan los lípidos
                    más abundantes en los alimentos, cuya principal función es
                    energética. Los fosfolípidos, por otro lado, tienen una
                    función esencialmente estructural, representando el
                    andamiaje de las membranas biológicas. Por último, los
                    esteroles, entre ellos el famoso colesterol, están presentes
                    en muchos alimentos, especialmente de origen animal. El
                    colesterol, además de su función estructural, es utilizado
                    por el organismo para formar cierto tipo de hormonas
                    (esteroides) y es el precursor de la vitamina D. Además, las
                    grasas se pueden dividir en 3 grupos según su estructura:
                    grasas saturadas, que se encuentran principalmente en carnes
                    rojas y embutidos, mantequilla, huevos y leche entera;
                    grasas poliinsaturadas que se encuentran principalmente en
                    el aceite de semillas, el pescado y los frutos secos; y, por
                    último, las grasas monoinsaturadas que son ricas, por
                    ejemplo, en el aceite de oliva virgen extra. Procura
                    preferir siempre las grasas insaturadas y utiliza aceite de
                    oliva como condimento.
                  </p>
                  <img
                    src={imageFats}
                    style={{ maxWidth: "15%", height: "auto" }}
                  />

                  <p className="TopicDescription-font">
                    <strong>Vitaminas: </strong>
                    las vitaminas representan un grupo de nutrientes muy
                    diferentes desde el punto de vista químico, pero tienen
                    algunas características bioquímicas y fisiológicas comunes,
                    de hecho, no pueden ser producidas directamente por el
                    organismo (excepto en algunos casos y en cantidades muy
                    pequeñas) y no proporciona energía. Dado que la demanda del
                    cuerpo es pequeña, se denominan micronutrientes. Sin
                    embargo, las vitaminas son esenciales tanto para el
                    crecimiento como para la salud de todo nuestro organismo:
                    fortalecen los sistemas nervioso e inmunológico, aumentan la
                    resistencia a las infecciones y ayudan al desarrollo de las
                    capacidades cognitivas. Las vitaminas se clasifican según su
                    solubilidad en liposolubles (vitaminas A, D, E, K) e
                    hidrosolubles (vitaminas del grupo B y vitamina C).
                  </p>
                  <img
                    src={imageVitamins}
                    style={{ maxWidth: "15%", height: "auto" }}
                  />

                  <p className="TopicDescription-font">
                    <strong>Minerales: </strong>
                    las sales minerales son sustancias fundamentales para
                    nuestro organismo y, al igual que las vitaminas, no tienen
                    valor energético. Los minerales también se denominan
                    micronutrientes, ya que el cuerpo los necesita en pequeñas
                    cantidades. Los minerales se clasifican en dos categorías,
                    llamados macroelementos y microelementos. Los macroelementos
                    (calcio, fósforo, magnesio, sodio, potasio, cloro y azufre)
                    deben ingerirse en mayores cantidades que los microelementos
                    (hierro, manganeso, cobre, yodo, zinc, flúor, selenio y
                    cromo). Recuerde, sin embargo, que esta distinción no indica
                    una mayor importancia del primero respecto del segundo, de
                    hecho, cada mineral tiene su función específica. Todas las
                    sales minerales son importantes para nuestro organismo y
                    ayudan a mantenerlo sano realizando diversas funciones, en
                    particular una función biorreguladora (participan en la
                    regulación de las reacciones metabólicas, la transmisión de
                    los impulsos nerviosos, los latidos del corazón, la
                    contracción muscular y en el control del pH del cuerpo) y la
                    función plástica. (participan en la formación de huesos,
                    dientes y sangre).
                  </p>
                  <img
                    src={imageMinerals}
                    style={{ maxWidth: "15%", height: "auto" }}
                  />
                  <br></br>
                  <br></br>
                </div>
              )}
            </div>
            <div>
              {currentLanguage === "tr" && (
                <div>
                  <p className="TopicDescription-font">
                    Gıda maddeleri protein, yağ, karbonhidrat, vitamin ve
                    mineral gibi birçok farklı besin maddesi içerir! Proteinler,
                    yağlar ve karbonhidratlar aslında vücudumuzun ihtiyaç
                    duyduğu tek besin değildir. Besinlerle alınması gereken
                    maddeler arasında vitamin ve mineraller de yer almaktadır.
                    Vücudumuzun ihtiyaç duyduğu besin öğelerinin tamamının tek
                    bir besinde bulunmadığını, bu nedenle her türlü besini
                    mümkün olduğunca farklı şekilde tüketmemiz gerektiğini
                    unutmayın. Farklı besinler hakkında daha fazla bilgi edinin!{" "}
                  </p>

                  <p className="TopicDescription-font">
                    <strong>Proteinler: </strong>
                    Proteinler karbon, hidrojen, oksijen ve nitrojenden oluşur
                    ve belli miktarda kükürt de mevcuttur. Proteinler, özel
                    bağlarla (peptitler) birbirine bağlanan, amino asit adı
                    verilen farklı yapılardan oluşur. Proteinler organlarımızın
                    inşası ve vücudun tüm mekanizmalarının çalışması için
                    vazgeçilmezdir. Her gün doğru miktarda protein abartılmadan
                    tüketilmelidir! Proteinler çoğunlukla et, balık, yumurta ve
                    süt, yoğurt ve peynirde bulunur. Fasulye, bezelye, nohut ve
                    mercimek gibi baklagillerde bulabileceğiniz bitkisel
                    proteinler de çok önemlidir. Ek olarak, proteinler enerji
                    veren besinlerdir, aslında karbonhidrat eksikliği durumunda
                    enerji elde etmek için kullanılabilirler; genellikle uzun
                    süre yemek yemeden kaldığımızda veya aldığımız protein
                    miktarı vücudumuzun gerçek ihtiyacını aştığında meydana
                    gelir.
                  </p>
                  <img
                    src={imageProteins}
                    style={{ maxWidth: "15%", height: "auto" }}
                  />

                  <p className="TopicDescription-font">
                    <strong>Karbonhidratlar: </strong>
                    Beslenme açısından karbonhidratlar basit şekerler ve
                    kompleks şekerler olarak sınıflandırılabilir. En önemli
                    basit şekerler (monosakkaritler ve disakkaritler) arasında
                    glikoz, fruktoz, galaktoz, sükroz, laktoz ve maltoz bulunur.
                    Kompleks şekerler arasında besin açısından en büyük öneme
                    sahip olanı, genellikle tahıllarda, baklagillerde ve
                    patateste bol miktarda bulunan nişastadır. Elimizde olan
                    önemli bir enerji kaynağıdırlar. Aslında hem kullanıma hazır
                    bir enerji kaynağını hem de zaman içinde faydalı bir enerji
                    rezervini temsil ederler. Karbonhidratlar günlük enerjimizin
                    en az %45-50'sini sağladığı için beslenmemizin temelinde yer
                    almalıdır. Bu çok fazla şeker yememiz gerektiği anlamına
                    gelmiyor, kompleks karbonhidratları tercih etmek her zaman
                    daha iyidir. Bu nedenle her öğünde tüketilmesi şiddetle
                    tavsiye edilir! Kahvaltı, öğle ve akşam yemeklerinde ekmek,
                    makarna ve tahılları, tercihen kepekli (tam tahıllı)
                    ürünleri asla eksik etmemelisiniz! Ancak meyve ve
                    baklagillerde de karbonhidrat bulunur.
                  </p>
                  <img
                    src={ImageCarbohydrates}
                    style={{ maxWidth: "15%", height: "auto" }}
                  />

                  <p className="TopicDescription-font">
                    <strong>Yağlar: </strong>
                    Beslenme açısından lipit olarak da adlandırılan en önemli
                    yağlar gliseritler, fosfolipitler ve sterollerdir.
                    Gliseritler hayvanlar ve bitkiler için rezerv maddelerdir ve
                    ana işlevi enerjik olan gıdalarda en çok bulunan lipitleri
                    temsil eder. Öte yandan fosfolipidler, biyolojik
                    membranların iskeletini temsil eden esas itibarıyla yapısal
                    bir işleve sahiptir. Son olarak, kolesterol de dahil olmak
                    üzere steroller, özellikle hayvansal kökenli birçok gıdada
                    mevcuttur. Kolesterol, yapısal fonksiyonunun yanı sıra vücut
                    tarafından belirli hormon türlerinin (steroidler) yapımında
                    kullanılır ve D vitamininin öncüsüdür. Ayrıca yağlar
                    yapılarına göre 3 gruba ayrılabilir: doymuş yağlar esas
                    olarak kırmızı ette ve kurutulmuş etlerde, tereyağında,
                    yumurtada ve tam yağlı sütte bulunur; çoklu doymamış yağlar
                    esas olarak tohum yağı, balık ve kuru yemişlerde bulunur ve
                    son olarak tekli doymamış yağlar sızma zeytinyağında
                    bulunur! Her zaman doymamış yağları tercih etmeye çalışın ve
                    çeşni olarak zeytinyağını kullanın.
                  </p>
                  <img
                    src={imageFats}
                    style={{ maxWidth: "15%", height: "auto" }}
                  />

                  <p className="TopicDescription-font">
                    <strong>Vitaminler: </strong>
                    Vitaminler, kimyasal açıdan çok farklı besin gruplarını
                    temsil eder, ancak bazı ortak biyokimyasal ve fizyolojik
                    özelliklere sahiptirler, aslında organizma tarafından
                    doğrudan üretilemezler (bazı durumlar ve çok küçük miktarlar
                    hariç) ve enerji sağlamazlar. Vücudun bunlara olan talebi az
                    olduğundan bunlara mikro besinler denir. Bununla birlikte,
                    vitaminler tüm organizmamızın sağlığı için olduğu kadar
                    büyüme için de gereklidir: sinir ve bağışıklık sistemlerini
                    güçlendirir, enfeksiyonlara karşı direnci arttırır ve
                    bilişsel kapasitelerin gelişmesine yardımcı olurlar.
                    Vitaminler, yağda çözünen (A, D, E, K vitaminleri) ve suda
                    çözünen (B vitaminleri ve C vitamini) çözünürlüklerine göre
                    sınıflandırılır.
                  </p>
                  <img
                    src={imageVitamins}
                    style={{ maxWidth: "15%", height: "auto" }}
                  />

                  <p className="TopicDescription-font">
                    <strong>Mineraller: </strong>
                    Mineral tuzları organizmamız için temel maddelerdir ve
                    vitaminler gibi enerji değeri yoktur. Minerallere vücudun
                    küçük miktarlarda ihtiyaç duyması nedeniyle mikro besinler
                    de denir. Mineraller makroelementler ve mikroelementler adı
                    verilen iki kategoriye ayrılır. Makro elementler (kalsiyum,
                    fosfor, magnezyum, sodyum, potasyum, klor ve kükürt), mikro
                    elementlerden (demir, manganez, bakır, iyot, çinko, flor,
                    selenyum ve krom) daha fazla miktarlarda alınmalıdır. Ancak
                    unutmayın ki bu ayrım, birincisinin ikincisinden daha önemli
                    olduğunu göstermez; aslında her mineralin kendine özgü bir
                    işlevi vardır. Tüm mineral tuzlar vücudumuz için önemlidir
                    ve başta biyolojik düzenleme işlevi (metabolik
                    reaksiyonların düzenlenmesinde, sinir uyarılarının
                    iletiminde, kalp atışı, kas kasılmasında ve vücudun pH'ının
                    kontrolünde rol oynarlar) ve yapısal işlev (kemik, diş ve
                    kan oluşumuna katılırlar) olmak üzere çeşitli işlevleri
                    yerine getirerek vücudumuzun sağlıklı kalmasına yardımcı
                    olur.
                  </p>
                  <img
                    src={imageMinerals}
                    style={{ maxWidth: "15%", height: "auto" }}
                  />
                  <br></br>
                  <br></br>
                </div>
              )}

              {currentLanguage === "fr" && (
                <div>
                  <p className="TopicDescription-font">
                    Les denrées alimentaires contiennent de nombreux nutriments
                    différents, tels que les protéines, les graisses, les
                    glucides, les vitamines et les minéraux ! Les protéines, les
                    graisses et les glucides ne sont en effet pas les seuls
                    nutriments dont notre corps a besoin. Les vitamines et les
                    minéraux sont également inclus parmi les substances qui
                    doivent être ingérées avec les aliments. Rappelez-vous que
                    les nutriments dont notre corps a besoin ne sont pas tous
                    contenus dans un seul aliment, nous devons donc manger
                    toutes sortes d'aliments en variant autant que possible.
                    Découvrez-en davantage sur les différents nutriments !
                  </p>

                  <p className="TopicDescription-font">
                    <strong>Protéines : </strong>
                    Les protéines sont composées de carbone, d'hydrogène,
                    d'oxygène et d'azote ; et une certaine quantité de soufre
                    est également présente. Les protéines sont constituées de
                    différentes briques, appelées acides aminés, liées par des
                    liaisons spéciales (peptides). Les protéines sont
                    indispensables pour construire nos organes et assurer le bon
                    fonctionnement de tous les mécanismes du corps. La bonne
                    quantité de protéines doit être consommée chaque jour, sans
                    exagération ! Les protéines se trouvent principalement dans
                    la viande, le poisson, les œufs et le lait, le yaourt et le
                    fromage. Les protéines végétales sont également très
                    importantes et vous pouvez les trouver dans les légumineuses
                    telles que les haricots, les pois, les pois chiches et les
                    lentilles. De plus, les protéines sont des nutriments
                    énergétiques, en effet, elles peuvent être utilisées pour
                    obtenir de l'énergie en cas de déficience en glucides, comme
                    cela se produit généralement lorsque nous restons longtemps
                    sans manger, ou lorsque la quantité de protéines que nous
                    ingérons dépasse les besoins réels du corps.
                  </p>
                  <img
                    src={imageProteins}
                    style={{ maxWidth: "15%", height: "auto" }}
                  />

                  <p className="TopicDescription-font">
                    <strong>Glucides : </strong>
                    Du point de vue nutritionnel, les glucides peuvent être
                    classés en sucres simples et en sucres complexes. Parmi les
                    sucres simples les plus importants (monosaccharides et
                    disaccharides) se trouvent le glucose, le fructose, le
                    galactose, le saccharose, le lactose et le maltose. Parmi
                    les sucres complexes, celui qui a la plus grande importance
                    nutritionnelle est l'amidon, typiquement abondant dans les
                    céréales, les légumineuses et les pommes de terre. Ils sont
                    une source d'énergie importante à notre disposition. En
                    effet, ils représentent à la fois une ressource énergétique
                    prête à l'emploi et une réserve utile d'énergie au fil du
                    temps. Les glucides devraient être à la base de notre
                    alimentation, car ils fournissent au moins 45 à 50 % de
                    notre énergie quotidienne. Cela ne signifie pas que nous
                    devons manger beaucoup de sucre, il est toujours préférable
                    de privilégier les glucides complexes. C'est pourquoi leur
                    consommation est fortement recommandée à chaque repas ! Au
                    petit-déjeuner, au déjeuner et au dîner, le pain, les pâtes
                    et les céréales ne doivent jamais manquer, de préférence
                    complets ! Cependant, les glucides sont également présents
                    dans les fruits et les légumineuses.
                  </p>
                  <img
                    src={ImageCarbohydrates}
                    style={{ maxWidth: "15%", height: "auto" }}
                  />

                  <p className="TopicDescription-font">
                    <strong>Graisses : </strong>
                    Les graisses les plus importantes, également appelées
                    lipides, du point de vue nutritionnel sont les glycérides,
                    les phospholipides et les stérols. Les glycérides sont des
                    substances de réserve pour les animaux et les plantes et
                    représentent les lipides les plus abondants dans les
                    aliments, dont la principale fonction est énergétique. Les
                    phospholipides, quant à eux, ont une fonction
                    essentiellement structurale, représentant l'échafaudage des
                    membranes biologiques. Enfin, les stérols, y compris le
                    fameux cholestérol, sont présents dans de nombreux aliments,
                    surtout d'origine animale. Le cholestérol, en plus de sa
                    fonction structurale, est utilisé par le corps pour
                    fabriquer certains types d'hormones (stéroïdes) et est le
                    précurseur de la vitamine D. De plus, les graisses peuvent
                    être divisées en 3 groupes selon leur structure : les
                    graisses saturées, que l'on trouve principalement dans la
                    viande rouge et les charcuteries, le beurre, les œufs et le
                    lait entier ; les graisses polyinsaturées que l'on trouve
                    principalement dans l'huile de graines, le poisson et les
                    noix ; et enfin, les graisses monoinsaturées qui sont riches
                    en huile d'olive vierge extra ! Essayez toujours de
                    privilégier les graisses insaturées et utilisez l'huile
                    d'olive comme assaisonnement.
                  </p>
                  <img
                    src={imageFats}
                    style={{ maxWidth: "15%", height: "auto" }}
                  />

                  <p className="TopicDescription-font">
                    <strong>Vitamines : </strong>
                    Les vitamines représentent un groupe de nutriments très
                    différents d'un point de vue chimique, mais elles ont
                    certaines caractéristiques biochimiques et physiologiques
                    communes. En effet, elles ne peuvent pas être produites
                    directement par l'organisme (sauf dans certains cas et en
                    très petites quantités) et ne fournissent pas d'énergie.
                    Étant donné que la demande de l'organisme pour elles est
                    faible, elles sont appelées micronutriments. Cependant, les
                    vitamines sont essentielles pour la croissance ainsi que
                    pour la santé de tout notre organisme : elles renforcent les
                    systèmes nerveux et immunitaire, augmentent la résistance
                    aux infections et favorisent le développement des capacités
                    cognitives. Les vitamines sont classées selon leur
                    solubilité dans les graisses - liposolubles (vitamines A, D,
                    E, K) et hydrosolubles (vitamines du groupe B et vitamine
                    C).
                  </p>
                  <img
                    src={imageVitamins}
                    style={{ maxWidth: "15%", height: "auto" }}
                  />

                  <p className="TopicDescription-font">
                    <strong>Minéraux : </strong>
                    Les sels minéraux sont des substances fondamentales pour
                    notre organisme et, comme les vitamines, ils n'ont pas de
                    valeur énergétique. Les minéraux sont également appelés
                    micronutriments, car l'organisme en a besoin en petites
                    quantités. Les minéraux sont classés en deux catégories,
                    appelées macroéléments et microéléments. Les macroéléments
                    (calcium, phosphore, magnésium, sodium, potassium, chlore et
                    soufre) doivent être ingérés en plus grandes quantités que
                    les microéléments (fer, manganèse, cuivre, iode, zinc,
                    fluor, sélénium et chrome). Cependant, rappelez-vous que
                    cette distinction n'indique pas une plus grande importance
                    des premiers par rapport aux seconds, en fait chaque minéral
                    a sa propre fonction spécifique. Tous les sels minéraux sont
                    importants pour notre corps et aident à le maintenir en
                    bonne santé en accomplissant diverses fonctions, en
                    particulier une fonction biorégulatrice (ils participent à
                    la régulation des réactions métaboliques, à la transmission
                    des influx nerveux, au rythme cardiaque, à la contraction
                    musculaire et au contrôle du pH du corps) et une fonction
                    plastique (ils participent à la formation des os, des dents
                    et du sang).
                  </p>
                  <img
                    src={imageMinerals}
                    style={{ maxWidth: "15%", height: "auto" }}
                  />
                  <br></br>
                  <br></br>
                </div>
              )}
              {currentLanguage === "ar" && (
                <div>
                  <p className="TopicDescription-font-arabic">
                    عناصر الغذاء تحتوي على العديد من المغذيات المختلفة، مثل
                    البروتين والدهون والكربوهيدرات والفيتامينات والمعادن!
                    البروتينات والدهون والكربوهيدرات ليست فقط المغذيات الوحيدة
                    التي يحتاجها جسمنا. تُدرج الفيتامينات والمعادن أيضًا ضمن
                    المواد التي يجب تناولها مع الطعام. تذكر أن المغذيات التي
                    يحتاجها جسمنا لا تتواجد جميعها في طعام واحد، لذا يجب أن
                    نتناول جميع أنواع الأطعمة المتنوعة قدر الإمكان. تعرف على
                    المزيد حول المغذيات المختلفة!
                  </p>

                  <p className="TopicDescription-font-arabic">
                    <strong>البروتينات: </strong>
                    تتكون البروتينات من الكربون والهيدروجين والأكسجين
                    والنيتروجين؛ وتوجد كمية معينة من الكبريت أيضًا. تتكون
                    البروتينات من كتل مختلفة، تُسمى الأحماض الأمينية، مرتبطة
                    بروابط خاصة (ببتيدات). البروتينات ضرورية لبناء أعضائنا وضمان
                    عمل جميع آليات الجسم. يجب تناول الكمية المناسبة من البروتين
                    يوميًا، دون مبالغة! البروتينات موجودة بشكل رئيسي في اللحوم
                    والأسماك والبيض والحليب واللبن والجبن. مهمة أيضًا جدًا
                    البروتينات النباتية التي يمكنك العثور عليها في البقوليات مثل
                    الفاصوليا والبازلاء والحمص والعدس. بالإضافة إلى ذلك، تُعتبر
                    البروتينات مغذيات طاقية، فعندما نعاني من نقص في
                    الكربوهيدرات، كما يحدث عادة عندما نبقى لفترة طويلة بدون
                    تناول الطعام، أو عندما تتجاوز كمية البروتين التي نتناولها
                    الاحتياجات الفعلية للجسم، يمكن استخدامها للحصول على الطاقة.
                  </p>
                  <img
                    src={imageProteins}
                    style={{ maxWidth: "15%", height: "auto" }}
                  />

                  <p className="TopicDescription-font-arabic">
                    <strong>الكربوهيدرات: </strong>
                    من الناحية الغذائية يمكن تصنيف الكربوهيدرات إلى سكريات بسيطة
                    وسكريات معقدة. ومن أهم السكريات البسيطة (السكريات الأحادية
                    والثنائية) الجلوكوز والفركتوز والجلاكتوز والسكروز واللاكتوز
                    والمالتوز. ومن بين السكريات المعقدة، فإن النشا له أكبر أهمية
                    غذائية، وهو متوفر عادة في الحبوب والبقوليات والبطاطس. فهي
                    مصدر مهم للطاقة تحت تصرفنا. في الواقع، فهي تمثل مصدر طاقة
                    جاهزًا للاستخدام واحتياطيًا مفيدًا للطاقة مع مرور الوقت. يجب
                    أن تكون الكربوهيدرات أساس نظامنا الغذائي، لأنها توفر ما لا
                    يقل عن 45-50٪ من طاقتنا اليومية. وهذا لا يعني أن علينا تناول
                    الكثير من السكر، فمن الأفضل دائمًا تفضيل الكربوهيدرات
                    المعقدة. ولهذا السبب يوصى بشدة باستهلاكها في كل وجبة! في
                    وجبات الإفطار والغداء والعشاء، لا يمكنك أبدًا تفويت الخبز
                    والمعكرونة والحبوب، ويفضل الحبوب الكاملة! ومع ذلك، توجد
                    الكربوهيدرات أيضًا في الفاكهة والبقوليات.
                  </p>
                  <img
                    src={ImageCarbohydrates}
                    style={{ maxWidth: "15%", height: "auto" }}
                  />

                  <p className="TopicDescription-font-arabic">
                    <strong>الدهون: </strong>
                    أهم الدهون من الناحية الغذائية هي الجليسريدات، والدهون
                    الفوسفاتية، والستيرول. الجلسريدات هي مواد احتياطية للحيوانات
                    والنباتات وتمثل الدهون الأكثر وفرة في الغذاء، ووظيفتها
                    الرئيسية هي الطاقة. من ناحية أخرى، تتمتع الدهون الفوسفاتية
                    بوظيفة هيكلية أساسية، تمثل سقالات الأغشية البيولوجية.
                    وأخيرا، فإن الستيرول، بما في ذلك الكولسترول الشهير، موجود في
                    العديد من الأطعمة، وخاصة ذات الأصل الحيواني. يستخدم الجسم
                    الكوليسترول، بالإضافة إلى وظيفته الهيكلية، لبناء أنواع معينة
                    من الهرمونات (الستيرويدات) وهو مقدمة لفيتامين د. علاوة على
                    ذلك، يمكن تقسيم الدهون إلى ثلاث مجموعات حسب تركيبها: الدهون
                    المشبعة، والتي توجد بشكل رئيسي في اللحوم الحمراء واللحوم
                    المعالجة والزبدة والبيض والحليب كامل الدسم؛ والدهون المتعددة
                    غير المشبعة الموجودة بشكل رئيسي في زيت البذور والأسماك
                    والمكسرات؛ وأخيرًا، الدهون الأحادية غير المشبعة الغنية بزيت
                    الزيتون البكر الممتاز! حاولي دائمًا تفضيل الدهون غير
                    المشبعة، واستخدمي زيت الزيتون كتوابل.
                  </p>
                  <img
                    src={imageFats}
                    style={{ maxWidth: "15%", height: "auto" }}
                  />

                  <p className="TopicDescription-font-arabic">
                    <strong>الفيتامينات: </strong>
                    تمثل الفيتامينات مجموعة متنوعة من المواد المغذية من الناحية
                    الكيميائية، ولكن لديها بعض الخصائص البيوكيميائية
                    والفسيولوجية المشتركة، حيث لا يمكن إنتاجها مباشرة من قبل
                    الجسم (إلا في بعض الحالات وبكميات قليلة جدًا) ولا توفر طاقة.
                    نظرًا لأن الطلب عليها ضئيل، يُطلق عليها المغذيات الدقيقة.
                    ومع ذلك، تعد الفيتامينات ضرورية للنمو وكذلك لصحة جسمنا
                    بأكمله: فهي تقوي الجهاز العصبي والمناعي، وتزيد من مقاومة
                    الجسم للالتهابات وتساعد على تطوير القدرات المعرفية. يتم
                    تصنيف الفيتامينات وفقًا لذوبانها في الدهون - فيتامينات ذائبة
                    في الدهون (A، D، E، K) وذائبة في الماء (فيتامينات B وفيتامين
                    C).
                  </p>
                  <img
                    src={imageVitamins}
                    style={{ maxWidth: "15%", height: "auto" }}
                  />

                  <p className="TopicDescription-font-arabic">
                    <strong>المعادن: </strong>
                    تعتبر الأملاح المعدنية أساسية لجسمنا، وكما هو الحال مع
                    الفيتامينات، فإنها لا تحتوي على قيمة طاقية. يُطلق على
                    المعادن أيضًا اسم المغذيات الدقيقة، حيث يحتاج الجسم إليها
                    بكميات صغيرة. تُصنف المعادن إلى فئتين، تُعرف باسم العناصر
                    الكبرى والعناصر الصغرى. العناصر الكبرى (الكالسيوم، الفوسفور،
                    المغنيسيوم، الصوديوم، البوتاسيوم، الكلور، والكبريت) يجب أن
                    تُستهلك بكميات أكبر من العناصر الصغرى (الحديد، المنغنيز،
                    النحاس، اليود، الزنك، الفلور، السيلينيوم والكروم). ومع ذلك،
                    تذكر أن هذا التمييز لا يُشير إلى أهمية أكبر للعناصر الكبرى
                    مقارنة بالعناصر الصغرى، في الواقع كل معدن له وظيفته الخاصة.
                    جميع الأملاح المعدنية مهمة لجسمنا وتساعدنا في الحفاظ على
                    صحتنا من خلال أداء وظائف مختلفة، وخاصة الوظيفة البيولوجية
                    التنظيمية (حيث تشارك في تنظيم التفاعلات الأيضية، ونقل
                    الإشارات العصبية، ونبض القلب، وانقباض العضلات، وفي التحكم في
                    درجة حموضة الجسم) والوظيفة البلاستيكية (حيث تشارك في تكوين
                    العظام والأسنان والدم).
                  </p>
                  <img
                    src={imageMinerals}
                    style={{ maxWidth: "15%", height: "auto" }}
                  />
                  <br></br>
                  <br></br>
                </div>
              )}
            </div>

            <button className="back-arrow" onClick={goBack}>
              <FontAwesomeIcon icon={faHome} /> {t("Educational_Material_Text")}{" "}
            </button>
          </motion.div>
        </div>
      </div>
      <div className="footer-sticky">{!isMobile && <Footer />} </div>
    </div>
  );
}

export default EdMaterialTopic4;
