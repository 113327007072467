import React, { useEffect, useState } from "react";

function TestAPI() {
  const [menuData, setMenuData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = {
          current_monday: "22-01-2024",
          child_school_name: "esp",
          child_snack: "y",
          child_milk: "y",
          config_language: "en",
        };

        const formData = new URLSearchParams(data).toString();

        const response = await fetch(
          "https://ailab.unipr.it/s2h/get-menu.php",
          {
            method: "POST",
            headers: {
              "Content-type": "application/x-www-form-urlencoded",
            },
            body: formData,
          }
        );

        if (response.ok) {
          const data = await response.json();
          setMenuData(data);
        } else {
          // console.error("Error fetching data:", response.statusText);
        }
      } catch (error) {
        // console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <div>
      {menuData ? (
        <>
          <h2>Menu Dates:</h2>
          <ul>
            {menuData.date.map((date, index) => (
              <li key={index}>{date}</li>
            ))}
          </ul>

          <h2>Meal Information:</h2>
          <ul>
            {menuData.meals.map((meal, index) => (
              <li key={index}>
                <strong>Day {index + 1}:</strong> Breakfast: {meal.b}, Morning
                Snack: {meal.ms}, Lunch: {meal.l}, Afternoon Snack:{meal.as},
                Dinner: {meal.d}
              </li>
            ))}
          </ul>
        </>
      ) : (
        <p>Loading data...</p>
      )}
    </div>
  );
}

export default TestAPI;
