import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useRoutes } from "react-router-dom";
import axios from "axios";
import logo from "../images/logo/logowhite.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDoorOpen, faBars } from "@fortawesome/free-solid-svg-icons";
import jwt_decode from "jwt-decode";
import { motion } from "framer-motion";
import "./sideBar.css";
import { useTranslation } from "react-i18next";
import Footer2 from "../components/footer2.js";
import { faTwitter } from "@fortawesome/free-brands-svg-icons";
import { faLinkedin } from "@fortawesome/free-brands-svg-icons";
import { faTiktok } from "@fortawesome/free-brands-svg-icons";
import { faYoutube } from "@fortawesome/free-brands-svg-icons";
import { faLink } from "@fortawesome/free-solid-svg-icons";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

import userProfileImg1 from "../images/userProfile/default.png";
import userProfileImg2 from "../images/userProfile/cat.png";
import userProfileImg3 from "../images/userProfile/dog.png";
import userProfileImg4 from "../images/userProfile/sheep.png";
import userProfileImg5 from "../images/userProfile/bear.png";
import userProfileImg6 from "../images/userProfile/wolf.png";
import userProfileImg7 from "../images/userProfile/tiger.png";
import userProfileImg8 from "../images/userProfile/frog.png";
import userProfileImg9 from "../images/userProfile/pig.png";

function SideBar2({ userImgName }) {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const [selectedAnimation, setSelectedAnimation] = useState(null);

  const [authTokens, setAuthTokens] = useState(() =>
    localStorage.getItem("authTokens")
      ? JSON.parse(localStorage.getItem("authTokens"))
      : null
  );
  const [user, setUser] = useState(() =>
    localStorage.getItem("authTokens")
      ? jwt_decode(localStorage.getItem("authTokens"))
      : null
  );
  const [userProfileId, setUserProfileId] = useState(() =>
    localStorage.getItem("child") ? localStorage.getItem("child") : null
  );

  const [profileImgName, setProfileImgName] = useState();
  const [isOpen, setIsOpen] = useState(false);

  const itemVariants = {
    open: {
      opacity: 1,
      y: 0,
      transition: { type: "spring", stiffness: 300, damping: 24 },
    },
    closed: { opacity: 0, y: 20, transition: { duration: 0.2 } },
  };

  const imageMap = {
    apim1: userProfileImg1,
    apim2: userProfileImg2,
    apim3: userProfileImg3,
    apim4: userProfileImg4,
    apim5: userProfileImg5,
    apim6: userProfileImg6,
    apim7: userProfileImg7,
    apim8: userProfileImg8,
    apim9: userProfileImg9,
  };

  const animations = [
    // {
    //   animate: { scale: [1, 1.5, 1], rotate: 360 },
    //   transition: { duration: 1 },
    // },
    {
      animate: { scale: [1, 1.5, 1], y: [0, -10, 0] },
      transition: { duration: 1 },
    },
    // {
    //   animate: { scale: [1, 1.5, 1], rotate: -360 },
    //   transition: { duration: 1 },
    // },
  ];

  const Logout = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(`blacklist_token`, {
        authTokens,
      });
      setAuthTokens(null);
      setUser(null);
      localStorage.removeItem("authTokens");
      localStorage.removeItem("adult");
      localStorage.removeItem("child");
      navigate("/");
    } catch (error) {
      console.error(error);
    }
  };

  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
      setIsSidebarOpen(window.innerWidth > 768); // Automatically open the sidebar on larger screens
    };

    handleResize(); // Initial check

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${userProfileId}/childProfileImgName`
        );
        // console.log(response.data.adult[0]);
        setProfileImgName(response.data.child[0]);
      } catch {}
    };
    fetchData();

    const animation = animations[Math.floor(Math.random() * animations.length)];
    setSelectedAnimation(animation);
  }, []);

  return (
    <div className="page-container">
      <div className="main-content">
        <div className={`sidebar ${isSidebarOpen ? "open" : ""}`}>
          <div className="logo-container">
            <Link to="/childHome">
              <img src={logo} style={{ maxWidth: "100%", height: "auto" }} />
            </Link>
          </div>
          <div className="menu-options-container">
            {profileImgName && (
              <div className="profile-container">
                <div className="icon">
                  <motion.img
                    src={imageMap[profileImgName.image]}
                    alt={`Profile ${profileImgName.username}`}
                    // className="sb-profile-icon"
                    animate={selectedAnimation.animate}
                    transition={{
                      duration: 1,
                    }}
                  />
                </div>
                <div className="text">
                  <div>{profileImgName.username}</div>
                  <Link to="/accounts">{t("switch_account")}</Link>
                </div>
              </div>
            )}
            <hr></hr>
            <Link
              to="/childHome"
              className={`menu-option ${
                location.pathname === "/childHome" ? "selected" : ""
              }`}
            >
              <label className="custom-font3" style={{ cursor: "pointer" }}>
                {t("Home")}
              </label>
            </Link>
            <hr></hr>
            <Link
              to="/childProfile"
              className={`menu-option ${
                location.pathname === "/childProfile" ? "selected" : ""
              }`}
            >
              <label className="custom-font3" style={{ cursor: "pointer" }}>
                {t("Child_profile")}
              </label>
            </Link>
            <hr></hr>
            {/* <Link
              to="/educationalMaterials2"
              className={`menu-option ${
                location.pathname === "/educationalMaterials2" ? "selected" : ""
              }`}
            >
              <label className="custom-font3" style={{ cursor: "pointer" }}>
                {t("ed_material")}
              </label>
            </Link>
            <hr></hr> */}
            <Link
              to="/digitamiGame2"
              className={`menu-option ${
                location.pathname === "/digitamiGame2" ? "selected" : ""
              }`}
            >
              <label className="custom-font3" style={{ cursor: "pointer" }}>
                {t("game")}
              </label>
            </Link>
            <hr></hr>
            <Link
              to="/about2"
              className={`menu-option ${
                location.pathname === "/about2" ? "selected" : ""
              }`}
            >
              <label className="custom-font3" style={{ cursor: "pointer" }}>
                {t("About")}
              </label>
            </Link>
            <hr></hr>
            {/* <Link
              to="/"
              className={`menu-option ${
                location.pathname === "/" ? "selected" : ""
              }`}
            >
              <div className="sign-out-button">
                <label
                  className="custom-font3"
                  style={{ cursor: "pointer" }}
                  onClick={Logout}
                >
                  {t("Logout")}
                </label>
              </div>
            </Link>
            <hr></hr> */}
          </div>
          <div className="social-icons">
            <p className="custom-font">
              <a
                href="https://switchtohealthy.eu/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon
                  icon={faLink}
                  // beatFade
                  style={{ color: "#f3606f" }}
                />
              </a>
              <a
                href="https://www.youtube.com/@switchtohealthy6290"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon
                  icon={faYoutube}
                  // beatFade
                  style={{ color: "#ff0000" }}
                />
              </a>
              <a
                href="https://twitter.com/SWITCHtoHEALTH1"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon
                  icon={faTwitter}
                  // beatFade
                  style={{ color: "#1DA1F2" }}
                />
              </a>
              <a
                href="https://www.linkedin.com/company/switch-to-healthy/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon
                  icon={faLinkedin}
                  // beatFade
                  style={{ color: "#0077B5" }}
                />
              </a>
              <a
                href="https://www.tiktok.com/@switchtohealthy"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon
                  icon={faTiktok}
                  className="tiktok-icon"
                  // beatFade
                  style={{ color: "#28ffff" }}
                />
              </a>
            </p>
          </div>

          {isMobile && <Footer2 />}
        </div>
        <div
          className={`sidebar-toggle ${isSidebarOpen ? "open" : ""}`}
          onClick={toggleSidebar}
        >
          <div className="expand-sidebar">
            <FontAwesomeIcon
              icon={isSidebarOpen ? faTimes : faBars}
              size="2x"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
export default SideBar2;
