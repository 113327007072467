import React from "react";
import "./Card.css";
import axios from "axios";

export const Card = ({
  imgSrc,
  imgAlt,
  title,
  subtitle,
  description,
  userProfileId,
  buttonText,
  link,
}) => {

  const handleAction = async (actionName) => {
    try {
      const response = await axios.post("userProfileActions", {
        userid: userProfileId,
        action: actionName,
      });
      // console.log("Action successfully sent:", response.data);
    } catch (error) {
      // console.error("Error in handleAction:", error);
    }
  };

  return (
    <div className="card-container">
      {imgSrc && imgAlt && (
        <img src={imgSrc} alt={imgAlt} className="card-img" />
      )}
      {title && <h1 className="card-title">{title}</h1>}
      {subtitle && <h2 className="card-subtitle">{subtitle}</h2>}
      {description && <p className="card-description">{description}</p>}
      {buttonText && link && (
        <a href={link} className="card-btn" onClick={() => handleAction(title)}>
          {buttonText}
        </a>
      )}
    </div>
  );
};
