import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import jwt_decode from "jwt-decode";
import "./EdMaterialTopic.css";
import SideBar from "../../components/sideBar.js";
import Footer from "../../components/footer.js";
import ImageTopic1 from "../../images/EdMaterial/Topic1.jpg";
import ImageTopic1_tr from "../../images/EdMaterial/Topic1_tr.png";
import LogoutAfterInactivity from "../../components/logoutAfterInactivity.js";
import { useTranslation } from "react-i18next";
import { motion } from "framer-motion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome } from "@fortawesome/free-solid-svg-icons";

function EdMaterialTopic1() {
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;

  const [isMobile, setIsMobile] = useState(false);
  const navigate = useNavigate();
  const [authTokens, setAuthTokens] = useState(() =>
    localStorage.getItem("authTokens")
      ? JSON.parse(localStorage.getItem("authTokens"))
      : null
  );
  const [user, setUser] = useState(() =>
    localStorage.getItem("authTokens")
      ? jwt_decode(localStorage.getItem("authTokens"))
      : null
  );
  const [userProfileId, setUserProfileId] = useState(() =>
    localStorage.getItem("adult") ? localStorage.getItem("adult") : null
  );
  useEffect(() => {
    if (user == null || user.is_active == false || userProfileId == null) {
      navigate("/");
    } else {
      const handleResize = () => {
        setIsMobile(window.innerWidth <= 768);
      };

      handleResize(); // Initial check

      window.addEventListener("resize", handleResize);
      window.scrollTo({ top: 0, behavior: "smooth" });
      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }
  }, []);
  LogoutAfterInactivity();

  const topicNumber = 1;
  // Handlers for the arrows
  const goToPreviousPage = () => {
    const previousPageNumber = topicNumber - 1; // Calculate the previous page number
    navigate(`/topic${previousPageNumber}`); // Use the previous page number in the route
  };

  const goToNextPage = () => {
    const nextPageNumber = topicNumber + 1; // Calculate the next page number
    navigate(`/topic${nextPageNumber}`); // Use the next page number in the route
  };
  const goBack = () => {
    navigate(`/educationalMaterials`); // Use the back button
  };
  return (
    <div className="topic-rightpart">
      <div
        style={{
          backgroundSize: "cover", // This will resize the image to cover the entire element
          backgroundRepeat: "no-repeat", // This will prevent the image from repeating
          backgroundPosition: "center", // This will position the image at the center of the element
        }}
      >
        <div>
          <SideBar />
          <motion.div
            initial={{ x: "40%" }}
            animate={{ x: "0%" }}
            transition={{ duration: 0.3, ease: "easeOut" }}
            className="Topic-Page"
          >
            <div className="header-container">
              {/* Previous Arrow */}
              <button className="prev-arrow" onClick={goToPreviousPage}>
                &larr;
              </button>

              {/* Title and Subtitle */}
              <div className="title-container">
                <h1>{t(`Topic${topicNumber}Title`)}</h1>
                <h3>{t(`Topic${topicNumber}Subtitle`)}</h3>
              </div>

              {/* Next Arrow */}
              <button className="next-arrow" onClick={goToNextPage}>
                &rarr;
              </button>
            </div>

            <br></br>

            <div>
              {currentLanguage === "en" && (
                <div>
                  <p className="TopicDescription-font">
                    <p>
                      The Mediterranean Diet, since ancient times, represents
                      the typical food style of the countries facing the
                      Mediterranean Sea. At the end of November 2010, the
                      Mediterranean Diet was proclaimed by UNESCO as an
                      "intangible cultural heritage of humanity", but it was
                      already recognized by the WHO and FAO as a "healthy,
                      sustainable and high-quality food model". It is not only a
                      dietary regimen, in fact, the Mediterranean Diet also
                      includes traditions, flavors, tasty recipes, habits and
                      lifestyles typical of the Mediterranean countries. This
                      food tradition includes the choice of simple but tasty
                      foods, with positive effects on health, representing not
                      only a dietary regimen but a real way towards wellness.
                      The benefits of the Mediterranean Diet are many! A lot of
                      scientific evidence attributed to the Mediterranean Diet a
                      positive effect on the prevention of diabetes, obesity,
                      cardiovascular diseases and cancer.{" "}
                    </p>
                    <p>
                      {" "}
                      For better effectiveness, however, it is necessary to
                      adopt a true Mediterranean lifestyle characterized not
                      only by a balanced diet but also by regular physical
                      activity. In fact, a good diet must always include the
                      right " portion " of physical activity... to combat
                      sedentariness you can start with a 30-minute walk every
                      day! Let's not forget the importance of social and
                      cultural aspects in the Mediterranean Diet... the overall
                      wellbeing of our organism can be further enhanced by tasty
                      and balanced recipes, common meals eaten in a convivial
                      environment and regular rest after meals.{" "}
                    </p>
                    <p>
                      {" "}
                      The Mediterranean Diet is based on a high consumption of
                      vegetable fats (virgin olive oil and nuts) and
                      low-processed vegetable foods (vegetables, fruit, whole
                      grains and legumes), low consumption of meat (especially
                      red and processed meat) and sweets and moderate
                      consumption of fish, milk and dairy products (mainly
                      yoghurt and fresh cheese).{" "}
                    </p>
                    <p>
                      A simple and immediate way to summarize the fundamental
                      points of the Mediterranean Diet can be seen in the
                      graphic representation of the Mediterranean food pyramid.
                      It is a useful tool that will guide you in planning your
                      child's and the whole family's meals every day. The
                      pyramid is composed of different floors or levels, at the
                      base you will find the foods that we can consume without
                      restrictions, while at the top you will find those to be
                      consumed only occasionally. At the sides of the pyramid
                      are indicated the ideal weekly consumption frequencies for
                      each food group, however, remember that there is not an
                      equal portion for everyone but the servings change
                      according to everyone's needs.
                    </p>
                    <p>
                      Bring the Mediterranean Diet to the table and enjoy it
                      with the whole family!
                    </p>
                  </p>
                  <img
                    src={ImageTopic1}
                    style={{ maxWidth: "100%", height: "auto" }}
                  />
                  <p className="TopicDescription-font">
                    <p>
                      In our journey to discover the Mediterranean Diet, we will
                      invite you to discover some typical foods of the
                      Mediterranean food tradition.
                    </p>
                    <p>
                      Moreover, we will suggest you many tasty and healthy
                      recipes that you can try together with your family.
                    </p>
                  </p>
                </div>
              )}

              {currentLanguage === "es" && (
                <div>
                  <p className="TopicDescription-font">
                    <p>
                      La Dieta Mediterránea, desde la antigüedad, representa el
                      estilo de alimentación típico de los países frente al mar
                      Mediterráneo. A finales de noviembre de 2010, la Dieta
                      Mediterránea fue proclamada por la UNESCO como "patrimonio
                      cultural inmaterial de la humanidad", pero ya fue
                      reconocida por la OMS y la FAO como un "modelo alimentario
                      saludable, sostenible y de alta calidad". No es sólo un
                      régimen dietético, de hecho, la Dieta Mediterránea también
                      incluye tradiciones, sabores, recetas sabrosas, hábitos y
                      estilos de vida propios de los países mediterráneos. Esta
                      tradición alimentaria incluye la elección de alimentos
                      sencillos pero sabrosos, con efectos positivos para la
                      salud, representando no sólo un régimen dietético sino un
                      verdadero camino hacia el bienestar. ¡Los beneficios de la
                      Dieta Mediterránea son muchos! Numerosas evidencias
                      científicas atribuyen a la Dieta Mediterránea un efecto
                      positivo en la prevención de la diabetes, la obesidad, las
                      enfermedades cardiovasculares y el cáncer.{" "}
                    </p>
                    <p>
                      {" "}
                      Sin embargo, para una mayor eficacia es necesario adoptar
                      un auténtico estilo de vida mediterráneo caracterizado no
                      sólo por una dieta equilibrada sino también por una
                      actividad física regular. De hecho, una buena alimentación
                      siempre debe incluir la " ración " adecuada de actividad
                      física... ¡para combatir el sedentarismo puedes empezar
                      con una caminata de 30 minutos todos los días! No
                      olvidemos la importancia de los aspectos sociales y
                      culturales en la Dieta Mediterránea... el bienestar
                      general de nuestro organismo puede mejorarse aún más con
                      recetas sabrosas y equilibradas, comidas comunes
                      realizadas en un ambiente agradable y un descanso regular
                      después de las comidas.{" "}
                    </p>
                    <p>
                      {" "}
                      La Dieta Mediterránea se basa en un alto consumo de grasas
                      vegetales (aceite de oliva virgen y frutos secos) y
                      alimentos vegetales poco procesados (hortalizas, frutas,
                      cereales integrales y legumbres), un bajo consumo de
                      carnes (especialmente carnes rojas y procesadas) y dulces
                      y un consumo moderado de pescado, leche y productos
                      lácteos (principalmente yogur y queso fresco).{" "}
                    </p>
                    <p>
                      Una forma sencilla e inmediata de resumir los puntos
                      fundamentales de la Dieta Mediterránea se puede ver en la
                      representación gráfica de la pirámide alimenticia
                      mediterránea. Es una herramienta útil que te guiará en la
                      planificación diaria de las comidas de tu hijo y de toda
                      la familia. La pirámide se compone de diferentes pisos o
                      niveles, en la base encontrarás los alimentos que podemos
                      consumir sin restricciones, mientras que en la parte
                      superior encontrarás aquellos para consumir sólo
                      ocasionalmente. A los lados de la pirámide se indican las
                      frecuencias de consumo semanales ideales para cada grupo
                      de alimentos, sin embargo, recuerda que no hay una ración
                      igual para todos, sino que las raciones cambian según las
                      necesidades de cada uno.
                    </p>
                    <p>
                      ¡Lleva la Dieta Mediterránea a la mesa y disfrútala con
                      toda la familia!
                    </p>
                  </p>
                  <img
                    src={ImageTopic1}
                    style={{ maxWidth: "100%", height: "auto" }}
                  />
                  <p className="TopicDescription-font">
                    <p>
                      En nuestro viaje por descubrir la Dieta Mediterránea, te
                      invitaremos a descubrir algunos alimentos típicos de la
                      tradición gastronómica mediterránea.
                    </p>
                    <p>
                      Además, te propondremos muchas recetas ricas y saludables
                      que podrás probar junto con tu familia.
                    </p>
                  </p>
                </div>
              )}

              {currentLanguage === "tr" && (
                <div>
                  <p className="TopicDescription-font">
                    <p>
                      Akdeniz Diyeti, eski çağlardan beri Akdeniz'deki ülkelerin
                      tipik beslenme tarzını temsil etmektedir. Akdeniz Diyeti,
                      Kasım 2010'un sonunda UNESCO tarafından "insanlığın somut
                      olmayan kültürel mirası" olarak ilan edildi, ancak DSÖ ve
                      FAO tarafından zaten "sağlıklı, sürdürülebilir ve kaliteli
                      bir gıda modeli" olarak tanınmıştı. Akdeniz Diyeti aslında
                      sadece bir beslenme rejimi değil, aynı zamanda Akdeniz
                      ülkelerine özgü gelenekleri, tatları, leziz tarifleri,
                      alışkanlıkları ve yaşam tarzlarını da içeriyor. Bu yemek
                      geleneği, sağlık üzerinde olumlu etkileri olan, yalnızca
                      bir beslenme rejimini değil aynı zamanda sağlıklı yaşama
                      giden gerçek bir yolu temsil eden basit ama lezzetli
                      yiyeceklerin seçimini içerir. Akdeniz Diyetinin faydaları
                      çoktur! Akdeniz Diyetinin diyabet, obezite, kalp-damar
                      hastalıkları ve kanserin önlenmesinde olumlu etkisi
                      olduğuna atfedilen birçok bilimsel kanıt bulunmaktadır.{" "}
                    </p>
                    <p>
                      {" "}
                      Ancak daha iyi bir etkinlik için, yalnızca dengeli
                      beslenmeyle değil, aynı zamanda düzenli fiziksel
                      aktiviteyle karakterize edilen gerçek bir Akdeniz yaşam
                      tarzını benimsemek gerekir. Aslında, iyi bir diyet her
                      zaman fiziksel aktivitenin doğru "bölümünü" içermelidir...
                      Hareketsizliğe karşı mücadele etmek için her gün 30
                      dakikalık bir yürüyüşle başlayabilirsiniz! Akdeniz
                      Diyetinde sosyal ve kültürel unsurların önemini
                      unutmayalım... Organizmamızın genel refahı, lezzetli ve
                      dengeli yemek tarifleri, keyifli bir ortamda yenilen ortak
                      yemekler ve yemek sonrası düzenli dinlenme ile daha da
                      artırılabilir.{" "}
                    </p>
                    <p>
                      {" "}
                      Akdeniz Diyeti, bitkisel yağların (sızma zeytinyağı ve
                      kuruyemişler) yüksek tüketimi ve az işlenmiş bitkisel
                      gıdalar (sebzeler, meyveler, tam tahıllar ve baklagiller),
                      etin (özellikle kırmızı ve işlenmiş et) ve tatlıların
                      düşük tüketimine dayanmaktadır. Orta düzeyde balık, süt ve
                      süt ürünleri tüketimi (çoğunlukla yoğurt ve taze peynir).{" "}
                    </p>
                    <p>
                      Akdeniz Diyetinin temel noktalarını özetlemenin basit ve
                      hızlı bir yolu, Akdeniz besin piramidinin grafik
                      gösteriminde görülebilir. Çocuğunuzun ve tüm ailenizin
                      günlük öğünlerini planlamanızda size yol gösterecek
                      kullanışlı bir araçtır. Piramit farklı katlardan veya
                      seviyelerden oluşur; tabanda kısıtlama olmadan
                      tüketebileceğimiz yiyecekleri bulacaksınız, üstte ise
                      yalnızca ara sıra tüketilebilecek yiyecekleri
                      bulacaksınız. Piramidin kenarlarında her besin grubu için
                      ideal haftalık tüketim sıklıkları belirtilmektedir ancak
                      herkese eşit porsiyon olmadığını, porsiyonların herkesin
                      ihtiyacına göre değiştiğini unutmayın.
                    </p>
                    <p>
                      Akdeniz diyetini sofranıza getirin ve tüm ailenizle tadını
                      çıkartın.
                    </p>
                  </p>
                  <img
                    src={ImageTopic1_tr}
                    style={{ maxWidth: "100%", height: "auto" }}
                  />
                  <p className="TopicDescription-font">
                    <p>
                      Akdeniz Diyetini keşfetme yolculuğumuzda sizleri Akdeniz
                      yemek geleneğinin bazı tipik yemeklerini keşfetmeye davet
                      edeceğiz.
                    </p>
                    <p>
                      Üstelik ailenizle birlikte deneyebileceğiniz birçok
                      lezzetli ve sağlıklı tarifler önereceğiz.
                    </p>
                  </p>
                </div>
              )}
              {currentLanguage === "fr" && (
                <div>
                  <p className="TopicDescription-font">
                    <p>
                      La Diète Méditerranéenne, depuis l'Antiquité, représente
                      le style alimentaire typique des pays bordant la mer
                      Méditerranée. À la fin du mois de novembre 2010, la Diète
                      Méditerranéenne a été proclamée par l'UNESCO comme un
                      "patrimoine culturel immatériel de l'humanité", mais elle
                      était déjà reconnue par l'OMS et la FAO comme un "modèle
                      alimentaire sain, durable et de haute qualité". Ce n'est
                      pas seulement un régime alimentaire, en fait, la Diète
                      Méditerranéenne inclut également des traditions, des
                      saveurs, des recettes savoureuses, des habitudes et des
                      modes de vie typiques des pays méditerranéens. Cette
                      tradition alimentaire inclut le choix de produits simples
                      mais savoureux, avec des effets positifs sur la santé,
                      représentant non seulement un régime alimentaire mais un
                      véritable chemin vers le bien-être. Les bienfaits de la
                      Diète Méditerranéenne sont nombreux ! Beaucoup de preuves
                      scientifiques attribuent à la Diète Méditerranéenne un
                      effet positif sur la prévention du diabète, de l'obésité,
                      des maladies cardiovasculaires et du cancer.{" "}
                    </p>
                    <p>
                      {" "}
                      Pour une meilleure efficacité, cependant, il est
                      nécessaire d'adopter un véritable mode de vie
                      méditerranéen caractérisé non seulement par une
                      alimentation équilibrée mais aussi par une activité
                      physique régulière. En fait, un bon régime alimentaire
                      doit toujours inclure la bonne "portion" d'activité
                      physique... pour lutter contre la sédentarité, vous pouvez
                      commencer par une marche de 30 minutes chaque jour !
                      N'oublions pas l'importance des aspects sociaux et
                      culturels dans la Diète Méditerranéenne... le bien-être
                      général de notre organisme peut être encore amélioré par
                      des recettes savoureuses et équilibrées, des repas communs
                      pris dans un environnement convivial et un repos régulier
                      après les repas.{" "}
                    </p>
                    <p>
                      {" "}
                      La Diète Méditerranéenne est basée sur une consommation
                      élevée de graisses végétales (huile d'olive vierge et
                      noix) et d'aliments végétaux peu transformés (légumes,
                      fruits, céréales complètes et légumineuses), une faible
                      consommation de viande (surtout la viande rouge et
                      transformée) et de sucreries, et une consommation modérée
                      de poisson, de lait et de produits laitiers
                      (principalement le yaourt et le fromage frais). Un moyen
                      simple et immédiat de résumer les points fondamentaux de
                      la Diète Méditerranéenne peut être vu dans la
                      représentation graphique de la pyramide alimentaire
                      méditerranéenne. C'est un outil utile qui vous guidera
                      dans la planification des repas de votre enfant et de
                      toute la famille chaque jour. La pyramide est composée de
                      différents étages ou niveaux, à la base vous trouverez les
                      aliments que nous pouvons consommer sans restriction,
                      tandis qu'au sommet vous trouverez ceux à consommer
                      seulement occasionnellement. Aux côtés de la pyramide sont
                      indiquées les fréquences de consommation hebdomadaires
                      idéales pour chaque groupe alimentaire, cependant,
                      rappelez-vous qu'il n'y a pas une portion égale pour tout
                      le monde mais les portions changent en fonction des
                      besoins de chacun.{" "}
                    </p>
                    <p>
                      Apportez la Diète Méditerranéenne à votre table et
                      savourez-la avec toute la famille !
                    </p>
                  </p>
                  <img
                    src={ImageTopic1}
                    style={{ maxWidth: "100%", height: "auto" }}
                  />
                  <p className="TopicDescription-font">
                    <p>
                      Dans notre voyage à la découverte de la Diète
                      Méditerranéenne, nous vous invitons à découvrir quelques
                      aliments typiques de la tradition alimentaire
                      méditerranéenne. De plus, nous vous suggérerons de
                      nombreuses recettes savoureuses et saines que vous pourrez
                      essayer avec votre famille.
                    </p>
                  </p>
                </div>
              )}
              {currentLanguage === "ar" && (
                <div>
                  <p className="TopicDescription-font-arabic">
                    <p>
                      يعتبر النظام الغذائي المتوسطي منذ العصور القديمة تمثيلًا
                      للنمط الغذائي النموذجي للبلدان المطلة على البحر الأبيض
                      المتوسط. في نهاية نوفمبر 2010، أعلنت اليونسكو النظام
                      الغذائي المتوسطي بأنه "تراثًا ثقافيًا لا ماديًا للبشرية"،
                      ولكنه كان معترف به بالفعل من قبل منظمة الصحة العالمية
                      ومنظمة الأغذية والزراعة باعتباره "نموذجًا للغذاء صحيًا
                      ومستدامًا وذو جودة عالية". إنه ليس فقط نظام غذائي، بل
                      النظام الغذائي المتوسطي يشمل أيضًا التقاليد والنكهات
                      والوصفات اللذيذة والعادات وأساليب الحياة النموذجية للبلدان
                      المتوسطية. يتضمن هذا التقليد الغذائي اختيار الأطعمة
                      البسيطة ولكن اللذيذة، مع آثار إيجابية على الصحة، ممثلة ليس
                      فقط في نظام غذائي ولكن أيضًا في الطريق الحقيقي نحو
                      الرفاهية. فوائد النظام الغذائي المتوسطي كثيرة! العديد من
                      الأدلة العلمية تُنسب إلى النظام الغذائي المتوسطي تأثيرًا
                      إيجابيًا على الوقاية من مرض السكري والسمنة وأمراض القلب
                      والأوعية الدموية والسرطان.{" "}
                    </p>
                    <p>
                      {" "}
                      لكن لتحقيق أقصى فعالية، فمن الضروري اعتماد نمط حياة بحري
                      متوسطي حقيقي يتميز ليس فقط بنظام غذائي متوازن ولكن أيضًا
                      بممارسة النشاط البدني بانتظام. في الواقع، يجب أن يتضمن
                      النظام الغذائي الجيد دائمًا الحصة الصحيحة من النشاط
                      البدني... لمكافحة الجلوس الكثير يمكنك البدء بالمشي لمدة 30
                      دقيقة يوميًا! لا ننسى أهمية الجوانب الاجتماعية والثقافية
                      في النظام الغذائي المتوسطي ... يمكن تعزيز الرفاهية العامة
                      لجسمنا أكثر من خلال وصفات لذيذة ومتوازنة ووجبات مشتركة يتم
                      تناولها في بيئة اجتماعية وراحة منتظمة بعد الوجبات.{" "}
                    </p>
                    <p>
                      {" "}
                      النظام الغذائي المتوسطي يعتمد على استهلاك عالي للدهون
                      النباتية (زيت الزيتون والمكسرات) والأطعمة النباتية ذات
                      التصنيع المنخفض (الخضروات والفواكه والحبوب الكاملة
                      والبقوليات)، واستهلاك منخفض للحوم (خاصة اللحوم الحمراء
                      والمعالجة) والحلويات واستهلاك معتدل للأسماك ومنتجات
                      الألبان (بشكل رئيسي الزبادي والجبن الطازج).{" "}
                    </p>
                    <p>
                      يمكن رؤية طريقة بسيطة وفورية لتلخيص النقاط الأساسية للنظام
                      الغذائي المتوسطي في التمثيل البياني لهرم غذاء البحر الأبيض
                      المتوسط. إنه أداة مفيدة سترشدك في تخطيط وجبات طفلك وعائلتك
                      كل يوم. يتكون الهرم من طوابق أو مستويات مختلفة، حيث ستجد
                      في القاعدة الأطعمة التي يمكننا استهلاكها دون قيود، بينما
                      في القمة ستجد تلك التي يجب استهلاكها فقط بشكل متقطع. على
                      جوانب الهرم يتم تحديد ترددات الاستهلاك الأسبوعية المثالية
                      لكل مجموعة من المواد الغذائية، ولكن تذكر أنه ليس هناك قسم
                      متساوٍ للجميع وإنما تتغير الحصص وفقًا لاحتياجات الجميع.
                    </p>
                    <p>
                      أحضرالنظام الغذائي المتوسطي إلى الطاولة واستمتع به مع
                      العائلة بأكملها!{" "}
                    </p>
                  </p>
                  <img
                    src={ImageTopic1}
                    style={{ maxWidth: "100%", height: "auto" }}
                  />
                  <p className="TopicDescription-font-arabic">
                    <p>
                      في رحلتنا لاكتشاف النظام الغذائي المتوسطي، سندعوكم لاكتشاف
                      بعض الأطعمة النموذجية لتقاليد الطعام المتوسطية.
                    </p>
                    <p>
                      علاوة على ذلك، سنقترح عليكم العديد من الوصفات اللذيذة
                      والصحية التي يمكنكم تجربتها مع عائلتكم.
                    </p>
                  </p>
                </div>
              )}
            </div>

            <button className="back-arrow" onClick={goBack}>
              <FontAwesomeIcon icon={faHome} /> {t("Educational_Material_Text")}{" "}
            </button>
          </motion.div>
        </div>
      </div>
      <div className="footer-sticky">{!isMobile && <Footer />} </div>
    </div>
  );
}

export default EdMaterialTopic1;
