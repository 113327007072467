import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useRoutes } from "react-router-dom";
import axios from "axios";
import logo from "../images/logo/logowhite.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDoorOpen, faBars } from "@fortawesome/free-solid-svg-icons";
import jwt_decode from "jwt-decode";
import { motion } from "framer-motion";
import "./sideBar.css";
import { useTranslation } from "react-i18next";
import Footer2 from "../components/footer2.js";
import { faTwitter } from "@fortawesome/free-brands-svg-icons";
import { faLinkedin } from "@fortawesome/free-brands-svg-icons";
import { faTiktok } from "@fortawesome/free-brands-svg-icons";
import { faYoutube } from "@fortawesome/free-brands-svg-icons";
import { faLink } from "@fortawesome/free-solid-svg-icons";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

import userProfileImg1 from "../images/userProfile/default.png";
import userProfileImg2 from "../images/userProfile/cat.png";
import userProfileImg3 from "../images/userProfile/dog.png";
import userProfileImg4 from "../images/userProfile/sheep.png";
import userProfileImg5 from "../images/userProfile/bear.png";
import userProfileImg6 from "../images/userProfile/wolf.png";
import userProfileImg7 from "../images/userProfile/tiger.png";
import userProfileImg8 from "../images/userProfile/frog.png";
import userProfileImg9 from "../images/userProfile/pig.png";

function SideBar({ userImgName }) {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const [selectedAnimation, setSelectedAnimation] = useState(null);

  const [authTokens, setAuthTokens] = useState(() =>
    localStorage.getItem("authTokens")
      ? JSON.parse(localStorage.getItem("authTokens"))
      : null
  );
  const [user, setUser] = useState(() =>
    localStorage.getItem("authTokens")
      ? jwt_decode(localStorage.getItem("authTokens"))
      : null
  );
  const [userProfileId, setUserProfileId] = useState(() =>
    localStorage.getItem("adult") ? localStorage.getItem("adult") : null
  );

  const [profileImgName, setProfileImgName] = useState();
  const [isOpen, setIsOpen] = useState(false);

  const itemVariants = {
    open: {
      opacity: 1,
      y: 0,
      transition: { type: "spring", stiffness: 300, damping: 24 },
    },
    closed: { opacity: 0, y: 20, transition: { duration: 0.2 } },
  };

  const imageMap = {
    apim1: userProfileImg1,
    apim2: userProfileImg2,
    apim3: userProfileImg3,
    apim4: userProfileImg4,
    apim5: userProfileImg5,
    apim6: userProfileImg6,
    apim7: userProfileImg7,
    apim8: userProfileImg8,
    apim9: userProfileImg9,
  };

  const animations = [
    // {
    //   animate: { scale: [1, 1.5, 1], rotate: 360 },
    //   transition: { duration: 1 },
    // },
    {
      animate: { y: [0, -20, 0], scale: [1, 1.5, 1] },
      transition: { duration: 1 },
    },
    // {
    //   animate: { scale: [1, 1.5, 1], rotate: -360 },
    //   transition: { duration: 1 },
    // },
  ];

  const isChartRouteActive = () => {
    const chartRoutes = [
      "/charts/food_groups",
      "/charts/energy",
      "/charts/macronutrients",
      "/charts/weight",
    ];
    return chartRoutes.some((route) => location.pathname.includes(route));
  };

  const Logout = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(`blacklist_token`, {
        authTokens,
      });
      setAuthTokens(null);
      setUser(null);
      localStorage.removeItem("authTokens");
      localStorage.removeItem("adult");
      localStorage.removeItem("child");
      navigate("/");
    } catch (error) {
      // console.error(error);
    }
  };

  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
      setIsSidebarOpen(window.innerWidth > 768); // Automatically open the sidebar on larger screens
    };

    handleResize(); // Initial check

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${userProfileId}/profileImgName`);
        // console.log(response.data.adult[0]);
        setProfileImgName(response.data.adult[0]);
      } catch {}
    };
    fetchData();

    const animation = animations[Math.floor(Math.random() * animations.length)];
    setSelectedAnimation(animation);
  }, []);

  const handleAction = async (actionName) => {
    try {
      const response = await axios.post("userProfileActions", {
        userid: userProfileId,
        action: actionName,
      });
      // console.log("Action successfully sent:", response.data);
    } catch (error) {
      // console.error("Error in handleAction:", error);
    }
  };

  return (
    <div className="page-container">
      <div className="main-content">
        <div className={`sidebar ${isSidebarOpen ? "open" : ""}`}>
          <div className="logo-container">
            <Link to="/home" onClick={() => handleAction("Home")}>
              <img src={logo} style={{ maxWidth: "100%", height: "auto" }} />
            </Link>
          </div>
          <div className="menu-options-container">
            {profileImgName && (
              <div className="profile-container">
                <div className="icon">
                  <motion.img
                    src={imageMap[profileImgName.image]}
                    alt={`Profile ${profileImgName.username}`}
                    // className="sb-profile-icon"
                    animate={selectedAnimation.animate}
                    transition={{
                      duration: 1,
                    }}
                  />
                </div>
                <div className="text">
                  <div>{profileImgName.username}</div>
                  <Link to="/accounts">{t("switch_account")}</Link>
                </div>
              </div>
            )}
            <hr></hr>

            <Link
              to="/home"
              className={`menu-option ${
                location.pathname === "/home" ? "selected" : ""
              }`}
            >
              <label
                className="custom-font3"
                style={{ cursor: "pointer" }}
                onClick={
                  location.pathname !== "/home"
                    ? () => handleAction("Home")
                    : null
                }
              >
                {t("Home")}
              </label>
            </Link>

            <hr></hr>

            <Link
              to="/userProfile"
              className={`menu-option ${
                location.pathname === "/userProfile" ? "selected" : ""
              }`}
            >
              <label
                className="custom-font3"
                style={{ cursor: "pointer" }}
                onClick={
                  location.pathname !== "/userProfile"
                    ? () => handleAction("userProfile")
                    : null
                }
              >
                {t("User_Profile")}
              </label>
            </Link>

            <hr></hr>
            {/* <div>
                  <button onClick={toggleVisibility}>User Profile</button>
                </div> */}
            {/* <Link to="/stats" className="menu-option">
              <label className="custom-font3">{t("Charts")}</label>
            </Link> */}
            <motion.div
              initial={false}
              animate={isOpen ? "open" : "closed"}
              className="dropdown"
            >
              <button
                className={`btn btn-link dropdown-toggle ${
                  isChartRouteActive() ? "selected" : ""
                }`}
                type="button"
                id="chartsDropdown"
                onClick={() => setIsOpen(!isOpen)}
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <label
                  className={`dropdown ${
                    isChartRouteActive() ? "selected" : ""
                  }`}
                  style={{
                    color: "#fff0ba",
                    cursor: "pointer",
                  }}
                >
                  {t("Charts")}
                </label>
              </button>

              <motion.ul
                variants={{
                  open: {
                    clipPath: "inset(0% 0% 0% 0% round 10px)",
                    transition: {
                      type: "spring",
                      bounce: 0,
                      duration: 0.7,
                      delayChildren: 0.3,
                      staggerChildren: 0.05,
                    },
                  },
                  closed: {
                    clipPath: "inset(10% 50% 90% 50% round 10px)",
                    transition: {
                      type: "spring",
                      bounce: 0,
                      duration: 0.3,
                    },
                  },
                }}
                style={{ pointerEvents: isOpen ? "auto" : "none" }}
                className="dropdown-menu"
                aria-labelledby="chartsDropdown"
              >
                <motion.li variants={itemVariants}>
                  <Link
                    to="/charts/food_groups"
                    className="dropdown-item"
                    onClick={() => handleAction("Food Categories")}
                  >
                    {t("food_categories")}
                  </Link>
                </motion.li>
                <motion.li variants={itemVariants}>
                  <Link
                    to="/charts/energy"
                    className="dropdown-item"
                    onClick={() => handleAction("Energy Intake")}
                  >
                    {t("Energy_Intake")}
                  </Link>
                </motion.li>
                <motion.li variants={itemVariants}>
                  <Link
                    to="/charts/macronutrients"
                    className="dropdown-item"
                    onClick={() => handleAction("Macronutrients")}
                  >
                    {t("Macronutrients")}
                  </Link>
                </motion.li>
                <motion.li variants={itemVariants}>
                  <Link
                    to="/charts/weight"
                    className="dropdown-item"
                    onClick={() => handleAction("Weight History")}
                  >
                    {t("Weight_History")}
                  </Link>
                </motion.li>
              </motion.ul>
            </motion.div>
            <hr></hr>

            <Link
              to="/educationalMaterials"
              className={`menu-option ${
                location.pathname === "/educationalMaterials" ? "selected" : ""
              }`}
            >
              <label
                className="custom-font3"
                style={{ cursor: "pointer" }}
                onClick={
                  location.pathname !== "/educationalMaterials"
                    ? () => handleAction("Educational Material")
                    : null
                }
              >
                {t("ed_material")}
              </label>
            </Link>

            <hr></hr>
            <Link
              to="/digitamiGame"
              className={`menu-option ${
                location.pathname === "/digitamiGame" ? "selected" : ""
              }`}
            >
              <label
                className="custom-font3"
                style={{ cursor: "pointer" }}
                onClick={
                  location.pathname !== "/digitamiGame"
                    ? () => handleAction("Game")
                    : null
                }
              >
                {t("game")}
              </label>
            </Link>
            <hr></hr>
            <Link
              to="/about"
              className={`menu-option ${
                location.pathname === "/about" ? "selected" : ""
              }`}
            >
              <label
                className="custom-font3"
                style={{ cursor: "pointer" }}
                onClick={
                  location.pathname !== "/about"
                    ? () => handleAction("About")
                    : null
                }
              >
                {t("About")}
              </label>
            </Link>
            <hr></hr>
            {/* <Link
              to="/"
              className={`menu-option ${
                location.pathname === "/" ? "selected" : ""
              }`}
            >
              <div className="sign-out-button">
                <label
                  className="custom-font3"
                  style={{ cursor: "pointer" }}
                  onClick={Logout}
                >
                  {t("Logout")}
                </label>
              </div>
            </Link>
            <hr></hr> */}
          </div>
          <div className="social-icons">
            <p className="custom-font">
              <a
                href="https://switchtohealthy.eu/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon
                  icon={faLink}
                  // beatFade
                  style={{ color: "#f3606f" }}
                  onClick={() => handleAction("switchtohealthy.eu")}
                />
              </a>
              <a
                href="https://www.youtube.com/@switchtohealthy6290"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon
                  icon={faYoutube}
                  // beatFade
                  style={{ color: "#ff0000" }}
                  onClick={() => handleAction("Youtube")}
                />
              </a>
              <a
                href="https://twitter.com/SWITCHtoHEALTH1"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon
                  icon={faTwitter}
                  // beatFade
                  style={{ color: "#1DA1F2" }}
                  onClick={() => handleAction("Twitter")}
                />
              </a>
              <a
                href="https://www.linkedin.com/company/switch-to-healthy/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon
                  icon={faLinkedin}
                  // beatFade
                  style={{ color: "#0077B5" }}
                  onClick={() => handleAction("Linkedin")}
                />
              </a>
              <a
                href="https://www.tiktok.com/@switchtohealthy"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon
                  icon={faTiktok}
                  className="tiktok-icon"
                  // beatFade
                  style={{ color: "#28ffff" }}
                  onClick={() => handleAction("Tiktok")}
                />
              </a>
            </p>
          </div>

          {isMobile && <Footer2 />}
        </div>
        <div
          className={`sidebar-toggle ${isSidebarOpen ? "open" : ""}`}
          onClick={toggleSidebar}
        >
          <div className="expand-sidebar">
            <FontAwesomeIcon
              icon={isSidebarOpen ? faTimes : faBars}
              size="2x"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
export default SideBar;
