import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import jwt_decode from "jwt-decode";
import { motion } from "framer-motion";
import SideBar from "../../components/sideBar";
import Footer from "../../components/footer.js";
import "../../components/sideBar.css";
import "../../components/footer.css";
import "./statistics.css";
import { useTranslation } from "react-i18next";
import "chartjs-plugin-annotation";
import LogoutAfterInactivity from "../../components/logoutAfterInactivity";
import { Bubble, Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  RadialLinearScale,
  ArcElement,
  Tooltip,
  Legend,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  RadialLinearScale,
  ArcElement,
  Tooltip,
  Legend
);

function Weight() {
  const [userHistory, setUserHistory] = useState();
  const [loading, setLoading] = useState();
  const [maxWeight, setMaxWeight] = useState();
  const [minWeight, setMinWeight] = useState();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [authTokens, setAuthTokens] = useState(() =>
    localStorage.getItem("authTokens")
      ? JSON.parse(localStorage.getItem("authTokens"))
      : null
  );
  const [user, setUser] = useState(() =>
    localStorage.getItem("authTokens")
      ? jwt_decode(localStorage.getItem("authTokens"))
      : null
  );
  const [userProfileId, setUserProfileId] = useState(() =>
    localStorage.getItem("adult") ? localStorage.getItem("adult") : null
  );

  const [isMobile, setIsMobile] = useState(false);
  useEffect(() => {
    if (user == null || user.is_active == false || userProfileId == null) {
      navigate("/");
    } else {
      const handleResize = () => {
        setIsMobile(window.innerWidth <= 768);
      };
      handleResize(); // Initial check
      window.addEventListener("resize", handleResize);
      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }
  }, []);

  useEffect(() => {
    if (user == null || user.is_active == false || userProfileId == null) {
      navigate("/");
    } else {
      const fetchData = async () => {
        if (user == null) {
          navigate("/");
        } else {
          let timer;
          try {
            timer = setTimeout(() => {
              setLoading(true);
              window.scrollTo({
                top: 0,
                behavior: "smooth",
              });
            }, 200);
            const responseUser = await axios.get(
              `userProfileHistory/${userProfileId}/`
            );
            setUserHistory(responseUser.data);
            limitWeights(responseUser.data);
            //console.log(responseUser.data);
          } catch (error) {
            //console.error(error);
          } finally {
            clearTimeout(timer);
            setLoading(false);
          }
        }
      };
      fetchData();
    }
  }, []);

  const limitWeights = (userHistory) => {
    const weights = [];
    userHistory.forEach((history) => {
      weights.push(history.weight);
    });
    setMaxWeight(Math.max(...weights));
    setMinWeight(Math.min(...weights));
  };

  const formatDate = (inputDate) => {
    const date = new Date(inputDate);
    // Format the date to "DD-MM-YYYY" format
    const day = date.getDate();
    const month = date.getMonth() + 1; // Add 1 to get the correct month
    const year = date.getFullYear();
    const formattedDate = `${day}-${month}-${year}`;
    return formattedDate;
  };

  const weightLabel = t("Weight_(kg)");

  const data1 = {
    labels: userHistory?.map((item, index) =>
      formatDate(item.updated_at.substring(0, 10))
    ),
    datasets: [
      {
        label: weightLabel,
        data: userHistory?.map((item) => item.weight),
        backgroundColor: "rgba(75, 192, 192, 0.6)",
        borderColor: "rgba(75, 192, 192, 1)",
        fill: false,
      },
    ],
  };

  // const config1 = {
  //   type: "line",
  //   options: {
  //     scales: {
  //       y: {
  //         title: {
  //           display: true,
  //           text: "Value",
  //         },
  //         min: 0,
  //         max: 100,
  //         ticks: {
  //           stepSize: 10,
  //         },
  //       },
  //     },
  //     responsive: true,
  //   },
  //   plugins: {
  //     tooltip: {
  //       position: "average",
  //     },
  //   },
  // };

  LogoutAfterInactivity();

  return (
    <div>
      <SideBar />
      <div className="rightpart">
        {loading && (
          <div class="text-center">
            <div class="spinner-border" role="status">
              <span class="sr-only">{t("Loading")}...</span>
            </div>
          </div>
        )}
        <br></br>
        <br></br>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1, ease: "easeOut" }}
        >
          <div className="title-font" style={{ textAlign: "center" }}>
            {t("Weight_History")}
          </div>
          <br></br>
          <br></br>
          <div className="custom-container">
            <br></br>
            <Line
              data={data1}
              options={{
                scales: {
                  y: {
                    suggestedMin: minWeight - 10,
                    suggestedMax: maxWeight + 10,
                  },
                },
              }}
            />
          </div>
        </motion.div>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <div className="footer-fixed-2">{!isMobile && <Footer />} </div>
      </div>
    </div>
  );
}

export default Weight;
