import SideBar2 from "../components/sideBar2";
import LoadingGif from "../images/hzk6C.gif";
import { motion } from "framer-motion";

function MaterialPage2() {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1, ease: "easeOut" }}
      className="back-color"
    >
      <SideBar2 />
      <div className="Game-container" style={{ width: "87%", float: "right" }}>
        <br></br>
        <br></br>
        <div className="title-font" style={{ textAlign: "center" }}>
          Coming Soon
        </div>
        <div className="desc-font" style={{ textAlign: "center" }}>
          This page is under construction
        </div>
        <div style={{ textAlign: "center" }}>
          <img src={LoadingGif} />
        </div>
      </div>
    </motion.div>
  );
}

export default MaterialPage2;
