import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import jwt_decode from "jwt-decode";
import SideBar from "../components/sideBar";
import Footer from "../components/footer";
import Footer2 from "../components/footer2";
import LogoutAfterInactivity from "../components/logoutAfterInactivity";
import digitamiLogo from "../images/digitami/DigitamiLogo.png";
import windowsIcon from "../images/digitami/windows_icon.png";
import androidIcon from "../images/digitami/android_icon.png";
import Button from "@mui/material/Button";
import "./game.css";
import { useTranslation } from "react-i18next";
import { motion } from "framer-motion";

function GamePage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isMobile, setIsMobile] = useState(false);

  const [authTokens, setAuthTokens] = useState(() =>
    localStorage.getItem("authTokens")
      ? JSON.parse(localStorage.getItem("authTokens"))
      : null
  );
  const [user, setUser] = useState(() =>
    localStorage.getItem("authTokens")
      ? jwt_decode(localStorage.getItem("authTokens"))
      : null
  );
  const [userProfileId, setUserProfileId] = useState(() =>
    localStorage.getItem("adult") ? localStorage.getItem("adult") : null
  );

  useEffect(() => {
    if (user == null || user.is_active == false || userProfileId == null) {
      navigate("/");
    } else {
      const handleResize = () => {
        setIsMobile(window.innerWidth <= 768);
      };

      handleResize(); // Initial check

      window.addEventListener("resize", handleResize);
      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }
  }, []);
  LogoutAfterInactivity();

  // const handleDownload = () => {
  //   const url = "../../public/game/Digitami.apk";
  //   const filename = url.substring(url.lastIndexOf("/") + 1);
  //   const link = document.createElement("a");
  //   link.href = url;
  //   link.setAttribute("download", filename);
  //   document.body.appendChild(link);
  //   link.click();
  //   link.remove();
  // };

  const handleButtonClick = () => {
    window.open("https://game.switchtohealthy.ovh/");
  };

  const handleAction = async (actionName) => {
    try {
      const response = await axios.post("userProfileActions", {
        userid: userProfileId,
        action: actionName,
      });
      // console.log("Action successfully sent:", response.data);
    } catch (error) {
      // console.error("Error in handleAction:", error);
    }
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1, ease: "easeOut" }}
    >
      <SideBar />
      <div className="Game-container">
        <br></br>
        <br></br>
        <div className="title-font" style={{ textAlign: "center" }}>
          {t("digitami_game")}
        </div>
        <div className="desc-font" style={{ textAlign: "center" }}>
          {t("game_desc1")}
          <br /> {t("game_desc2")}
        </div>
        <br></br>
        <br></br>
        <motion.div
          animate={{
            scale: [1, 1.5, 1.5, 1],
          }}
          transition={{ duration: 2 }}
          className="digitamiLogo"
        >
          <img className="digitamiImg" src={digitamiLogo} alt="Digitami Logo" />
        </motion.div>
        <br></br>
        <br></br>
        <div className="desc-font" style={{ textAlign: "center" }}>
          {t("game_desc3")}
        </div>
        <br></br>
        <div style={{ textAlign: "center" }}>
          {/* <div>Play on browser</div> */}
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              handleButtonClick();
              handleAction("Play on browser");
            }}
            // startIcon={<LogoutIcon />}
          >
            {t("play_on_browser")}
          </Button>
        </div>

        <br></br>
        <br></br>
        <div className="centerRow">
          <div>
            <img className="windowsImg" src={windowsIcon} alt="Windows Logo" />
            <div>Windows</div>
            <br></br>
            <a href="/game/Digitami Setup.exe" download>
              <Button
                variant="contained"
                color="info"
                onClick={() => handleAction("Download windows")}
                // startIcon={<LogoutIcon />}
              >
                {t("download")}
              </Button>
            </a>
          </div>
          <div>
            <img className="androidImg" src={androidIcon} alt="Android Logo" />
            <div>Android</div>
            <br></br>

            <a href="/game/Digitami.apk" download>
              <Button
                variant="contained"
                color="success"
                onClick={() => handleAction("Download android")}
                // onClick={handleDownload}
                // startIcon={<LogoutIcon />}
              >
                {t("download")}
              </Button>
            </a>
          </div>
        </div>

        <div className="footer-fixed-2">{!isMobile && <Footer />} </div>
      </div>
    </motion.div>
  );
}

export default GamePage;
