import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import jwt_decode from "jwt-decode";
import Footer from "../../components/footer";
import image from "../../images/graphics/STH - LOGO.png";
import "./adultsForm.css";
import PersonalInfo from "./personalInfo";
import PhysicalCharacteristics from "./physicalCharacteristics";
import Allergies from "./allergies";
import Country from "./country";
import ChildCharacteristics from "./childCharacteristics";
import { useTranslation } from "react-i18next";

function AdultsForm() {
  const [authTokens, setAuthTokens] = useState(() =>
    localStorage.getItem("authTokens")
      ? JSON.parse(localStorage.getItem("authTokens"))
      : null
  );
  const [user, setUser] = useState(() =>
    localStorage.getItem("authTokens")
      ? jwt_decode(localStorage.getItem("authTokens"))
      : null
  );

  const { t } = useTranslation();
  const [maxAdults, setMaxAdults] = useState(false);
  const [maxChild, setMaxChild] = useState(false);
  const [lockOptions, setLockOptions] = useState(false);
  const [lockSchool, setLockSchool] = useState(false);
  const [errorMessage, setErrorMessage] = useState();
  const [page, setPage] = useState(0);
  const [formData, setFormData] = useState({
    profile: "adult",
    username: "",
    img: "",
    height: "",
    weight: "",
    gender: "",
    yearOfBirth: "",
    PAL: "",
    dairy: false,
    intervention_question_1: "",
    intervention_question_2: "",
    country: "",
    countryLanguageCode: "",
    school_name: "",
    group_age: "",
    child_snack: "",
    child_milk: "",
    child_school_lunch: "",
  });

  const navigate = useNavigate();

  const FormTitles = [
    "Personal Info",
    "Physical Characteristics",
    "Allergies",
    "Country",
    "Child Characteristics",
  ];

  useEffect(() => {
    if (user == null || user.is_active == false) {
      navigate("/");
    } else {
      const fetchData = async () => {
        try {
          const response = await axios.get(user.user_id + "/profiles");
          // console.log(response.data);
          if (response.data.adults.length == 1) {
            setLockOptions(true);
            setFormData({
              ...formData,
              country: response.data.adults[0].country,
              intervention_question_1:
                response.data.adults[0].intervention_question_1,
              intervention_question_2:
                response.data.adults[0].intervention_question_2,
            });
          }
          if (response.data.adults.length >= 2) {
            setMaxAdults(true);
            setLockOptions(true);
            setFormData({
              ...formData,
              profile: "child",
              country: response.data.adults[0].country,
            });
            setErrorMessage(t("max_adults_message"));
          }
          if (response.data.children.length >= 8) {
            setMaxChild(true);
            setErrorMessage(t("max_children_message"));
          }
        } catch {}
      };
      fetchData();
    }
  }, []);

  const PageDisplay = () => {
    if (page === 0) {
      return (
        <PersonalInfo
          page={page}
          setPage={setPage}
          formData={formData}
          setFormData={setFormData}
          maxAdults={maxAdults}
          maxChild={maxChild}
          errorMessage={errorMessage}
        />
      );
    } else if (page === 1) {
      return (
        <Country
          page={page}
          setPage={setPage}
          formData={formData}
          lockOptions={lockOptions}
          setFormData={setFormData}
        />
      );
    } else if (page === 2) {
      return (
        <PhysicalCharacteristics
          page={page}
          setPage={setPage}
          formData={formData}
          setFormData={setFormData}
        />
      );
    } else if (page === 3) {
      return (
        <Allergies
          page={page}
          setPage={setPage}
          formData={formData}
          setFormData={setFormData}
        />
      );
    } else if (page === 4) {
      return (
        <ChildCharacteristics
          page={page}
          setPage={setPage}
          formData={formData}
          setFormData={setFormData}
          lockSchool={lockSchool}
        />
      );
    }
  };

  return (
    <div className="back-color">
      <div className="App">
        <motion.div
          initial={{ y: "100%" }}
          animate={{ y: "0%" }}
          transition={{ duration: 1.5, ease: "easeOut" }}
          className="form"
        >
          <div className="progressbar">
            {formData["profile"] === "adult" && (
              <div
                style={{
                  width:
                    page === 0
                      ? "25%"
                      : page == 1
                      ? "50%"
                      : page == 2
                      ? "75%"
                      : "100%",
                }}
              ></div>
            )}
            {formData["profile"] === "child" && (
              <div
                style={{
                  width: page === 0 ? "33%" : page == 4 ? "66%" : "100%",
                }}
              ></div>
            )}
          </div>
          <img src={image} style={{ maxWidth: "100%", height: "auto" }} />
          <div className="page-display">{PageDisplay()}</div>
          {/* <div className="nav-bar">
            <button
              onClick={() => {
                if (page === 0) {
                  navigate("/netflix");
                } else {
                  setPage((currPage) => currPage - 1);
                }
              }}
              className="button"
            >
              {page === 0 ? "Cancel" : "Prev"}
            </button>
            <button
              onClick={() => {
                if (page === FormTitles.length - 1) {
                  alert("FORM SUBMITTED");
                  console.log(formData);
                } else {
                  setPage((currPage) => currPage + 1);
                }
              }}
              className="button"
            >
              {page === FormTitles.length - 1 ? "Submit" : "Next"}
            </button>
          </div> */}
          <br></br>
          <br></br>
        </motion.div>
      </div>
    </div>
  );
}

export default AdultsForm;
