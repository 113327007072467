import React, { useState, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { Alert } from "react-bootstrap";
import axios from "axios";
import jwt_decode from "jwt-decode";
import { motion } from "framer-motion";
import SideBar from "../components/sideBar";
import Footer from "../components/footer.js";
import "bootstrap/dist/css/bootstrap.css";
import "./homePage.css";
import "../components/sideBar.css";
import "../components/calendar.css";
import backgroundImage from "../images/homePage/food-4k-1vrcb0mw76zcg4qf.jpg";
import { useTranslation } from "react-i18next";
import LogoutAfterInactivity from "../components/logoutAfterInactivity";
import { Bounce, ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { jsPDF } from "jspdf";
import "@fortawesome/fontawesome-free/css/all.min.css";

function HomePage() {
  const [NPs, setNPs] = useState([]);
  const [valid_days, setVaid_days] = useState();
  const [selectedDay, setSelectedDay] = useState(1);
  const [selectedDish, setSelectedDish] = useState(null);
  const [weekList, setWeekList] = useState([]);
  const [weekCount, setWeekCount] = useState();
  const [loading, setLoading] = useState();
  const [loadingMessage, setLoadingMessage] = useState();
  const location = useLocation();
  const navigate = useNavigate();
  const [week, setWeek] = useState();
  const [startDate, setStartDate] = useState();
  const [formattedStartDate, setFormattedStartDate] = useState();
  const [formattedEndDate, setFormattedEndDate] = useState();
  const [errorMessage, setErrorMessage] = useState("");
  const [warningMessage, setWarningMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [authTokens, setAuthTokens] = useState(() =>
    localStorage.getItem("authTokens")
      ? JSON.parse(localStorage.getItem("authTokens"))
      : null
  );
  const [user, setUser] = useState(() =>
    localStorage.getItem("authTokens")
      ? jwt_decode(localStorage.getItem("authTokens"))
      : null
  );
  const [userProfileId, setUserProfileId] = useState(() =>
    localStorage.getItem("adult") ? localStorage.getItem("adult") : null
  );
  const { t } = useTranslation();
  const [isMobile, setIsMobile] = useState(false);

  const days = [1, 2, 3, 4, 5, 6, 7];

  const dayMap = {
    1: t("Monday"),
    2: t("Tuesday"),
    3: t("Wednesday"),
    4: t("Thursday"),
    5: t("Friday"),
    6: t("Saturday"),
    7: t("Sunday"),
  };

  const today = new Date();
  const currentDayOfWeek = today.getDay(); // Get the current day of the week (0-6, where 0 is Sunday)

  // Calculate the date of the Monday and Sunday
  const monday = new Date(today);
  const sunday = new Date(today);
  const nextMonday = new Date(today);
  const nextSunday = new Date(today);
  monday.setDate(today.getDate() - currentDayOfWeek + 1);
  sunday.setDate(today.getDate() - currentDayOfWeek + 7);
  nextMonday.setDate(today.getDate() - currentDayOfWeek + 8);
  nextSunday.setDate(today.getDate() - currentDayOfWeek + 14);

  // Format the Monday and Sunday date
  const m_year = monday.getFullYear();
  const m_month = String(monday.getMonth() + 1).padStart(2, "0");
  const m_day = String(monday.getDate()).padStart(2, "0");

  const s_year = sunday.getFullYear();
  const s_month = String(sunday.getMonth() + 1).padStart(2, "0");
  const s_day = String(sunday.getDate()).padStart(2, "0");

  const nm_year = nextMonday.getFullYear();
  const nm_month = String(nextMonday.getMonth() + 1).padStart(2, "0");
  const nm_day = String(nextMonday.getDate()).padStart(2, "0");

  const ns_year = nextSunday.getFullYear();
  const ns_month = String(nextSunday.getMonth() + 1).padStart(2, "0");
  const ns_day = String(nextSunday.getDate()).padStart(2, "0");

  const formattedMonday = `${m_year}-${m_month}-${m_day}`;
  const formattedSunday = `${s_year}-${s_month}-${s_day}`;
  const formattedNextMonday = `${nm_year}-${nm_month}-${nm_day}`;
  const formattedNextSunday = `${ns_year}-${ns_month}-${ns_day}`;

  // const formattedMonday = "2023-08-18";

  const waterMessages = [
    t("waterMessage1"),
    t("waterMessage2"),
    t("waterMessage3"),
    t("waterMessage4"),
    t("waterMessage5"),
  ];

  const getRandomMessage = () => {
    const randomIndex = Math.floor(Math.random() * waterMessages.length);
    return waterMessages[randomIndex];
  };

  //console.log(formattedDate); // Output: "2023-06-12" (for example)
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    handleResize(); // Initial check

    toast.info(getRandomMessage(), {
      position: "bottom-right",
      autoClose: 7000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      transition: Bounce,
    });

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      if (user == null || user.is_active == false || userProfileId == null) {
        navigate("/");
      } else {
        // if (location.state && location.state.successMessage) {
        //   setSuccessMessage(location.state.successMessage);
        //   setTimeout(() => {
        //     setSuccessMessage("");
        //   }, 10000); // Hide the success message after 10 seconds
        // }
        setSelectedDay(currentDayOfWeek === 0 ? 7 : currentDayOfWeek);

        setLoading(true);
        setLoadingMessage(t("loading_message"));
        try {
          const response = await axios.get(
            `${userProfileId}/${formattedMonday}/getCurrentWeekNPs`
          );
          if (response.data.length === 0) {
            try {
              const createResponse = await axios.get(
                `${userProfileId}/${formattedMonday}/${formattedSunday}/createNPs`
              );
              if (createResponse.data[0].valid_days < 7) {
                setWarningMessage(
                  `${t("nps_warning_err1")} ${response.data[0].valid_days} ${t(
                    "nps_warning_err2"
                  )}`
                );
                setTimeout(() => {
                  setWarningMessage("");
                }, 20000);
              } else {
                setWarningMessage("");
              }
              setNPs(createResponse.data);
              setWeek(createResponse.data[0].week);
              var mondayParts = createResponse.data[0].start_date
                .substring(0, 10)
                .split("-");
              var monday_year = mondayParts[0];
              var monday_month = mondayParts[1];
              var monday_day = mondayParts[2];
              setFormattedStartDate(
                monday_day + "/" + monday_month + "/" + monday_year
              );
              var sundayParts = createResponse.data[0].end_date
                .substring(0, 10)
                .split("-");
              var sunday_year = sundayParts[0];
              var sunday_month = sundayParts[1];
              var sunday_day = sundayParts[2];
              setFormattedEndDate(
                sunday_day + "/" + sunday_month + "/" + sunday_year
              );
              // try {
              //   const nextMondayresponse = await axios.get(
              //     `${userProfileId}/${formattedNextMonday}/getCurrentWeekNPs`
              //   );
              //   if (nextMondayresponse.data.length === 0) {
              //     try {
              //       const createNextMondayResponse = await axios.get(
              //         `${userProfileId}/${formattedNextMonday}/${formattedNextSunday}/createNPs`
              //       );
              //     } catch (error) {
              //       if (
              //         error.response.data.error ===
              //         "Unfortunately, it is impossible to recommend a suitable nutritional plan because no meals found for the selected country"
              //       ) {
              //         setErrorMessage(t("nps_error_1"));
              //       }
              //       if (
              //         error.response.data.error ===
              //         "Unfortunately, it is impossible to recommend a suitable nutritional plan because no meals found for the selected allergies"
              //       ) {
              //         setErrorMessage(t("nps_error_2"));
              //       }
              //       if (
              //         error.response.data.error ===
              //         "Unfortunately, it is impossible to recommend a suitable nutritional plan because no meals found for the current season"
              //       ) {
              //         setErrorMessage(t("nps_error_3"));
              //       }
              //       if (
              //         error.response.data.error ===
              //         "Unfortunately, it is impossible to recommend a suitable nutritional plan due to a limited number of meals in our database for your specific profile."
              //       ) {
              //         setErrorMessage(t("nps_error_4"));
              //       }
              //       window.scrollTo({
              //         top: 0,
              //         behavior: "smooth", // Use "smooth" for smooth scrolling behavior
              //       });
              //     }
              //   } else {
              //   }
              // } catch (error) {
              //   // console.error(error);
              // }
            } catch (error) {
              if (
                error.response.data.error ===
                "Unfortunately, it is impossible to recommend a suitable nutritional plan because no meals found for the selected country"
              ) {
                setErrorMessage(t("nps_error_1"));
              }
              if (
                error.response.data.error ===
                "Unfortunately, it is impossible to recommend a suitable nutritional plan because no meals found for the selected allergies"
              ) {
                setErrorMessage(t("nps_error_2"));
              }
              if (
                error.response.data.error ===
                "Unfortunately, it is impossible to recommend a suitable nutritional plan because no meals found for the current season"
              ) {
                setErrorMessage(t("nps_error_3"));
              }
              if (
                error.response.data.error ===
                "Unfortunately, it is impossible to recommend a suitable nutritional plan due to a limited number of meals in our database for your specific profile."
              ) {
                setErrorMessage(t("nps_error_4"));
              }
              window.scrollTo({
                top: 0,
                behavior: "smooth", // Use "smooth" for smooth scrolling behavior
              });
            }
          } else {
            if (response.data[0].valid_days < 7) {
              setWarningMessage(
                `${t("nps_warning_err1")} ${response.data[0].valid_days} ${t(
                  "nps_warning_err2"
                )}`
              );
              setTimeout(() => {
                setWarningMessage("");
              }, 20000);
            } else {
              setWarningMessage("");
            }
            setNPs(response.data);
            setWeek(response.data[0].week);
            var mondayParts = response.data[0].start_date
              .substring(0, 10)
              .split("-");
            var monday_year = mondayParts[0];
            var monday_month = mondayParts[1];
            var monday_day = mondayParts[2];
            setFormattedStartDate(
              monday_day + "/" + monday_month + "/" + monday_year
            );
            var sundayParts = response.data[0].end_date
              .substring(0, 10)
              .split("-");
            var sunday_year = sundayParts[0];
            var sunday_month = sundayParts[1];
            var sunday_day = sundayParts[2];
            setFormattedEndDate(
              sunday_day + "/" + sunday_month + "/" + sunday_year
            );
            // try {
            //   const nextMondayresponse = await axios.get(
            //     `${userProfileId}/${formattedNextMonday}/getCurrentWeekNPs`
            //   );
            //   if (nextMondayresponse.data.length === 0) {
            //     try {
            //       const createNextMondayResponse = await axios.get(
            //         `${userProfileId}/${formattedNextMonday}/${formattedNextSunday}/createNPs`
            //       );
            //     } catch (error) {
            //       if (
            //         error.response.data.error ===
            //         "Unfortunately, it is impossible to recommend a suitable nutritional plan because no meals found for the selected country"
            //       ) {
            //         setErrorMessage(t("nps_error_1"));
            //       }
            //       if (
            //         error.response.data.error ===
            //         "Unfortunately, it is impossible to recommend a suitable nutritional plan because no meals found for the selected allergies"
            //       ) {
            //         setErrorMessage(t("nps_error_2"));
            //       }
            //       if (
            //         error.response.data.error ===
            //         "Unfortunately, it is impossible to recommend a suitable nutritional plan because no meals found for the current season"
            //       ) {
            //         setErrorMessage(t("nps_error_3"));
            //       }
            //       if (
            //         error.response.data.error ===
            //         "Unfortunately, it is impossible to recommend a suitable nutritional plan due to a limited number of meals in our database for your specific profile."
            //       ) {
            //         setErrorMessage(t("nps_error_4"));
            //       }
            //       window.scrollTo({
            //         top: 0,
            //         behavior: "smooth", // Use "smooth" for smooth scrolling behavior
            //       });
            //     }
            //   } else {
            //   }
            // } catch (error) {}
          }
        } catch (error) {}

        try {
          const getWeeksresponse = await axios.get(`${userProfileId}/getWeeks`);
          // console.log(getWeeksresponse.data.week_list);
          // console.log(getWeeksresponse.data.week_count);
          setWeekList(getWeeksresponse.data.week_list);
          setWeekCount(getWeeksresponse.data.week_count);
        } catch (error) {}
        setLoading(false);
        setLoadingMessage("");
      }
    };

    fetchData();
  }, []);

  const getNextWeekNPs = async (userId, week) => {
    try {
      setLoading(true);
      const response = await axios.get(`${userId}/${week}/getNextWeekNPs`);
      // console.log(response.data[0].valid_days);
      if (response.data[0].valid_days < 7) {
        setWarningMessage(
          `${t("nps_warning_err1")} ${response.data[0].valid_days} ${t(
            "nps_warning_err2"
          )}`
        );
        setTimeout(() => {
          setWarningMessage("");
        }, 20000);
      } else {
        setWarningMessage("");
      }
      setNPs(response.data);
      setVaid_days(response.data[0].valid_days);
      setWeek(response.data[0].week);
      var mondayParts = response.data[0].start_date.substring(0, 10).split("-");
      var monday_year = mondayParts[0];
      var monday_month = mondayParts[1];
      var monday_day = mondayParts[2];
      setFormattedStartDate(
        monday_day + "/" + monday_month + "/" + monday_year
      );
      var sundayParts = response.data[0].end_date.substring(0, 10).split("-");
      var sunday_year = sundayParts[0];
      var sunday_month = sundayParts[1];
      var sunday_day = sundayParts[2];
      setFormattedEndDate(sunday_day + "/" + sunday_month + "/" + sunday_year);

      if (response.data.length === 0) {
      }
      setLoading(false);
    } catch (error) {
      // console.error(error);
    }
  };

  const handleAction = async (actionName) => {
    try {
      const response = await axios.post("userProfileActions", {
        userid: userProfileId,
        action: actionName,
      });
      // console.log("Action successfully sent:", response.data);
    } catch (error) {
      // console.error("Error in handleAction:", error);
    }
  };

  const handleNextWeek = () => {
    // console.log(week);
    setSelectedDish(null);
    getNextWeekNPs(userProfileId, week);
  };

  const handlePreviousWeek = () => {
    // console.log(week);
    setSelectedDish(null);
    getPreviousWeekNPs(userProfileId, week);
  };

  const getPreviousWeekNPs = async (userId, week) => {
    try {
      setLoading(true);
      const response = await axios.get(`${userId}/${week}/getPreviousWeekNPs`);
      if (response.data[0].valid_days < 7) {
        setWarningMessage(
          `${t("nps_warning_err1")} ${response.data[0].valid_days} ${t(
            "nps_warning_err2"
          )}`
        );
        setTimeout(() => {
          setWarningMessage("");
        }, 20000);
      } else {
        setWarningMessage("");
      }
      setNPs(response.data);
      setVaid_days(response.data[0].valid_days);
      setWeek(response.data[0].week);
      var mondayParts = response.data[0].start_date.substring(0, 10).split("-");
      var monday_year = mondayParts[0];
      var monday_month = mondayParts[1];
      var monday_day = mondayParts[2];
      setFormattedStartDate(
        monday_day + "/" + monday_month + "/" + monday_year
      );
      var sundayParts = response.data[0].end_date.substring(0, 10).split("-");
      var sunday_year = sundayParts[0];
      var sunday_month = sundayParts[1];
      var sunday_day = sundayParts[2];
      setFormattedEndDate(sunday_day + "/" + sunday_month + "/" + sunday_year);

      if (response.data.length === 0) {
      }
      setLoading(false);
    } catch (error) {
      // console.error(error);
    }
  };

  const handleDishClick = (dish) => {
    setSelectedDish(dish);

    if (isMobile) {
      // Scroll to the meal-grid-right section
      window.scrollTo({
        top: document.querySelector(".meal-grid-right").offsetTop,
        behavior: "smooth",
      });
    }
  };

  const handleDownloadPdf = () => {
    const doc = new jsPDF();
    let line = 10;
    const pageHeight = doc.internal.pageSize.height; // Get the height of the page

    // Add a custom font (assuming you have it available in your project)
    doc.addFileToVFS("Blinker-Regular.ttf", "YOUR_BASE64_ENCODED_FONT_STRING");
    doc.addFont("Blinker-Regular.ttf", "Blinker", "normal");
    doc.setFont("Blinker", "normal");

    // Function to add an underline
    const addUnderline = (x, y, textWidth) => {
      const underlineThickness = 0.5; // Adjust thickness as needed
      doc.setLineWidth(underlineThickness);
      doc.line(x, y + 1, x + textWidth, y + 1); // Draw line slightly below the text
      doc.setLineWidth(0.2); // Reset line width
    };

    // Function to add a dish if it exists
    const addDishToPdf = (meal, dishIndex) => {
      const dish = meal[`dish_${dishIndex}`];
      if (dish) {
        // Check if we need to add a new page
        if (line + 20 > pageHeight - 10) {
          doc.addPage();
          line = 10;
        }

        // Set font size for dish names
        doc.setFontSize(12);
        doc.text(String(dish.dish_language_info[0].name), 30, line);
        line += 10;

        // Adding ingredients
        const ingredients = dish.dish_language_info[0].ingredients_adult
          .split(";")
          .filter((item) => item.trim() !== "") // Filter out empty strings
          .map((item) => {
            const [ingredient, quantity, measure] = item
              .split(",")
              .map((part) => part.trim());
            return `${ingredient}, ${quantity} ${measure}`;
          });

        // Set font size for ingredients
        doc.setFontSize(10);
        // Check if we need to add a new page for ingredients
        ingredients.forEach((ingredient) => {
          if (line + 10 > pageHeight - 10) {
            doc.addPage();
            line = 10;
          }
          doc.text(ingredient, 40, line);
          line += 10;
        });

        // line += 10; // Extra space after listing ingredients
      }
    };

    // Function to handle each meal section
    const addMealToPdf = (mealName, meal) => {
      // Check if we need to add a new page before adding a meal title
      if (line + 10 > pageHeight - 10) {
        doc.addPage();
        line = 10;
      }

      // Set font size and style for meal titles
      doc.setFontSize(14);
      doc.setFont("Blinker", "italic"); // Set font to italic
      const textWidth = doc.getTextWidth(mealName); // Measure the text width
      doc.text(mealName, 20, line); // Meal title (e.g. "Breakfast", "Lunch")
      addUnderline(20, line, textWidth); // Add underline
      doc.setFont("Blinker", "normal"); // Reset font to normal
      line += 10;

      for (let i = 1; i <= 10; i++) {
        addDishToPdf(meal, i); // Check for up to 10 dishes
      }
    };

    // Days of the week
    const daysOfWeek = [
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
      "Sunday",
    ];

    // Iterate through each day of the week
    daysOfWeek.forEach((dayName, dayIndex) => {
      // Check if we need to add a new page before adding the day's header
      if (line + 10 > pageHeight - 10) {
        doc.addPage();
        line = 10;
      }

      // Set font size and style for day names
      doc.setFontSize(16);
      doc.setFont("Blinker", "bold");
      doc.text(dayName, 10, line);
      doc.setFont("Blinker", "normal"); // Reset font to normal
      line += 10;

      // Define meals and their corresponding indices
      const meals = [
        { name: "Breakfast", index: 0 },
        { name: "Morning Snack", index: 1 },
        { name: "Lunch", index: 2 },
        { name: "Afternoon Snack", index: 3 },
        { name: "Dinner", index: 4 },
      ];

      // Check if the current dayIndex is within the range of NPs
      if (dayIndex < NPs.length) {
        // Loop through meals and add them to the PDF for the current day
        meals.forEach((meal) => {
          addMealToPdf(meal.name, NPs[dayIndex].meals[meal.index]);
        });
      }

      // Add a page break if it's not the last day
      if (dayIndex < daysOfWeek.length - 1) {
        doc.addPage();
        line = 10;
      }
    });

    doc.save("weekly_plan.pdf");
  };

  // const handleDishClick = (dish) => {
  //   setSelectedDish(dish);
  //   window.scrollTo({
  //     top: document.querySelector(".meal-grid-right").offsetTop,
  //     behavior: "smooth",
  //   });
  // };

  LogoutAfterInactivity();

  return (
    <div className="parent-container">
      <div className="page-container">
        <SideBar />

        <div className="rightpart">
          {loading && (
            <div className="text-center">
              <div className="spinner-border" role="status"></div>
              <div className="load-message-font">{loadingMessage}</div>
            </div>
          )}
          <div
            style={{
              backgroundImage: `url(${backgroundImage})`,
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {successMessage && (
                <Alert variant="success">{successMessage}</Alert>
              )}
              {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
              {warningMessage && (
                <Alert variant="warning">{warningMessage}</Alert>
              )}
            </div>
            <div className="calendar">
              <div className="container">
                <div className="row justify-content-md-center">
                  <div className="controls">
                    <div className="col-md-auto ">
                      <motion.button
                        className="previousweek"
                        onClick={() => {
                          handlePreviousWeek();
                          handleAction("Previous Week");
                        }}
                        disabled={week === weekList[0]}
                        whileHover={week !== weekList[0] ? { scale: 1.2 } : {}}
                        whileTap={week !== weekList[0] ? { scale: 0.9 } : {}}
                        transition={{
                          type: "spring",
                          stiffness: 400,
                          damping: 17,
                        }}
                      ></motion.button>
                    </div>
                    <div className="col-md-auto">
                      <div className="formatted-day">
                        <h1 className="formatted-days-font">
                          {formattedStartDate} - {formattedEndDate}
                        </h1>
                      </div>
                    </div>
                    <div className="col-md-auto">
                      <motion.button
                        className="nextweek"
                        onClick={() => {
                          handleNextWeek();
                          handleAction("Next Week");
                        }}
                        disabled={week === weekList[weekList.length - 1]}
                        whileHover={
                          week !== weekList[weekList.length - 1]
                            ? { scale: 1.2 }
                            : {}
                        }
                        whileTap={
                          week !== weekList[weekList.length - 1]
                            ? { scale: 0.9 }
                            : {}
                        }
                        transition={{
                          type: "spring",
                          stiffness: 400,
                          damping: 17,
                        }}
                      ></motion.button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="days">
                {days.map((day) => (
                  <motion.div
                    whileHover={{ scale: 1.1 }}
                    whileTap={{ scale: 0.9 }}
                    transition={{
                      type: "spring",
                      stiffness: 400,
                      damping: 17,
                    }}
                    className={`day ${selectedDay === day ? "selected" : ""}`}
                    key={day}
                    onClick={() => {
                      if (selectedDay !== day) {
                        setSelectedDay(day);
                        setSelectedDish(null);
                        handleAction(`Week: ${week}, Day: ${day}`);
                      }
                    }}
                  >
                    <h1
                      className={`custom-font ${
                        window.innerWidth <= 768 ? "mobile-day" : ""
                      }`}
                    >
                      {window.innerWidth <= 768 ? dayMap[day][0] : dayMap[day]}
                    </h1>
                  </motion.div>
                ))}
                {/* {days.map((day) => (
                  <div
                    className={`day ${selectedDay === day ? "selected" : ""}`}
                    key={day}
                    onClick={() => setSelectedDay(day)}
                  >
                    <h1 className="custom-font">{dayMap[day][0]}</h1>{" "}
                  </div>
                ))} */}

                {/* {days.map((day) => (
                  <div
                    className={`day ${selectedDay === day ? "selected" : ""}`}
                    key={day}
                    onClick={() => setSelectedDay(day)}
                  >
                    <h1 className="custom-font">{dayMap[day]}</h1>
                  </div>
                ))} */}
              </div>
            </div>
            <div
              style={{
                marginTop: "10px",
                marginRight: "2.2%",
                textAlign: "right",
              }}
            >
              <button onClick={handleDownloadPdf} className="btn btn-primary">
                <i
                  className="fas fa-file-pdf"
                  style={{ marginRight: "10px" }}
                ></i>
                {t("download_weekly_plan")}
              </button>
            </div>

            <div className="meal-grid">
              <motion.div
                whileHover={{ scale: 1.05 }}
                // whileTap={{ scale: 0.9 }}
                transition={{ type: "spring", stiffness: 400, damping: 17 }}
                className="meal-grid-left"
              >
                {/* <div className="custom-font2-title">Daily Nutritional Plan</div> */}
                {NPs.map((np) => {
                  //console.log("np.day:", np.day);
                  //console.log("day:", selectedDay);

                  if (np.day === selectedDay) {
                    //console.log("Condition is satisfied for np:", np);
                    // console.log(np);
                    // console.log(np.valid_day);
                    if (String(np.valid_day) === "false") {
                      toast.error(
                        "Unfortunately, this plan does not follow the Mediterranean diet principles.",
                        {
                          position: "top-center",
                          autoClose: 4000,
                          hideProgressBar: false,
                          closeOnClick: true,
                          pauseOnHover: true,
                          draggable: true,
                          progress: undefined,
                          theme: "light",
                          transition: Bounce,
                        }
                      );
                    }

                    return np.meals.map((meal) => {
                      // console.log(meal);
                      //console.log("meal.id", meal.id);
                      //console.log("meal.type", meal.type);

                      return (
                        <div key={`${np.day}-${np.id}-${meal.id}`}>
                          {meal.type === "Dinner" &&
                          np.valid_school_dinner === true ? (
                            <span className="meal-type-font-2">
                              {t("family_dinner")}
                            </span>
                          ) : meal.type === "Dinner" &&
                            np.valid_adults_dinner === true ? (
                            <span className="meal-type-font-2">
                              {t("parents_dinner")}
                            </span>
                          ) : meal.type === "Lunch" &&
                            np.valid_adults_lunch === true ? (
                            <span className="meal-type-font-2">
                              {t("parents_lunch")}
                            </span>
                          ) : meal.type === "Lunch" &&
                            np.valid_school_lunch === true ? (
                            <span className="meal-type-font-2">
                              {t("family_lunch")}
                            </span>
                          ) : (
                            <p className="meal-type-font">
                              {t(`${meal.type}`)}
                            </p>
                          )}
                          {/* <hr className="line"></hr> */}
                          {meal.dish_1 && (
                            <p
                              onClick={() => {
                                if (selectedDish !== meal.dish_1) {
                                  handleDishClick(meal.dish_1);
                                  handleAction(
                                    `Week: ${np.week}, Day: ${np.day}, Meal type: ${meal.type}, Dish: ${meal.dish_1.id}`
                                  );
                                }
                              }}
                              className="dish-font-grid"
                            >
                              <div
                                className={`sth ${
                                  selectedDish === meal.dish_1 ? "selected" : ""
                                }`}
                              >
                                {meal.dish_1.dish_language_info[0].name}
                                {/* <span style={{ float: "right" }}>
                                  {t("kcal")}: {meal.dish_1.kcal},{" "}
                                  {t("protein")}: {meal.dish_1.protein} g,{" "}
                                  {t("fat")}: {meal.dish_1.fat} g, {t("carbs")}:{" "}
                                  {meal.dish_1.carbohydrates} g
                                </span> */}
                              </div>
                            </p>
                          )}
                          {meal.dish_2 && (
                            <p
                              onClick={() => {
                                if (selectedDish !== meal.dish_2) {
                                  handleDishClick(meal.dish_2);
                                  handleAction(
                                    `Week: ${np.week}, Day: ${np.day}, Meal type: ${meal.type}, Dish: ${meal.dish_2.id}`
                                  );
                                }
                              }}
                              className="dish-font-grid"
                            >
                              <div
                                className={`sth ${
                                  selectedDish === meal.dish_2 ? "selected" : ""
                                }`}
                              >
                                {meal.dish_2.dish_language_info[0].name}
                                {/* <span style={{ float: "right" }}>
                                  {t("kcal")}: {meal.dish_2.kcal},{" "}
                                  {t("protein")}: {meal.dish_2.protein} g,{" "}
                                  {t("fat")}: {meal.dish_2.fat} g, {t("carbs")}:{" "}
                                  {meal.dish_2.carbohydrates} g
                                </span> */}
                              </div>
                            </p>
                          )}
                          {meal.dish_3 && (
                            <p
                              onClick={() => {
                                if (selectedDish !== meal.dish_3) {
                                  handleDishClick(meal.dish_3);
                                  handleAction(
                                    `Week: ${np.week}, Day: ${np.day}, Meal type: ${meal.type}, Dish: ${meal.dish_3.id}`
                                  );
                                }
                              }}
                              className="dish-font-grid"
                            >
                              <div
                                className={`sth ${
                                  selectedDish === meal.dish_3 ? "selected" : ""
                                }`}
                              >
                                {meal.dish_3.dish_language_info[0].name}
                                {/* <span style={{ float: "right" }}>
                                  {t("kcal")}: {meal.dish_3.kcal},{" "}
                                  {t("protein")}: {meal.dish_3.protein} g,{" "}
                                  {t("fat")}: {meal.dish_3.fat} g, {t("carbs")}:{" "}
                                  {meal.dish_3.carbohydrates} g
                                </span> */}
                              </div>
                            </p>
                          )}
                          {meal.dish_4 && (
                            <p
                              onClick={() => {
                                if (selectedDish !== meal.dish_4) {
                                  handleDishClick(meal.dish_4);
                                  handleAction(
                                    `Week: ${np.week}, Day: ${np.day}, Meal type: ${meal.type}, Dish: ${meal.dish_4.id}`
                                  );
                                }
                              }}
                              className="dish-font-grid"
                            >
                              <div
                                className={`sth ${
                                  selectedDish === meal.dish_4 ? "selected" : ""
                                }`}
                              >
                                {meal.dish_4.dish_language_info[0].name}
                                {/* <span style={{ float: "right" }}>
                                  {t("kcal")}: {meal.dish_4.kcal},{" "}
                                  {t("protein")}: {meal.dish_4.protein} g,{" "}
                                  {t("fat")}: {meal.dish_4.fat} g, {t("carbs")}:{" "}
                                  {meal.dish_4.carbohydrates} g
                                </span> */}
                              </div>
                            </p>
                          )}
                          {meal.dish_5 && (
                            <p
                              onClick={() => {
                                if (selectedDish !== meal.dish_5) {
                                  handleDishClick(meal.dish_5);
                                  handleAction(
                                    `Week: ${np.week}, Day: ${np.day}, Meal type: ${meal.type}, Dish: ${meal.dish_5.id}`
                                  );
                                }
                              }}
                              className="dish-font-grid"
                            >
                              <div
                                className={`sth ${
                                  selectedDish === meal.dish_5 ? "selected" : ""
                                }`}
                              >
                                {meal.dish_5.dish_language_info[0].name}
                                {/* <span style={{ float: "right" }}>
                                  {t("kcal")}: {meal.dish_5.kcal},{" "}
                                  {t("protein")}: {meal.dish_5.protein} g,{" "}
                                  {t("fat")}: {meal.dish_5.fat} g, {t("carbs")}:{" "}
                                  {meal.dish_5.carbohydrates} g
                                </span> */}
                              </div>
                            </p>
                          )}
                          {meal.dish_6 && (
                            <p
                              onClick={() => {
                                if (selectedDish !== meal.dish_6) {
                                  handleDishClick(meal.dish_6);
                                  handleAction(
                                    `Week: ${np.week}, Day: ${np.day}, Meal type: ${meal.type}, Dish: ${meal.dish_6.id}`
                                  );
                                }
                              }}
                              className="dish-font-grid"
                            >
                              <div
                                className={`sth ${
                                  selectedDish === meal.dish_6 ? "selected" : ""
                                }`}
                              >
                                {meal.dish_6.dish_language_info[0].name}
                                {/* <span style={{ float: "right" }}>
                                  {t("kcal")}: {meal.dish_5.kcal},{" "}
                                  {t("protein")}: {meal.dish_5.protein} g,{" "}
                                  {t("fat")}: {meal.dish_5.fat} g, {t("carbs")}:{" "}
                                  {meal.dish_5.carbohydrates} g
                                </span> */}
                              </div>
                            </p>
                          )}
                          {meal.dish_7 && (
                            <p
                              onClick={() => {
                                if (selectedDish !== meal.dish_7) {
                                  handleDishClick(meal.dish_7);
                                  handleAction(
                                    `Week: ${np.week}, Day: ${np.day}, Meal type: ${meal.type}, Dish: ${meal.dish_7.id}`
                                  );
                                }
                              }}
                              className="dish-font-grid"
                            >
                              <div
                                className={`sth ${
                                  selectedDish === meal.dish_7 ? "selected" : ""
                                }`}
                              >
                                {meal.dish_7.dish_language_info[0].name}
                                {/* <span style={{ float: "right" }}>
                                  {t("kcal")}: {meal.dish_5.kcal},{" "}
                                  {t("protein")}: {meal.dish_5.protein} g,{" "}
                                  {t("fat")}: {meal.dish_5.fat} g, {t("carbs")}:{" "}
                                  {meal.dish_5.carbohydrates} g
                                </span> */}
                              </div>
                            </p>
                          )}
                          {meal.dish_8 && (
                            <p
                              onClick={() => {
                                if (selectedDish !== meal.dish_8) {
                                  handleDishClick(meal.dish_8);
                                  handleAction(
                                    `Week: ${np.week}, Day: ${np.day}, Meal type: ${meal.type}, Dish: ${meal.dish_8.id}`
                                  );
                                }
                              }}
                              className="dish-font-grid"
                            >
                              <div
                                className={`sth ${
                                  selectedDish === meal.dish_8 ? "selected" : ""
                                }`}
                              >
                                {meal.dish_8.dish_language_info[0].name}
                                {/* <span style={{ float: "right" }}>
                                  {t("kcal")}: {meal.dish_5.kcal},{" "}
                                  {t("protein")}: {meal.dish_5.protein} g,{" "}
                                  {t("fat")}: {meal.dish_5.fat} g, {t("carbs")}:{" "}
                                  {meal.dish_5.carbohydrates} g
                                </span> */}
                              </div>
                            </p>
                          )}
                          {meal.dish_9 && (
                            <p
                              onClick={() => {
                                if (selectedDish !== meal.dish_9) {
                                  handleDishClick(meal.dish_9);
                                  handleAction(
                                    `Week: ${np.week}, Day: ${np.day}, Meal type: ${meal.type}, Dish: ${meal.dish_9.id}`
                                  );
                                }
                              }}
                              className="dish-font-grid"
                            >
                              <div
                                className={`sth ${
                                  selectedDish === meal.dish_9 ? "selected" : ""
                                }`}
                              >
                                {meal.dish_9.dish_language_info[0].name}
                                {/* <span style={{ float: "right" }}>
                                  {t("kcal")}: {meal.dish_5.kcal},{" "}
                                  {t("protein")}: {meal.dish_5.protein} g,{" "}
                                  {t("fat")}: {meal.dish_5.fat} g, {t("carbs")}:{" "}
                                  {meal.dish_5.carbohydrates} g
                                </span> */}
                              </div>
                            </p>
                          )}
                          {meal.dish_10 && (
                            <p
                              onClick={() => {
                                if (selectedDish !== meal.dish_10) {
                                  handleDishClick(meal.dish_10);
                                  handleAction(
                                    `Week: ${np.week}, Day: ${np.day}, Meal type: ${meal.type}, Dish: ${meal.dish_10.id}`
                                  );
                                }
                              }}
                              className="dish-font-grid"
                            >
                              <div
                                className={`sth ${
                                  selectedDish === meal.dish_10
                                    ? "selected"
                                    : ""
                                }`}
                              >
                                {meal.dish_10.dish_language_info[0].name}
                                {/* <span style={{ float: "right" }}>
                                  {t("kcal")}: {meal.dish_5.kcal},{" "}
                                  {t("protein")}: {meal.dish_5.protein} g,{" "}
                                  {t("fat")}: {meal.dish_5.fat} g, {t("carbs")}:{" "}
                                  {meal.dish_5.carbohydrates} g
                                </span> */}
                              </div>
                            </p>
                          )}
                        </div>
                      );
                    });
                  } // else {
                  //   console.log("Condition is not satisfied for np:", np);
                  // }
                })}
              </motion.div>
              <motion.div
                whileHover={{ scale: 1.05 }}
                // whileTap={{ scale: 0.9 }}
                transition={{ type: "spring", stiffness: 400, damping: 17 }}
                className="meal-grid-right"
              >
                {/* <div className="custom-font2-title">Additional Information</div> */}
                {selectedDish && (
                  <motion.div
                    key={selectedDish.id}
                    initial={{ x: 10, opacity: 0 }}
                    animate={{ x: 0, opacity: 1 }}
                    exit={{ x: -10, opacity: 0 }}
                    transition={{ duration: 0.5 }}
                  >
                    <p className="dish-name-font">
                      {selectedDish.dish_language_info[0].name}
                    </p>
                    <br></br>
                    <p className="custom-font2">{t("Nutritional_info")}:</p>
                    <div className="custom-font">
                      {/* {t("kcal")}: {selectedDish.kcal} , {t("protein")}:{" "}
                      {selectedDish.protein} g, {t("fat")}: {selectedDish.fat}{" "}
                      g, {t("carbs")}: {selectedDish.carbohydrates} g */}
                      <ul>
                        <li>
                          {t("energy")}: {selectedDish.kcal} kcal
                        </li>
                        <li>
                          {t("fat")}: {selectedDish.fat} g
                        </li>
                        <li>
                          {t("carbs")}: {selectedDish.carbohydrates} g
                        </li>
                        <li>
                          {t("protein")}: {selectedDish.protein} g
                        </li>
                      </ul>
                    </div>
                    <br></br>
                    <div>
                      {selectedDish.dish_language_info[0].ingredients_adult && (
                        <div>
                          <p className="custom-font2">
                            {t("Ingredients_for_an_adult")}:
                          </p>
                          <ol>
                            {selectedDish.dish_language_info[0].ingredients_adult
                              .split(";")
                              .filter((item) => item.trim() !== "") // Filter out empty strings
                              .map((item, index) => {
                                const [ingredient, quantity, measure] = item
                                  .split(",")
                                  .map((part) => part.trim());
                                return (
                                  <li className="custom-font" key={index}>
                                    {ingredient}, {quantity} {measure}
                                    {/* Renders each item with space */}
                                  </li>
                                );
                              })}
                          </ol>
                          <p className="custom-font4">{t("gross_weight")}</p>
                        </div>
                      )}
                    </div>
                    <br></br>
                    <div>
                      {selectedDish.dish_language_info[0].recipe !== "nan" && (
                        <div>
                          <p className="custom-font2">{t("Recipe")}:</p>
                          <p className="custom-font">
                            {selectedDish.dish_language_info[0].recipe}
                          </p>
                        </div>
                      )}
                    </div>
                    <br></br>
                    <div>
                      {selectedDish.dish_language_info[0].tip !== "nan" && (
                        <div>
                          <p className="custom-font2">{t("Tips")}:</p>
                          <p className="custom-font">
                            {selectedDish.dish_language_info[0].tip}
                          </p>
                        </div>
                      )}
                    </div>
                  </motion.div>
                )}
              </motion.div>
            </div>

            <div className="footer-sticky">{!isMobile && <Footer />} </div>
          </div>

          {/* Render Footer only if isMobile is false */}
        </div>
        <ToastContainer />
      </div>
    </div>
  );
}

export default HomePage;
