import React, { useState, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import jwt_decode from "jwt-decode";
import { motion } from "framer-motion";
import SideBar from "../../components/sideBar";
import Footer from "../../components/footer.js";
import "../../components/sideBar.css";
import "../../components/footer.css";
import "./statistics.css";
import { useTranslation } from "react-i18next";
import "chartjs-plugin-annotation";
import LogoutAfterInactivity from "../../components/logoutAfterInactivity";
import { Line, Bar, PolarArea, Pie } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  RadialLinearScale,
  ArcElement,
  Tooltip,
  Legend,
  plugins,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  RadialLinearScale,
  ArcElement,
  Tooltip,
  Legend
);

function Macronutrients() {
  const [weeklyNPs, setWeeklyNPs] = useState([]);
  const [loading, setLoading] = useState();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [sidebarOpen, setSidebarOpen] = useState(false); // State for sidebar menu

  const [authTokens, setAuthTokens] = useState(() =>
    localStorage.getItem("authTokens")
      ? JSON.parse(localStorage.getItem("authTokens"))
      : null
  );
  const [user, setUser] = useState(() =>
    localStorage.getItem("authTokens")
      ? jwt_decode(localStorage.getItem("authTokens"))
      : null
  );
  const [userProfileId, setUserProfileId] = useState(() =>
    localStorage.getItem("adult") ? localStorage.getItem("adult") : null
  );

  const today = new Date();
  const currentDayOfWeek = today.getDay(); // Get the current day of the week (0-6, where 0 is Sunday)
  // console.log(currentDayOfWeek);

  // Calculate the date of the Monday and Sunday
  const monday = new Date(today);
  const sunday = new Date(today);
  const nextMonday = new Date(today);

  monday.setDate(today.getDate() - currentDayOfWeek + 1);
  sunday.setDate(today.getDate() - currentDayOfWeek + 7);
  nextMonday.setDate(today.getDate() - currentDayOfWeek + 8);

  // Format the Monday and Sunday date
  const m_year = monday.getFullYear();
  const m_month = String(monday.getMonth() + 1).padStart(2, "0");
  const m_day = String(monday.getDate()).padStart(2, "0");

  const formattedMonday = `${m_year}-${m_month}-${m_day}`;

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    if (user == null || user.is_active == false || userProfileId == null) {
      navigate("/");
    } else {
      const handleResize = () => {
        setIsMobile(window.innerWidth <= 768);
      };

      handleResize(); // Initial check

      window.addEventListener("resize", handleResize);
      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }
  }, []);

  useEffect(() => {
    if (user == null || user.is_active == false || userProfileId == null) {
      navigate("/");
    } else {
      const fetchData = async () => {
        if (user == null) {
          navigate("/");
        } else {
          let timer;
          try {
            timer = setTimeout(() => {
              setLoading(true);
              window.scrollTo({
                top: 0,
                behavior: "smooth",
              });
            }, 200);
            const responseWeeklyNPs = await axios.get(
              `${userProfileId}/${formattedMonday}/getWeeklyNPs`
            );
            setWeeklyNPs(responseWeeklyNPs.data);
            // console.log(responseWeeklyNPs.data);
          } catch (error) {
            // console.error(error);
          } finally {
            clearTimeout(timer);
            setLoading(false);
          }
        }
      };
      fetchData();
    }
  }, []);

  const label1 = t("carbs") + " (%)";
  const label2 = t("fat") + " (%)";
  const label3 = t("protein") + " (%)";

  const days = [
    t("Monday"),
    t("Tuesday"),
    t("Wednesday"),
    t("Thursday"),
    t("Friday"),
    t("Saturday"),
    t("Sunday"),
  ];

  const calculatePercentage = (value, total) =>
    Math.round((value / total) * 100);

  const data2 = {
    labels: days,
    datasets: [
      {
        label: label1,
        data: Object.entries(weeklyNPs)?.map(([key, value]) =>
          calculatePercentage(
            value.carbs,
            value.carbs + value.fat + value.protein
          )
        ),
        backgroundColor: ["rgba(255, 159, 64, 0.2)"],
        borderColor: ["rgb(255, 159, 64)"],

        borderWidth: 1,
      },
      {
        label: label2,
        data: Object.entries(weeklyNPs)?.map(([key, value]) =>
          calculatePercentage(
            value.fat,
            value.carbs + value.fat + value.protein
          )
        ),
        backgroundColor: ["rgba(255, 99, 132, 0.2)"],
        borderColor: ["rgb(255, 99, 132)"],
        borderWidth: 1,
      },
      {
        label: label3,
        data: Object.entries(weeklyNPs)?.map(([key, value]) =>
          calculatePercentage(
            value.protein,
            value.carbs + value.fat + value.protein
          )
        ),
        backgroundColor: ["rgba(75, 192, 192, 0.2)"],
        borderColor: ["rgb(75, 192, 192)"],
        borderWidth: 1,
      },
    ],
  };

  const config2 = {
    options: {
      scales: {
        x: {
          beginAtZero: true,
        },
        y: {
          beginAtZero: true,
        },
      },
      plugins: {
        tooltip: {
          position: "average",
        },
      },
    },
  };

  config2.options.indexAxis = isMobile ? "y" : "x";
  config2.options.scales.x.axis = isMobile ? "y" : "x";
  config2.options.scales.y.axis = isMobile ? "x" : "y";
  config2.options.maintainAspectRatio = isMobile ? false : true;
  config2.options.aspectRatio = 2;

  const containerClassName = isMobile
    ? "custom-container-mobile"
    : "custom-container";

  LogoutAfterInactivity();

  return (
    <div>
      <SideBar />
      <div className="rightpart">
        {loading && (
          <div class="text-center">
            <div class="spinner-border" role="status">
              <span class="sr-only">{t("Loading")}...</span>
            </div>
          </div>
        )}
        <br></br>
        <br></br>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1, ease: "easeOut" }}
        >
          <div className="title-font" style={{ textAlign: "center" }}>
            {t("Macronutrients")}
          </div>
          <div className="desc-font" style={{ textAlign: "center" }}>
            {t("macronutrients_desc")}
          </div>
          <br></br>
          <br></br>
          <div className={containerClassName}>
            <br></br>
            <Bar data={data2} config={config2} options={config2.options} />
          </div>
        </motion.div>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <div className="footer-fixed-2">{!isMobile && <Footer />} </div>
      </div>
    </div>
  );
}

export default Macronutrients;
