import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import jwt_decode from "jwt-decode";
import "./EdMaterialTopic.css";
import SideBar from "../../components/sideBar.js";
import Footer from "../../components/footer.js";
import backgroundImage from "../../images/graphics/about2.jpg";
import LogoutAfterInactivity from "../../components/logoutAfterInactivity.js";
import { useTranslation } from "react-i18next";
import { motion } from "framer-motion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome } from "@fortawesome/free-solid-svg-icons";

function EdMaterialTopic11() {
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const [isMobile, setIsMobile] = useState(false);
  const navigate = useNavigate();
  const [authTokens, setAuthTokens] = useState(() =>
    localStorage.getItem("authTokens")
      ? JSON.parse(localStorage.getItem("authTokens"))
      : null
  );
  const [user, setUser] = useState(() =>
    localStorage.getItem("authTokens")
      ? jwt_decode(localStorage.getItem("authTokens"))
      : null
  );
  const [userProfileId, setUserProfileId] = useState(() =>
    localStorage.getItem("adult") ? localStorage.getItem("adult") : null
  );
  useEffect(() => {
    if (user == null || user.is_active == false || userProfileId == null) {
      navigate("/");
    } else {
      const handleResize = () => {
        setIsMobile(window.innerWidth <= 768);
      };

      handleResize(); // Initial check

      window.addEventListener("resize", handleResize);
      window.scrollTo({ top: 0, behavior: "smooth" });
      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }
  }, []);
  LogoutAfterInactivity();
  const topicNumber = 11;
  // Handlers for the arrows
  const goToPreviousPage = () => {
    const previousPageNumber = topicNumber - 1; // Calculate the previous page number
    navigate(`/topic${previousPageNumber}`); // Use the previous page number in the route
  };

  const goToNextPage = () => {
    const nextPageNumber = topicNumber + 1; // Calculate the next page number
    navigate(`/topic${nextPageNumber}`); // Use the next page number in the route
  };
  const goBack = () => {
    navigate(`/educationalMaterials`); // Use the back button
  };
  return (
    <div className="topic-rightpart">
      <div
        style={{
          backgroundSize: "cover", // This will resize the image to cover the entire element
          backgroundRepeat: "no-repeat", // This will prevent the image from repeating
          backgroundPosition: "center", // This will position the image at the center of the element
        }}
      >
        <div>
          <SideBar />
          <motion.div
            initial={{ x: "40%" }}
            animate={{ x: "0%" }}
            transition={{ duration: 0.3, ease: "easeOut" }}
            className="Topic-Page"
          >
            <div className="header-container">
              {/* Previous Arrow */}
              <button className="prev-arrow" onClick={goToPreviousPage}>
                &larr;
              </button>

              {/* Title and Subtitle */}
              <div className="title-container">
                <h1>{t(`Topic${topicNumber}Title`)}</h1>
                <h3>{t(`Topic${topicNumber}Subtitle`)}</h3>
              </div>

              {/* Next Arrow */}
              <button className="next-arrow" onClick={goToNextPage}>
                &rarr;
              </button>
            </div>
            <br></br>
            {currentLanguage === "en" && (
              <div>
                <p className="TopicDescription-font">
                  <p>
                    To ensure a healthy, balanced and varied diet, it is
                    essential to be able to determine how often, daily or
                    weekly, a certain standard portion of a food can or should
                    be consumed, according to the different energy needs.
                  </p>
                  <p>
                    The consumption frequency of standard portions is defined as
                    the number of standard portions that are recommended for
                    each group of foods to be consumed daily or weekly in order
                    to have a healthy diet. These frequencies have been defined
                    considering the quantities in grams of the standard portions
                    for each type of food, the energy and nutritional
                    composition of the food and the objectives and nutritional
                    recommendations for maintaining a good health, in particular
                    to prevent obesity and chronic diseases. The consumption
                    frequencies, in detail, have been set for the various food
                    groups, subdivided into their main categories, for which a
                    given daily or weekly frequency has been assigned.
                  </p>
                  <p>
                    Consumption frequencies, as well as consumption portions,
                    are obviously not the same for everyone but must be adjusted
                    according to age, sex, height, physical activity level and
                    physiological status.
                  </p>
                  <p>
                    In order to have a healthy and balanced diet, it is
                    therefore important to consciously vary the choice of foods.
                  </p>
                </p>
              </div>
            )}
            {currentLanguage === "es" && (
              <div>
                <p className="TopicDescription-font">
                  <p>
                    Para garantizar una dieta sana, equilibrada y variada, es
                    fundamental poder determinar con qué frecuencia, diaria o
                    semanalmente, se puede o se debe consumir una determinada
                    ración estándar de un alimento, en función de las diferentes
                    necesidades energéticas.{" "}
                  </p>
                  <p>
                    La frecuencia de consumo de porciones estándar se define
                    como el número de porciones estándar que se recomiendan de
                    cada grupo de alimentos a consumir diaria o semanalmente
                    para tener una dieta saludable. Estas frecuencias se han
                    definido considerando las cantidades en gramos de las
                    raciones estándar para cada tipo de alimento, la composición
                    energética y nutricional de los alimentos y los objetivos y
                    recomendaciones nutricionales para mantener una buena salud,
                    en particular para prevenir la obesidad y las enfermedades
                    crónicas. Se han fijado detalladamente las frecuencias de
                    consumo de los distintos grupos de alimentos, subdivididos
                    en sus principales categorías, a las que se les ha asignado
                    una determinada frecuencia diaria o semanal.{" "}
                  </p>
                  <p>
                    Las frecuencias de consumo, así como las porciones de
                    consumo, obviamente no son las mismas para todos, sino que
                    deben ajustarse según la edad, el sexo, la altura, el nivel
                    de actividad física y el estado fisiológico.{" "}
                  </p>
                  <p>
                    Para llevar una dieta sana y equilibrada, es importante
                    variar conscientemente la elección de los alimentos.{" "}
                  </p>
                </p>
              </div>
            )}{" "}
            {currentLanguage === "tr" && (
              <div>
                <p className="TopicDescription-font">
                  <p>
                    Sağlıklı, dengeli ve çeşitli bir beslenme sağlamak için,
                    farklı enerji ihtiyaçlarına göre bir gıdanın belirli bir
                    standart porsiyonunun günlük veya haftalık olarak ne
                    sıklıkta tüketilebileceğini veya tüketilmesi gerektiğini
                    belirleyebilmek önemlidir.{" "}
                  </p>
                  <p>
                    Standart porsiyon tüketim sıklığı, sağlıklı beslenme için
                    her bir besin grubunun günlük veya haftalık olarak
                    tüketilmesi önerilen standart porsiyon sayısı olarak
                    tanımlanmaktadır. Bu sıklıklar, her bir gıda türü için
                    standart porsiyonların gram cinsinden miktarları, gıdanın
                    enerji ve besinsel bileşimi ve özellikle obezite ve kronik
                    hastalıkların önlenmesi için iyi bir sağlığın korunmasına
                    yönelik hedefler ve beslenme önerileri dikkate alınarak
                    tanımlanmıştır. Çeşitli gıda grupları için ayrıntılı olarak
                    tüketim sıklıkları belirlenmiş, ana kategorilerine ayrılmış
                    ve belirli bir günlük veya haftalık sıklık belirlenmiştir.
                  </p>
                  <p>
                    Tüketim sıklıkları ve tüketim porsiyonları tabi ki herkes
                    için aynı değildir. Yaşa, cinsiyete, boya, fiziksel aktivite
                    düzeyine ve fizyolojik duruma göre ayarlanması gerekir.
                  </p>
                  <p>
                    Sağlıklı ve dengeli beslenmek için besin seçimini bilinçli
                    olarak değiştirmek önemlidir.
                  </p>
                </p>
              </div>
              
            )}
            {currentLanguage === "fr" && (
                <div>
                <p className="TopicDescription-font">
                  <p>
                    Pour garantir une alimentation saine, équilibrée et variée, il est essentiel de pouvoir déterminer à quelle fréquence, quotidiennement ou hebdomadairement, une certaine portion standard d'un aliment peut ou devrait être consommée, en fonction des différents besoins énergétiques.
                  </p>
                  <p>
                    La fréquence de consommation des portions standard est définie comme le nombre de portions standard recommandées pour chaque groupe d'aliments à consommer quotidiennement ou hebdomadairement afin d'avoir une alimentation saine. Ces fréquences ont été définies en tenant compte des quantités en grammes des portions standard pour chaque type d'aliment, de la composition énergétique et nutritionnelle de l'aliment, ainsi que des objectifs et recommandations nutritionnels visant à maintenir une bonne santé, en particulier pour prévenir l'obésité et les maladies chroniques. Les fréquences de consommation, en détail, ont été établies pour les différents groupes alimentaires, subdivisés en leurs principales catégories, pour lesquelles une fréquence quotidienne ou hebdomadaire spécifique a été attribuée.
                  </p>
                  <p>
                    Les fréquences de consommation, ainsi que les portions de consommation, ne sont évidemment pas les mêmes pour tout le monde, mais doivent être ajustées en fonction de l'âge, du sexe, de la taille, du niveau d'activité physique et de l'état physiologique.
                  </p>
                  <p>
                    Pour avoir une alimentation saine et équilibrée, il est donc important de varier consciemment le choix des aliments.
                  </p>
                </p>
              </div>
              
              )}
              {currentLanguage === "ar"&& (
                <div>
                <p className="TopicDescription-font-arabic">
                  <p>
                    لضمان اتباع نظام غذائي صحي ومتوازن ومتنوع، من الضروري أن تكون قادرًا على تحديد عدد المرات التي يمكن أو ينبغي استهلاك جزء معين من الطعام يوميًا أو أسبوعيًا، وفقًا لاحتياجات الطاقة المختلفة.
                  </p>
                  <p>
                    يتم تعريف تردد استهلاك الأجزاء المقياسية على أنه عدد الأجزاء المقياسية الموصى باستهلاكها لكل مجموعة من الأطعمة يوميًا أو أسبوعيًا من أجل الحصول على نظام غذائي صحي. وقد تم تحديد هذه الترددات مع الأخذ في الاعتبار الكميات بالجرام للأجزاء المقياسية لكل نوع من الأطعمة، والطاقة والتركيب الغذائي للأغذية، والأهداف والتوصيات الغذائية للحفاظ على صحة جيدة، ولا سيما الوقاية من السمنة والأمراض المزمنة. تم تحديد ترددات الاستهلاك بالتفصيل لمختلف المجموعات الغذائية، مقسمة إلى فئاتها الرئيسية، والتي تم تخصيص تردد يومي أو أسبوعي معين لها.
                  </p>
                  <p>
                    من الواضح أن ترددات الاستهلاك، وكذلك أجزاء الاستهلاك، ليست هي نفسها بالنسبة للجميع ولكن يجب تعديلها وفقًا للعمر والجنس والطول ومستوى النشاط البدني والحالة الفسيولوجية.
                  </p>
                  <p>
                    من أجل الحصول على نظام غذائي صحي ومتوازن، من المهم تنويع اختيار الأطعمة بوعي.
                  </p>
                </p>
              </div>
              
              )}
            <button className="back-arrow" onClick={goBack}>
              <FontAwesomeIcon icon={faHome} /> {t("Educational_Material_Text")}{" "}
            </button>
          </motion.div>
        </div>
      </div>
      <div className="footer-sticky">{!isMobile && <Footer />} </div>
    </div>
  );
}

export default EdMaterialTopic11;
