import React, { useState, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import jwt_decode from "jwt-decode";
import SideBar from "../../components/sideBar";
import Footer from "../../components/footer.js";
import "../../components/sideBar.css";
import "../../components/footer.css";
import "./statistics.css";
import { useTranslation } from "react-i18next";
import "chartjs-plugin-annotation";
import LogoutAfterInactivity from "../../components/logoutAfterInactivity";
import { Line, Bar, PolarArea, Pie } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  RadialLinearScale,
  ArcElement,
  Tooltip,
  Legend,
  plugins,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  RadialLinearScale,
  ArcElement,
  Tooltip,
  Legend
);

function Micronutrients() {
  const [weeklyNPs, setWeeklyNPs] = useState([]);
  const [loading, setLoading] = useState();
  const navigate = useNavigate();
  const [selectedDayNumber, setSelectedDayNumber] = useState(0); // State to store the selected number
  const [selectedDay, setSelectedDay] = useState();
  const { t } = useTranslation();
  const [sidebarOpen, setSidebarOpen] = useState(false); // State for sidebar menu

  const [authTokens, setAuthTokens] = useState(() =>
    localStorage.getItem("authTokens")
      ? JSON.parse(localStorage.getItem("authTokens"))
      : null
  );
  const [user, setUser] = useState(() =>
    localStorage.getItem("authTokens")
      ? jwt_decode(localStorage.getItem("authTokens"))
      : null
  );

  const today = new Date();
  const currentDayOfWeek = today.getDay(); // Get the current day of the week (0-6, where 0 is Sunday)
  // console.log(currentDayOfWeek);

  // Calculate the date of the Monday and Sunday
  const monday = new Date(today);
  const sunday = new Date(today);
  const nextMonday = new Date(today);

  monday.setDate(today.getDate() - currentDayOfWeek + 1);
  sunday.setDate(today.getDate() - currentDayOfWeek + 7);
  nextMonday.setDate(today.getDate() - currentDayOfWeek + 8);

  // Format the Monday and Sunday date
  const m_year = monday.getFullYear();
  const m_month = String(monday.getMonth() + 1).padStart(2, "0");
  const m_day = String(monday.getDate()).padStart(2, "0");

  const formattedMonday = `${m_year}-${m_month}-${m_day}`;

  const [isMobile, setIsMobile] = useState(false);
  useEffect(() => {
    if (user == null || user.is_active == false) {
      navigate("/");
    } else {
      const handleResize = () => {
        setIsMobile(window.innerWidth <= 768);
      };

      handleResize(); // Initial check

      window.addEventListener("resize", handleResize);
      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }
  }, []);

  useEffect(() => {
    if (user == null || user.is_active == false) {
      navigate("/");
    } else {
      setSelectedDayNumber(currentDayOfWeek);
      setSelectedDay(dayMap[currentDayOfWeek]);

      const fetchData = async () => {
        if (user == null) {
          navigate("/");
        } else {
          let timer;
          try {
            timer = setTimeout(() => {
              setLoading(true);
              window.scrollTo({
                top: 0,
                behavior: "smooth",
              });
            }, 200);
            const responseWeeklyNPs = await axios.get(
              `${user.user_id}/${formattedMonday}/getWeeklyNPs`
            );
            setWeeklyNPs(responseWeeklyNPs.data);
            // console.log(responseWeeklyNPs.data);
          } catch (error) {
            // console.error(error);
          } finally {
            clearTimeout(timer);
            setLoading(false);
          }
        }
      };

      fetchData();
    }
  }, []);

  const dayMap = {
    1: "Monday",
    2: "Tuesday",
    3: "Wednesday",
    4: "Thursday",
    5: "Friday",
    6: "Saturday",
    7: "Sunday",
  };

  const handleDaySelection = (day) => {
    // Map the selected day to a number
    let number = 0;
    switch (day) {
      case "Monday":
        number = 1;
        day = "Monday";
        break;
      case "Tuesday":
        number = 2;
        day = "Tuesday";
        break;
      case "Wednesday":
        number = 3;
        day = "Wednesday";
        break;
      case "Thursday":
        number = 4;
        day = "Thursday";
        break;
      case "Friday":
        number = 5;
        day = "Friday";
        break;
      case "Saturday":
        number = 6;
        day = "Saturday";
        break;
      case "Sunday":
        number = 7;
        day = "Sunday";
        break;
      default:
        number = 0;
    }

    setSelectedDayNumber(number);
    setSelectedDay(day);
  };

  const preprocessData = (weeklyNPs, selectedDayNumber) => {
    const carbs = Object.entries(weeklyNPs)
      .filter(([key, value]) => key === selectedDayNumber.toString())
      .map(([key, value]) => value.carbs);
    const protein = Object.entries(weeklyNPs)
      .filter(([key, value]) => key === selectedDayNumber.toString())
      .map(([key, value]) => value.protein);
    const fat = Object.entries(weeklyNPs)
      .filter(([key, value]) => key === selectedDayNumber.toString())
      .map(([key, value]) => value.fat);

    const carbs_per = Math.round(
      (carbs[0] / (carbs[0] + protein[0] + fat[0])) * 100
    );
    const protein_per = Math.round(
      (protein[0] / (carbs[0] + protein[0] + fat[0])) * 100
    );
    const fat_per = Math.round(
      (fat[0] / (carbs[0] + protein[0] + fat[0])) * 100
    );

    return [carbs_per, protein_per, fat_per];
  };

  const label1 = t("Carbohydrates");
  const label2 = t("Protein");
  const label3 = t("Fat");

  const data3 = {
    labels: [label1, label2, label3],
    datasets: [
      {
        label: "%",
        data: preprocessData(weeklyNPs, selectedDayNumber),
        backgroundColor: [
          "rgba(255, 99, 132, 0.6)",
          "rgba(54, 162, 235, 0.6)",
          "rgba(255, 206, 86, 0.6)",
        ],
      },
    ],
  };

  const config3 = {
    type: "polar area",
    options: {
      scales: {
        y: {
          beginAtZero: true,
        },
      },
    },
    plugins: {
      tooltip: {
        position: "average",
      },
    },
  };

  LogoutAfterInactivity();

  return (
    <div>
      <SideBar setSidebarOpen={setSidebarOpen} />
      <div className="rightpart">
        {loading && (
          <div class="text-center">
            <div class="spinner-border" role="status">
              <span class="sr-only">{t("Loading")}...</span>
            </div>
          </div>
        )}
        <br></br>
        <br></br>
        <div>
          <div style={{ textAlign: "center" }}>
            <h2>{t("Macronutrients")}</h2>
          </div>
          <br></br>
          <br></br>
          <div className="dropdown-container">
            <div className="dropdown">
              <button
                className="btn btn-link dropdown-toggle"
                style={{
                  backgroundColor: "pink", // Set your desired background color
                  textDecoration: "none",
                  color: "black",
                }}
              >
                {t(`${selectedDay}`)}
              </button>
              <div className="dropdown-content">
                <a href="#" onClick={() => handleDaySelection("Monday")}>
                  {t("Monday")}
                </a>
                <a href="#" onClick={() => handleDaySelection("Tuesday")}>
                  {t("Tuesday")}
                </a>
                <a href="#" onClick={() => handleDaySelection("Wednesday")}>
                  {t("Wednesday")}
                </a>
                <a href="#" onClick={() => handleDaySelection("Thursday")}>
                  {t("Thursday")}
                </a>
                <a href="#" onClick={() => handleDaySelection("Friday")}>
                  {t("Friday")}
                </a>
                <a href="#" onClick={() => handleDaySelection("Saturday")}>
                  {t("Saturday")}
                </a>
                <a href="#" onClick={() => handleDaySelection("Sunday")}>
                  {t("Sunday")}
                </a>
              </div>
            </div>
          </div>
          <br></br>
          <br></br>
          <div className="chart-container">
            <div className="polar-area-chart">
              <Pie data={data3} config={config3} />
            </div>
          </div>
        </div>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <div className="footer-fixed-2">{!isMobile && <Footer />} </div>
      </div>
    </div>
  );
}

export default Micronutrients;
