import React, { useState, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { Alert } from "react-bootstrap";
import axios from "axios";
import jwt_decode from "jwt-decode";
import { motion } from "framer-motion";
import SideBar from "../components/sideBar";
import Footer from "../components/footer.js";
import "bootstrap/dist/css/bootstrap.css";
import "./homePage.css";
import "../components/sideBar.css";
import "../components/calendar.css";
import backgroundImage from "../images/homePage/food-4k-1vrcb0mw76zcg4qf.jpg";
import { useTranslation } from "react-i18next";
import LogoutAfterInactivity from "../components/logoutAfterInactivity";
import SideBar2 from "../components/sideBar2.js";

function ChildHomePage() {
  const [NPs, setNPs] = useState([]);
  const [valid_days, setVaid_days] = useState();
  const [selectedDay, setSelectedDay] = useState(1);
  const [selectedDish, setSelectedDish] = useState(null);
  const [weekList, setWeekList] = useState([]);
  const [weekCount, setWeekCount] = useState();
  const [loading, setLoading] = useState();
  const location = useLocation();
  const navigate = useNavigate();
  const [week, setWeek] = useState();
  const [startDate, setStartDate] = useState();
  const [formattedStartDate, setFormattedStartDate] = useState();
  const [formattedEndDate, setFormattedEndDate] = useState();
  const [errorMessage, setErrorMessage] = useState("");
  const [warningMessage, setWarningMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const [meals, setMeals] = useState([]);
  const [mondayMeals, setMondayMeals] = useState([]);
  const [tuesdayMeals, setTuesdayMeals] = useState([]);
  const [wednesdayMeals, setWednesdayMeals] = useState([]);
  const [thursdayMeals, setThursdayMeals] = useState([]);
  const [fridayMeals, setFridayMeals] = useState([]);
  const [saturdayMeals, setSaturdayMeals] = useState([]);
  const [sundayMeals, setSundayMeals] = useState([]);

  const [authTokens, setAuthTokens] = useState(() =>
    localStorage.getItem("authTokens")
      ? JSON.parse(localStorage.getItem("authTokens"))
      : null
  );
  const [user, setUser] = useState(() =>
    localStorage.getItem("authTokens")
      ? jwt_decode(localStorage.getItem("authTokens"))
      : null
  );
  const [userProfileId, setUserProfileId] = useState(() =>
    localStorage.getItem("child") ? localStorage.getItem("child") : null
  );
  const { t } = useTranslation();
  const [isMobile, setIsMobile] = useState(false);

  const days = [1, 2, 3, 4, 5, 6, 7];

  const dayMap = {
    1: t("Monday"),
    2: t("Tuesday"),
    3: t("Wednesday"),
    4: t("Thursday"),
    5: t("Friday"),
    6: t("Saturday"),
    7: t("Sunday"),
  };

  const today = new Date();
  const currentDayOfWeek = today.getDay(); // Get the current day of the week (0-6, where 0 is Sunday)

  // Calculate the date of the Monday and Sunday
  const monday = new Date(today);
  const sunday = new Date(today);
  const nextMonday = new Date(today);
  const nextSunday = new Date(today);
  monday.setDate(today.getDate() - currentDayOfWeek + 1);
  sunday.setDate(today.getDate() - currentDayOfWeek + 7);
  nextMonday.setDate(today.getDate() - currentDayOfWeek + 8);
  nextSunday.setDate(today.getDate() - currentDayOfWeek + 14);

  // Format the Monday and Sunday date
  const m_year = monday.getFullYear();
  const m_month = String(monday.getMonth() + 1).padStart(2, "0");
  const m_day = String(monday.getDate()).padStart(2, "0");

  const s_year = sunday.getFullYear();
  const s_month = String(sunday.getMonth() + 1).padStart(2, "0");
  const s_day = String(sunday.getDate()).padStart(2, "0");

  const nm_year = nextMonday.getFullYear();
  const nm_month = String(nextMonday.getMonth() + 1).padStart(2, "0");
  const nm_day = String(nextMonday.getDate()).padStart(2, "0");

  const ns_year = nextSunday.getFullYear();
  const ns_month = String(nextSunday.getMonth() + 1).padStart(2, "0");
  const ns_day = String(nextSunday.getDate()).padStart(2, "0");

  const formattedMonday = `${m_year}-${m_month}-${m_day}`;
  const formattedSunday = `${s_year}-${s_month}-${s_day}`;
  const formattedNextMonday = `${nm_year}-${nm_month}-${nm_day}`;
  const formattedNextSunday = `${ns_year}-${ns_month}-${ns_day}`;

  const [ingredients, setIngredients] = useState("");

  // const formattedMonday = "2023-08-18";

  //console.log(formattedDate); // Output: "2023-06-12" (for example)
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    handleResize(); // Initial check

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    let timer;
    const fetchData = async () => {
      if (user == null || user.is_active == false || userProfileId == null) {
        navigate("/");
      } else {
        setSelectedDay(currentDayOfWeek === 0 ? 7 : currentDayOfWeek);
        setFormattedStartDate(m_day + "/" + m_month + "/" + m_year);
        setFormattedEndDate(s_day + "/" + s_month + "/" + s_year);

        try {
          timer = setTimeout(() => {
            setLoading(true);
            window.scrollTo({
              top: 0,
              behavior: "smooth",
            });
          }, 200);
          const response = await axios.get(
            `${userProfileId}/${formattedMonday}/getChildNPs`
          );
          //   console.log(response.data.slice(0, 5));
          //   console.log(response.data.slice(5, 10));
          //   console.log(response.data.slice(10, 15));
          //   console.log(response.data.slice(15, 20));
          //   console.log(response.data.slice(20, 25));
          //   console.log(response.data.slice(25, 30));
          //   console.log(response.data.slice(30, 35));
          // console.log(response.data);
          setNPs(response.data);
          setMondayMeals(response.data.slice(0, 5));
          setTuesdayMeals(response.data.slice(5, 10));
          // console.log(response.data.slice(5, 10));
          setWednesdayMeals(response.data.slice(10, 15));
          setThursdayMeals(response.data.slice(15, 20));
          setFridayMeals(response.data.slice(20, 25));
          setSaturdayMeals(response.data.slice(25, 30));
          setSundayMeals(response.data.slice(30, 35));

          if (selectedDay === 1) {
            setMeals(response.data.slice(0, 5));
          } else if (selectedDay === 2) {
            setMeals(response.data.slice(5, 10));
          } else if (selectedDay === 3) {
            setMeals(response.data.slice(10, 15));
          } else if (selectedDay === 4) {
            setMeals(response.data.slice(15, 20));
          } else if (selectedDay === 5) {
            setMeals(response.data.slice(20, 25));
          } else if (selectedDay === 6) {
            setMeals(response.data.slice(25, 30));
          } else if (selectedDay === 7) {
            setMeals(response.data.slice(30, 35));
          }

          const response2 = await axios.get(
            "IdChildProfile/" + userProfileId + "/"
          );
          // console.log(response2.data);
          if (response2.data.group_age === "3-6") {
            setIngredients("ingredients_3_6");
          } else if (response2.data.group_age === "7-11") {
            setIngredients("ingredients_7_11");
          } else if (response2.data.group_age === "12-15") {
            setIngredients("ingredients_12_15");
          } else if (response2.data.group_age === "16-17") {
            setIngredients("ingredients_16_17");
          } else if (response2.data.group_age === "7-9") {
            setIngredients("ingredients_7_9");
          } else if (response2.data.group_age === "10-11") {
            setIngredients("ingredients_10_11");
          } else if (response2.data.group_age === "12-17") {
            setIngredients("ingredients_12_17");
          }
          // console.log(response2.data.group_age);
          // console.log(ingredients);
        } catch {
        } finally {
          clearTimeout(timer);
          setLoading(false);
        }
      }
    };
    fetchData();
    return () => clearTimeout(timer);
  }, []);

  const daySelection = (day) => {
    setSelectedDay(day);
    if (day === 1) {
      setMeals(mondayMeals);
    } else if (day === 2) {
      setMeals(tuesdayMeals);
    } else if (day === 3) {
      setMeals(wednesdayMeals);
    } else if (day === 4) {
      setMeals(thursdayMeals);
    } else if (day === 5) {
      setMeals(fridayMeals);
    } else if (day === 6) {
      setMeals(saturdayMeals);
    } else if (day === 7) {
      setMeals(sundayMeals);
    }
  };

  const handleDishClick = (dish) => {
    setSelectedDish(dish);

    if (isMobile) {
      // Scroll to the meal-grid-right section
      window.scrollTo({
        top: document.querySelector(".meal-grid-right").offsetTop,
        behavior: "smooth",
      });
    }
  };

  // const handleNextWeek = () => {
  //   let timer;
  //   const fetchData = async () => {
  //     if (user == null || user.is_active == false || userProfileId == null) {
  //       navigate("/");
  //     } else {
  //       setSelectedDay(currentDayOfWeek === 0 ? 7 : currentDayOfWeek);
  //       setFormattedStartDate(nm_day + "/" + nm_month + "/" + nm_year);
  //       setFormattedEndDate(ns_day + "/" + ns_month + "/" + ns_year);

  //       try {
  //         timer = setTimeout(() => {
  //           setLoading(true);
  //           window.scrollTo({
  //             top: 0,
  //             behavior: "smooth",
  //           });
  //         }, 200);
  //         const response = await axios.get(
  //           `${userProfileId}/${formattedNextMonday}/getChildNPs`
  //         );
  //         //   console.log(response.data.slice(0, 5));
  //         //   console.log(response.data.slice(5, 10));
  //         //   console.log(response.data.slice(10, 15));
  //         //   console.log(response.data.slice(15, 20));
  //         //   console.log(response.data.slice(20, 25));
  //         //   console.log(response.data.slice(25, 30));
  //         //   console.log(response.data.slice(30, 35));
  //         // console.log(response.data);
  //         setNPs(response.data);
  //         setMondayMeals(response.data.slice(0, 5));
  //         setTuesdayMeals(response.data.slice(5, 10));
  //         // console.log(response.data.slice(5, 10));
  //         setWednesdayMeals(response.data.slice(10, 15));
  //         setThursdayMeals(response.data.slice(15, 20));
  //         setFridayMeals(response.data.slice(20, 25));
  //         setSaturdayMeals(response.data.slice(25, 30));
  //         setSundayMeals(response.data.slice(30, 35));

  //         if (selectedDay === 1) {
  //           setMeals(response.data.slice(0, 5));
  //         } else if (selectedDay === 2) {
  //           setMeals(response.data.slice(5, 10));
  //         } else if (selectedDay === 3) {
  //           setMeals(response.data.slice(10, 15));
  //         } else if (selectedDay === 4) {
  //           setMeals(response.data.slice(15, 20));
  //         } else if (selectedDay === 5) {
  //           setMeals(response.data.slice(20, 25));
  //         } else if (selectedDay === 6) {
  //           setMeals(response.data.slice(25, 30));
  //         } else if (selectedDay === 7) {
  //           setMeals(response.data.slice(30, 35));
  //         }

  //         const response2 = await axios.get(
  //           "IdChildProfile/" + userProfileId + "/"
  //         );
  //         // console.log(response2.data);
  //         if (response2.data.group_age === "3-6") {
  //           setIngredients("ingredients_3_6");
  //         } else if (response2.data.group_age === "7-11") {
  //           setIngredients("ingredients_7_11");
  //         } else if (response2.data.group_age === "12-15") {
  //           setIngredients("ingredients_12_15");
  //         } else if (response2.data.group_age === "16-17") {
  //           setIngredients("ingredients_16_17");
  //         } else if (response2.data.group_age === "7-9") {
  //           setIngredients("ingredients_7_9");
  //         } else if (response2.data.group_age === "10-11") {
  //           setIngredients("ingredients_10_11");
  //         } else if (response2.data.group_age === "12-17") {
  //           setIngredients("ingredients_12_17");
  //         }
  //         // console.log(response2.data.group_age);
  //         // console.log(ingredients);
  //       } catch {
  //       } finally {
  //         clearTimeout(timer);
  //         setLoading(false);
  //       }
  //     }
  //   };
  //   fetchData();
  //   return () => clearTimeout(timer);
  // };

  // const handleDishClick = (dish) => {
  //   setSelectedDish(dish);
  //   window.scrollTo({
  //     top: document.querySelector(".meal-grid-right").offsetTop,
  //     behavior: "smooth",
  //   });
  // };

  LogoutAfterInactivity();

  return (
    <div className="parent-container">
      <div className="page-container">
        <SideBar2 />

        <div className="rightpart">
          {loading && (
            <div class="text-center">
              <div class="spinner-border" role="status">
                <span class="sr-only">{t("Loading")}...</span>
              </div>
            </div>
          )}
          <div
            style={{
              backgroundImage: `url(${backgroundImage})`,
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {successMessage && (
                <Alert variant="success">{successMessage}</Alert>
              )}
              {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
              {warningMessage && (
                <Alert variant="warning">{warningMessage}</Alert>
              )}
            </div>
            <div className="calendar">
              <div className="container">
                <div className="row justify-content-md-center">
                  <div className="controls">
                    <div className="col-md-auto ">
                      <motion.button
                        className="previousweek"
                        disabled
                        // onClick={handlePreviousWeek}
                      ></motion.button>
                    </div>
                    <div className="col-md-auto">
                      <div className="formatted-day">
                        <h1 className="formatted-days-font">
                          {formattedStartDate} - {formattedEndDate}
                        </h1>
                      </div>
                    </div>
                    <div className="col-md-auto">
                      <motion.button
                        className="nextweek"
                        disabled
                        // onClick={handleNextWeek}
                      ></motion.button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="days">
                {days.map((day) => (
                  <motion.div
                    whileHover={{ scale: 1.1 }}
                    whileTap={{ scale: 0.9 }}
                    transition={{
                      type: "spring",
                      stiffness: 400,
                      damping: 17,
                    }}
                    className={`day ${selectedDay === day ? "selected" : ""}`}
                    key={day}
                    onClick={() => {
                      daySelection(day);
                      setSelectedDish(null);
                    }}
                  >
                    <h1
                      className={`custom-font ${
                        window.innerWidth <= 768 ? "mobile-day" : ""
                      }`}
                    >
                      {window.innerWidth <= 768 ? dayMap[day][0] : dayMap[day]}
                    </h1>
                  </motion.div>
                ))}
                {/* {days.map((day) => (
                  <div
                    className={`day ${selectedDay === day ? "selected" : ""}`}
                    key={day}
                    onClick={() => setSelectedDay(day)}
                  >
                    <h1 className="custom-font">{dayMap[day][0]}</h1>{" "}
                  </div>
                ))} */}

                {/* {days.map((day) => (
                  <div
                    className={`day ${selectedDay === day ? "selected" : ""}`}
                    key={day}
                    onClick={() => setSelectedDay(day)}
                  >
                    <h1 className="custom-font">{dayMap[day]}</h1>
                  </div>
                ))} */}
              </div>
            </div>

            <div className="meal-grid">
              <motion.div
                whileHover={{ scale: 1.05 }}
                // whileTap={{ scale: 0.9 }}
                transition={{ type: "spring", stiffness: 400, damping: 17 }}
                className="child-meal-grid-left"
              >
                {meals.map((meal) => {
                  //console.log(meal[0]);
                  //console.log("meal.id", meal.id);
                  //console.log("meal.type", meal.type);

                  return (
                    <div key={`${meal.id}`}>
                      <p className="meal-type-font">{t(`${meal.type}`)}</p>
                      {meal.dish_1 && (
                        <p
                          onClick={() => handleDishClick(meal.dish_1)}
                          className="dish-font-grid"
                        >
                          <div
                            className={`sth ${
                              selectedDish === meal.dish_1 ? "selected" : ""
                            }`}
                          >
                            {meal.dish_1.dish_language_info[0].name}
                            {/* <span style={{ float: "right" }}>
                                  {t("kcal")}: {meal.dish_1.kcal},{" "}
                                  {t("protein")}: {meal.dish_1.protein} g,{" "}
                                  {t("fat")}: {meal.dish_1.fat} g, {t("carbs")}:{" "}
                                  {meal.dish_1.carbohydrates} g
                                </span> */}
                          </div>
                        </p>
                      )}
                      {meal.dish_2 && (
                        <p
                          onClick={() => handleDishClick(meal.dish_2)}
                          className="dish-font-grid"
                        >
                          <div
                            className={`sth ${
                              selectedDish === meal.dish_2 ? "selected" : ""
                            }`}
                          >
                            {meal.dish_2.dish_language_info[0].name}
                            {/* <span style={{ float: "right" }}>
                                  {t("kcal")}: {meal.dish_2.kcal},{" "}
                                  {t("protein")}: {meal.dish_2.protein} g,{" "}
                                  {t("fat")}: {meal.dish_2.fat} g, {t("carbs")}:{" "}
                                  {meal.dish_2.carbohydrates} g
                                </span> */}
                          </div>
                        </p>
                      )}
                      {meal.dish_3 && (
                        <p
                          onClick={() => handleDishClick(meal.dish_3)}
                          className="dish-font-grid"
                        >
                          <div
                            className={`sth ${
                              selectedDish === meal.dish_3 ? "selected" : ""
                            }`}
                          >
                            {meal.dish_3.dish_language_info[0].name}
                            {/* <span style={{ float: "right" }}>
                                  {t("kcal")}: {meal.dish_3.kcal},{" "}
                                  {t("protein")}: {meal.dish_3.protein} g,{" "}
                                  {t("fat")}: {meal.dish_3.fat} g, {t("carbs")}:{" "}
                                  {meal.dish_3.carbohydrates} g
                                </span> */}
                          </div>
                        </p>
                      )}
                      {meal.dish_4 && (
                        <p
                          onClick={() => handleDishClick(meal.dish_4)}
                          className="dish-font-grid"
                        >
                          <div
                            className={`sth ${
                              selectedDish === meal.dish_4 ? "selected" : ""
                            }`}
                          >
                            {meal.dish_4.dish_language_info[0].name}
                            {/* <span style={{ float: "right" }}>
                                  {t("kcal")}: {meal.dish_4.kcal},{" "}
                                  {t("protein")}: {meal.dish_4.protein} g,{" "}
                                  {t("fat")}: {meal.dish_4.fat} g, {t("carbs")}:{" "}
                                  {meal.dish_4.carbohydrates} g
                                </span> */}
                          </div>
                        </p>
                      )}
                      {meal.dish_5 && (
                        <p
                          onClick={() => handleDishClick(meal.dish_5)}
                          className="dish-font-grid"
                        >
                          <div
                            className={`sth ${
                              selectedDish === meal.dish_5 ? "selected" : ""
                            }`}
                          >
                            {meal.dish_5.dish_language_info[0].name}
                            {/* <span style={{ float: "right" }}>
                                  {t("kcal")}: {meal.dish_5.kcal},{" "}
                                  {t("protein")}: {meal.dish_5.protein} g,{" "}
                                  {t("fat")}: {meal.dish_5.fat} g, {t("carbs")}:{" "}
                                  {meal.dish_5.carbohydrates} g
                                </span> */}
                          </div>
                        </p>
                      )}
                    </div>
                  );
                })}
              </motion.div>
              <motion.div
                whileHover={{ scale: 1.05 }}
                // whileTap={{ scale: 0.9 }}
                transition={{ type: "spring", stiffness: 400, damping: 17 }}
                className="child-meal-grid-right"
              >
                {/* <div className="custom-font2-title">Additional Information</div> */}
                {selectedDish && (
                  <motion.div
                    key={selectedDish.id}
                    initial={{ x: 10, opacity: 0 }}
                    animate={{ x: 0, opacity: 1 }}
                    exit={{ x: -10, opacity: 0 }}
                    transition={{ duration: 0.5 }}
                  >
                    <p className="dish-name-font">
                      {selectedDish.dish_language_info[0].name}
                    </p>
                    <br></br>
                    {/* <p className="custom-font2">{t("Nutritional_info")}:</p>
                    <div className="custom-font">
                      <ul>
                        <li>
                          {t("energy")}: {selectedDish.kcal} kcal
                        </li>
                        <li>
                          {t("fat")}: {selectedDish.fat} g
                        </li>
                        <li>
                          {t("carbs")}: {selectedDish.carbohydrates} g
                        </li>
                        <li>
                          {t("protein")}: {selectedDish.protein} g
                        </li>
                      </ul>
                    </div>
                    <br></br> */}
                    <div>
                      {selectedDish.dish_language_info[0][ingredients] && (
                        <div>
                          <p className="custom-font2">{t("Ingredients")}:</p>
                          <ol>
                            {selectedDish.dish_language_info[0][ingredients]
                              .split(";")
                              .filter((item) => item.trim() !== "") // Filter out empty strings
                              .map((item, index) => {
                                const [ingredient, quantity, measure] = item
                                  .split(",")
                                  .map((part) => part.trim());
                                return (
                                  <li className="custom-font" key={index}>
                                    {ingredient}, {quantity} {measure}
                                    {/* Renders each item with space */}
                                  </li>
                                );
                              })}
                          </ol>
                        </div>
                      )}
                    </div>
                    <br></br>
                    <div>
                      {selectedDish.dish_language_info[0].recipe !== "nan" && (
                        <div>
                          <p className="custom-font2">{t("Recipe")}:</p>
                          <p className="custom-font">
                            {selectedDish.dish_language_info[0].recipe}
                          </p>
                        </div>
                      )}
                    </div>
                    <br></br>
                    <div>
                      {selectedDish.dish_language_info[0].tip !== "nan" && (
                        <div>
                          <p className="custom-font2">{t("Recipe")}:</p>
                          <p className="custom-font">
                            {selectedDish.dish_language_info[0].tip}
                          </p>
                        </div>
                      )}
                    </div>
                  </motion.div>
                )}
              </motion.div>
            </div>
            <div className="footer-sticky">{!isMobile && <Footer />} </div>
          </div>

          {/* Render Footer only if isMobile is false */}
        </div>
      </div>
    </div>
  );
}

export default ChildHomePage;
