import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import jwt_decode from "jwt-decode";
import "./EdMaterialTopic.css";
import SideBar from "../../components/sideBar.js";
import Footer from "../../components/footer.js";
import EdMat10 from "../../images/EdMaterial/EdMat10.png";
import backgroundImage from "../../images/graphics/about2.jpg";
import LogoutAfterInactivity from "../../components/logoutAfterInactivity.js";
import { useTranslation } from "react-i18next";
import { motion } from "framer-motion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome } from "@fortawesome/free-solid-svg-icons";

function EdMaterialTopic10() {
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const [isMobile, setIsMobile] = useState(false);
  const navigate = useNavigate();
  const [authTokens, setAuthTokens] = useState(() =>
    localStorage.getItem("authTokens")
      ? JSON.parse(localStorage.getItem("authTokens"))
      : null
  );
  const [user, setUser] = useState(() =>
    localStorage.getItem("authTokens")
      ? jwt_decode(localStorage.getItem("authTokens"))
      : null
  );
  const [userProfileId, setUserProfileId] = useState(() =>
    localStorage.getItem("adult") ? localStorage.getItem("adult") : null
  );
  useEffect(() => {
    if (user == null || user.is_active == false || userProfileId == null) {
      navigate("/");
    } else {
      const handleResize = () => {
        setIsMobile(window.innerWidth <= 768);
      };

      handleResize(); // Initial check

      window.addEventListener("resize", handleResize);
      window.scrollTo({ top: 0, behavior: "smooth" });
      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }
  }, []);
  LogoutAfterInactivity();
  const topicNumber = 10;
  // Handlers for the arrows
  const goToPreviousPage = () => {
    const previousPageNumber = topicNumber - 1; // Calculate the previous page number
    navigate(`/topic${previousPageNumber}`); // Use the previous page number in the route
  };

  const goToNextPage = () => {
    const nextPageNumber = topicNumber + 1; // Calculate the next page number
    navigate(`/topic${nextPageNumber}`); // Use the next page number in the route
  };
  const goBack = () => {
    navigate(`/educationalMaterials`); // Use the back button
  };
  return (
    <div className="topic-rightpart">
      <div
        style={{
          backgroundSize: "cover", // This will resize the image to cover the entire element
          backgroundRepeat: "no-repeat", // This will prevent the image from repeating
          backgroundPosition: "center", // This will position the image at the center of the element
        }}
      >
        <div>
          <SideBar />
          <motion.div
            initial={{ x: "40%" }}
            animate={{ x: "0%" }}
            transition={{ duration: 0.3, ease: "easeOut" }}
            className="Topic-Page"
          >
            <div className="header-container">
              {/* Previous Arrow */}
              <button className="prev-arrow" onClick={goToPreviousPage}>
                &larr;
              </button>

              {/* Title and Subtitle */}
              <div className="title-container">
                <h1>{t(`Topic${topicNumber}Title`)}</h1>
                <h3>{t(`Topic${topicNumber}Subtitle`)}</h3>
              </div>

              {/* Next Arrow */}
              <button className="next-arrow" onClick={goToNextPage}>
                &rarr;
              </button>
            </div>
            <br></br>
            {currentLanguage === "en" && (
              <div>
                <p className="TopicDescription-font">
                  <p>
                    Breakfast is the first meal of the day and is important for
                    everyone, at any age and in any health condition. Breakfast
                    breaks the night fast and, consumed shortly after waking up,
                    prepares you for daily activities. It should therefore hold
                    a very important place in the daily diet.
                  </p>
                  <p>
                    It is becoming increasingly well known that a balanced
                    breakfast, which becomes a regular habit from early
                    childhood, promotes both development and the maintenance of
                    a good nutritional status. There is no one ideal breakfast,
                    sweet or savoury alike, the adequacy of the breakfast comes
                    from the combination of foods and the variety of choices.
                    Breakfast should be a meal that includes different groups of
                    foods, in order to be balanced in terms of energy and
                    nutrients.
                  </p>
                </p>
                <img
                  src={EdMat10}
                  style={{ maxWidth: "20%", height: "auto" }}
                />
                <p className="TopicDescription-font">
                  <p>
                    By following a few simple tips you can prepare a suitable
                    breakfast for the whole family!{" "}
                  </p>
                  <ul>
                    <li>Breakfast should be eaten every day.</li>
                    <li>
                      Breakfast should provide about 15-20% of the daily energy
                      needs.
                    </li>
                    <li>
                      The daily choice of foods to be consumed for breakfast is
                      important both for the correct intake of nutrients and for
                      the pleasure of enjoying breakfast!
                    </li>
                    <li>
                      Milk and yoghurt (better if low fat content) should always
                      be present at breakfast, in fact, during the rest of the
                      day, there are few opportunities to consume these foods.
                      Remember that the intake of milk and yoghurt is essential
                      to ensure a correct calcium intake.
                    </li>
                    <li>
                      If we definitely do not consume milk or yoghurt, we can
                      incorporate foods that are sources of protein, preferably
                      with little fat and little salt, to control the sense of
                      satiety throughout the day.
                    </li>
                    <li>
                      Cereals are also highly recommended at breakfast, but it
                      is better to choose wholegrain cereals that are richer in
                      dietary fibre. Cereals do not necessarily mean breakfast
                      cereals, on the contrary! Bread, especially wholemeal
                      bread, is an excellent alternative that can be included in
                      both savoury and sweet breakfasts.
                    </li>
                    <li>
                      Fruit should be consumed at breakfast every day. Nuts are
                      also a good choice for a balanced breakfast, especially if
                      you don't use them during the day as snacks.
                    </li>
                    <li>
                      Be careful! Products consumed at breakfast often contain a
                      high salt content. Always read the labels on the packages,
                      to know the composition of the food you bring to the
                      table.
                    </li>
                    <li>
                      Even at breakfast, sugar, honey or fructose should not be
                      added to drinks.
                    </li>
                    <li>
                      Breakfast is also an important time for hydration! Milk,
                      coffee, and tea definitely contribute to your water
                      needs... but it is also important to drink water for
                      breakfast.
                    </li>
                    <li>
                      Breakfast is a convivial moment! If you can, try to eat it
                      at the table, together with your family to teach everyone
                      this good and essential habit and start the day in the
                      best way.
                    </li>
                    <li>
                      If at breakfast you just can't give up sweets, try to
                      avoid snacks and biscuits and prepare wholemeal homemade
                      cakes and biscuits with olive oil and very little sugar.
                      This is a good way to improve your breakfast.
                    </li>
                    <li>
                      Try to vary your breakfast as much as possible! Also, for
                      breakfast, it is important to vary what you eat and not to
                      always enjoy the same type of food. Follow the rule of
                      seasonality at breakfast as well, choosing fresh seasonal
                      fruit and varying foods and drinks every day.
                    </li>
                  </ul>
                </p>
              </div>
            )}
            {currentLanguage === "es" && (
              <div>
                <p className="TopicDescription-font">
                  <p>
                    El desayuno es la primera comida del día y es importante
                    para todas las personas, a cualquier edad y en cualquier
                    estado de salud. El desayuno rompe el ayuno nocturno y,
                    consumido poco después de despertarse, te prepara para las
                    actividades diarias. Por tanto, debería ocupar un lugar muy
                    importante en la dieta diaria.{" "}
                  </p>
                  <p>
                    Cada vez es más sabido que un desayuno equilibrado, que se
                    convierte en un hábito habitual desde la primera infancia,
                    favorece tanto el desarrollo como el mantenimiento de un
                    buen estado nutricional. No existe un desayuno ideal, ya sea
                    dulce o salado, la idoneidad del desayuno pasa por la
                    combinación de alimentos y la variedad de opciones. El
                    desayuno debe ser una comida que incluya diferentes grupos
                    de alimentos, para que sea equilibrado en cuanto a energía y
                    nutrientes.
                  </p>
                </p>
                <img
                  src={EdMat10}
                  style={{ maxWidth: "20%", height: "auto" }}
                />
                <p className="TopicDescription-font">
                  <p>
                    ¡Siguiendo unos sencillos consejos podrás preparar un
                    desayuno adecuado para toda la familia!
                  </p>
                  <ul>
                    <li>El desayuno se debe tomar todos los días.</li>
                    <li>
                      El desayuno debe proporcionar entre el 15 y el 20 % de las
                      necesidades energéticas diarias.
                    </li>
                    <li>
                      La elección diaria de los alimentos a consumir en el
                      desayuno es importante tanto para la correcta ingesta de
                      nutrientes como para el placer de disfrutar del desayuno.
                    </li>
                    <li>
                      La leche y el yogur (mejor si son bajos en grasas) siempre
                      deben estar presentes en el desayuno, de hecho, durante el
                      resto del día hay pocas oportunidades para consumir estos
                      alimentos. Recuerda que la ingesta de leche y yogur es
                      fundamental para asegurar un correcto aporte de calcio.
                    </li>
                    <li>
                      Si definitivamente no consumimos leche ni yogur, podemos
                      incorporar alimentos que sean fuentes de proteínas,
                      preferiblemente con poca grasa y poca sal, para controlar
                      la sensación de saciedad a lo largo del día.
                    </li>
                    <li>
                      Los cereales también son muy recomendables en el desayuno,
                      pero es mejor elegir cereales integrales que sean más
                      ricos en fibra dietética. Los cereales no necesariamente
                      significan cereales para el desayuno, ¡al contrario! El
                      pan, especialmente el integral, es una excelente
                      alternativa que puede incluirse en desayunos tanto salados
                      como dulces.
                    </li>
                    <li>
                      La fruta se debe consumir en el desayuno todos los días.
                      Los frutos secos también son una buena opción para un
                      desayuno equilibrado, sobre todo si no los utilizas
                      durante el día como snack.
                    </li>
                    <li>
                      ¡Ten cuidado! Los productos que se consumen en el desayuno
                      suelen contener un alto contenido en sal. Lee siempre las
                      etiquetas de los paquetes, para conocer la composición de
                      los alimentos que llevas a la mesa.
                    </li>
                    <li>
                      No se debe añadir a las bebidas azúcar, miel o fructosa.
                    </li>
                    <li>
                      ¡El desayuno también es un momento importante para la
                      hidratación! La leche, el café, el té definitivamente
                      contribuyen a tus necesidades de agua... pero también es
                      importante beber agua en el desayuno.
                    </li>
                    <li>
                      ¡El desayuno es un momento agradable! Si puedes prueba a
                      comerlo en la mesa, junto a tu familia para enseñar a
                      todos este buen e imprescindible hábito y empezar el día
                      de la mejor manera.
                    </li>
                    <li>
                      Si en el desayuno no puedes renunciar a los dulces,
                      intenta evitar los snacks y galletas y prepara tartas y
                      bizcochos caseros integrales con aceite de oliva y muy
                      poco azúcar. Esta es una buena forma de mejorar tu
                      desayuno.
                    </li>
                    <li>
                      ¡Intenta variar tu desayuno lo máximo posible! También en
                      el desayuno es importante variar lo que se come y no
                      disfrutar siempre del mismo tipo de comida. Sigue también
                      la regla de la estacionalidad en el desayuno, eligiendo
                      fruta fresca de temporada y variando alimentos y bebidas
                      todos los días.
                    </li>
                  </ul>
                </p>
              </div>
            )}{" "}
            {currentLanguage === "tr" && (
              <div>
                <p className="TopicDescription-font">
                  <p>
                    Kahvaltı günün ilk öğünüdür ve her yaşta, her sağlık
                    koşulunda herkes için önemlidir. Kahvaltı gece orucunu açar
                    ve uyandıktan hemen sonra tüketildiğinde sizi günlük
                    aktivitelere hazırlar. Bu nedenle günlük beslenmede çok
                    önemli bir yer tutmalıdır.{" "}
                  </p>
                  <p>
                    Erken çocukluk döneminden itibaren düzenli bir alışkanlık
                    haline gelen dengeli bir kahvaltının hem gelişimin hem de
                    iyi bir beslenme durumunun korunmasını desteklediği giderek
                    daha iyi bilinmektedir. İster tatlı ister tuzlu olsun ideal
                    bir kahvaltı yoktur; kahvaltının yeterliliği yiyeceklerin ve
                    seçeneklerin çeşitliliğinin birleşiminden gelir.
                    Kahvaltının, enerji ve besin öğeleri açısından dengeli
                    olabilmesi için farklı besin gruplarını içeren bir öğün
                    olmalıdır.
                  </p>
                </p>
                <img
                  src={EdMat10}
                  style={{ maxWidth: "20%", height: "auto" }}
                />
                <p className="TopicDescription-font">
                  <p>
                    Birkaç basit ipucunu takip ederek tüm ailenize uygun bir
                    kahvaltı hazırlayabilirsiniz!
                  </p>
                  <ul>
                    <li>Kahvaltı her gün yenilmelidir.</li>
                    <li>
                      Kahvaltı günlük enerji ihtiyacının yaklaşık %15-20’sini
                      sağlamalıdır.
                    </li>
                    <li>
                      Kahvaltıda tüketilecek besinlerin günlük seçimi hem besin
                      maddelerinin doğru şekilde alınması hem de kahvaltı keyfi
                      için önemlidir!
                    </li>
                    <li>
                      Süt ve yoğurt (yağ oranı düşükse daha iyi) kahvaltıda her
                      zaman bulunmalıdır, aslında günün geri kalanında bu
                      yiyecekleri tüketmek için çok az fırsat vardır. Doğru
                      kalsiyum alımını sağlamak için süt ve yoğurt alımının
                      gerekli olduğunu unutmayın.
                    </li>
                    <li>
                      Eğer hiç süt ya da yoğurt tüketmiyorsak gün boyu tokluk
                      hissini kontrol altına almak için protein kaynağı olan,
                      tercihen az yağlı ve az tuzlu besinleri kahvaltıya dahil
                      edebiliriz.
                    </li>
                    <li>
                      Kahvaltıda tahıllar da şiddetle tavsiye edilir, ancak
                      diyet lifi açısından daha zengin olan tam tahılları seçmek
                      daha iyidir. Tahıllar mutlaka kahvaltılık gevrekler
                      anlamına gelmez, aksine! Ekmek, özellikle de tam tahıllı
                      ekmek hem tuzlu hem de tatlı kahvaltılarda yer alabilecek
                      mükemmel bir alternatiftir.
                    </li>
                    <li>
                      Her gün kahvaltıda meyve tüketilmelidir. Kuruyemişler
                      dengeli bir kahvaltı için de iyi bir seçimdir, özellikle
                      de gün içinde atıştırmalık olarak kullanmıyorsanız.
                    </li>
                    <li>
                      Dikkat! Kahvaltıda tüketilen ürünler genellikle yüksek
                      oranda tuz içerir. Sofraya getirdiğiniz yiyeceğin
                      bileşimini öğrenmek için daima paketlerin üzerindeki
                      etiketleri okuyun.
                    </li>
                    <li>
                      Kahvaltıda içeceklere şeker, bal veya fruktoz
                      eklenmemelidir.
                    </li>
                    <li>
                      Kahvaltı keyifli bir an! Eğer ailenizle birlikte sofrada
                      yemeyi deneyebilirseniz bu güzel ve vazgeçilmez
                      alışkanlığı herkese öğretebilir ve güne en iyi şekilde
                      başlayabilirsiniz.
                    </li>
                    <li>
                      Kahvaltıda tatlılardan vazgeçemiyorsanız hazır
                      atıştırmalıklardan ve bisküvilerden uzak durup; ev yapımı
                      ve tam tahıllı undan yapılmış kek ve bisküvileri
                      zeytinyağı ve çok az şekerle hazırlayın. Bu, kahvaltınızı
                      iyileştirmenin iyi bir yoludur.
                    </li>
                    <li>
                      Kahvaltınızı mümkün olduğunca çeşitlendirmeye çalışın!
                      Kahvaltıda yediklerinizi çeşitlendirmeniz ve her zaman
                      aynı tür yiyecekleri tüketmemeniz önemlidir. Kahvaltıda da
                      mevsim kuralına uyun, her gün taze mevsim meyveleri ve
                      çeşitli yiyecek ve içecekleri tercih edin.
                    </li>
                  </ul>
                </p>
              </div>
            )}
            {currentLanguage === "fr" && (
              <div>
                <p className="TopicDescription-font">
                  <p>
                    Le petit-déjeuner est le premier repas de la journée et est
                    important pour tout le monde, à tout âge et dans toutes les
                    conditions de santé. Le petit-déjeuner rompt le jeûne de la
                    nuit et, consommé peu de temps après le réveil, vous prépare
                    aux activités quotidiennes. Il devrait donc occuper une
                    place très importante dans l'alimentation quotidienne.
                  </p>
                  <p>
                    Il est de plus en plus connu qu'un petit-déjeuner équilibré,
                    qui devient une habitude régulière dès le plus jeune âge,
                    favorise à la fois le développement et le maintien d'un bon
                    état nutritionnel. Il n'y a pas de petit-déjeuner idéal
                    unique, sucré ou salé, l'adéquation du petit-déjeuner vient
                    de la combinaison des aliments et de la variété des choix.
                    Le petit-déjeuner devrait être un repas qui inclut
                    différents groupes d'aliments, afin d'être équilibré en
                    termes d'énergie et de nutriments.
                  </p>
                </p>
                <img
                  src={EdMat10}
                  style={{ maxWidth: "20%", height: "auto" }}
                />
                <p className="TopicDescription-font">
                  <p>
                    En suivant quelques conseils simples, vous pouvez préparer
                    un petit-déjeuner adapté à toute la famille !
                  </p>
                  <ul>
                    <li>
                      Le petit-déjeuner devrait être consommé tous les jours.
                    </li>
                    <li>
                      Le petit-déjeuner devrait fournir environ 15 à 20 % des
                      besoins énergétiques quotidiens.
                    </li>
                    <li>
                      Le choix quotidien des aliments à consommer au
                      petit-déjeuner est important à la fois pour l'apport
                      correct de nutriments et pour le plaisir de savourer le
                      petit-déjeuner !
                    </li>
                    <li>
                      Le lait et le yaourt (de préférence à faible teneur en
                      matières grasses) devraient toujours être présents au
                      petit-déjeuner, en effet, pendant le reste de la journée,
                      il y a peu d'occasions de consommer ces aliments.
                      Rappelez-vous que la consommation de lait et de yaourt est
                      essentielle pour garantir un apport correct en calcium.
                    </li>
                    <li>
                      Si nous ne consommons définitivement pas de lait ou de
                      yaourt, nous pouvons incorporer des aliments sources de
                      protéines, de préférence avec peu de matières grasses et
                      peu de sel, pour contrôler la sensation de satiété tout au
                      long de la journée.
                    </li>
                    <li>
                      Les céréales sont également fortement recommandées au
                      petit-déjeuner, mais il vaut mieux choisir des céréales
                      complètes qui sont plus riches en fibres alimentaires. Les
                      céréales ne signifient pas nécessairement les céréales du
                      petit-déjeuner, au contraire ! Le pain, surtout le pain
                      complet, est une excellente alternative qui peut être
                      incluse dans les petits-déjeuners sucrés et salés.
                    </li>
                    <li>
                      Les fruits doivent être consommés au petit-déjeuner chaque
                      jour. Les noix sont également un bon choix pour un
                      petit-déjeuner équilibré, surtout si vous ne les utilisez
                      pas comme collation pendant la journée.
                    </li>
                    <li>
                      Attention ! Les produits consommés au petit-déjeuner
                      contiennent souvent une forte teneur en sel. Lisez
                      toujours les étiquettes des emballages, pour connaître la
                      composition des aliments que vous apportez à table.
                    </li>
                    <li>
                      Même au petit-déjeuner, le sucre, le miel ou le fructose
                      ne doivent pas être ajoutés aux boissons.
                    </li>
                    <li>
                      Le petit-déjeuner est également un moment important pour
                      l’hydratation ! Le lait, le café, le thé contribuent
                      certainement à vos besoins en eau... mais il est également
                      important de boire de l'eau au petit-déjeuner.
                    </li>
                    <li>
                      Le petit-déjeuner est un moment convivial ! Si possible,
                      essayez de le prendre à table, avec votre famille pour
                      enseigner à tous cette bonne et essentielle habitude et
                      commencer la journée de la meilleure façon.
                    </li>
                    <li>
                      Si au petit-déjeuner, vous ne pouvez vraiment pas vous
                      passer de sucreries, essayez d'éviter les snacks et les
                      biscuits et préparez des gâteaux et des biscuits complets
                      faits maison avec de l'huile d'olive et très peu de sucre.
                      C'est un bon moyen d'améliorer votre petit-déjeuner.
                    </li>
                    <li>
                      Essayez de varier votre petit-déjeuner autant que possible
                      ! Pour le petit-déjeuner aussi, il est important de varier
                      ce que vous mangez et de ne pas toujours savourer le même
                      type d'aliments. Suivez la règle de la saisonnalité au
                      petit-déjeuner également, en choisissant des fruits de
                      saison frais et en variant les aliments et les boissons
                      chaque jour.
                    </li>
                  </ul>
                </p>
              </div>
            )}
            {currentLanguage === "ar" && (
              <div>
                <p className="TopicDescription-font-arabic">
                  <p>
                    وجبة الإفطار هي الوجبة الأولى في اليوم وهي مهمة للجميع، في
                    أي عمر وفي أي حالة صحية. وجبة الإفطار تكسر صيام الليل، ويتم
                    تناولها بعد وقت قصير من الاستيقاظ، مما يؤهلك لممارسة الأنشطة
                    اليومية. لذلك يجب أن تحتل مكانًا مهمًا جدًا في النظام
                    الغذائي اليومي.
                  </p>
                  <p>
                    لقد أصبح من المعروف بشكل متزايد أن وجبة الإفطار المتوازنة،
                    التي تصبح عادة منتظمة منذ الطفولة المبكرة، تعزز النمو
                    والحفاظ على حالة غذائية جيدة. لا يوجد إفطار مثالي واحد، حلو
                    أو مالح على حد سواء، وتأتي كفاية الإفطار من مزيج الأطعمة
                    وتنوع الخيارات. يجب أن تكون وجبة الإفطار عبارة عن وجبة تتضمن
                    مجموعات مختلفة من الأطعمة، حتى تكون متوازنة من حيث الطاقة
                    والعناصر الغذائية.
                  </p>
                </p>
                <img
                  src={EdMat10}
                  style={{ maxWidth: "20%", height: "auto" }}
                />
                <p className="TopicDescription-font-arabic">
                  <p>
                    باتباع بعض النصائح البسيطة يمكنك إعداد وجبة إفطار مناسبة
                    لجميع أفراد الأسرة!
                  </p>
                  <ul>
                    <li className="TopicDescription-font-arabic">يجب تناول وجبة الإفطار يومياً.</li>
                    <li className="TopicDescription-font-arabic">
                      يجب أن توفر وجبة الإفطار حوالي 15-20% من احتياجات الطاقة
                      اليومية.
                    </li>
                    <li className="TopicDescription-font-arabic">
                      يعد الاختيار اليومي للأطعمة التي سيتم تناولها في وجبة
                      الإفطار أمرًا مهمًا للحصول على العناصر الغذائية الصحيحة
                      ولمتعة الاستمتاع بوجبة الإفطار!
                    </li>
                    <li className="TopicDescription-font-arabic">
                      يجب أن يكون الحليب والزبادي (من الأفضل أن يكون محتواهما
                      قليل الدسم) موجودين دائمًا في وجبة الإفطار، في الواقع،
                      خلال بقية اليوم هناك فرص قليلة لتناول هذه الأطعمة. تذكر أن
                      تناول الحليب والزبادي ضروري لضمان تناول الكالسيوم بشكل
                      صحيح.
                    </li>
                    <li className="TopicDescription-font-arabic">
                      إذا كنا بالتأكيد لا نستهلك الحليب أو الزبادي، فيمكننا دمج
                      الأطعمة التي تعتبر مصادر للبروتين، ويفضل أن تكون مع قليل
                      من الدهون وقليل من الملح، للتحكم في الإحساس بالشبع طوال
                      اليوم.
                    </li>
                    <li className="TopicDescription-font-arabic">
                      يوصى بشدة بتناول الحبوب أيضًا في وجبة الإفطار، ولكن من
                      الأفضل اختيار الحبوب الكاملة الغنية بالألياف الغذائية.
                      الحبوب لا تعني بالضرورة حبوب الإفطار، بل على العكس! يعد
                      الخبز، وخاصة خبز القمح الكامل، بديلاً ممتازًا يمكن ضمه في
                      وجبات الإفطار المالحة والحلوة.
                    </li>
                    <li className="TopicDescription-font-arabic">
                      يجب تناول الفاكهة في وجبة الإفطار كل يوم. تعتبر المكسرات
                      أيضًا خيارًا جيدًا لوجبة إفطار متوازنة، خاصة إذا كنت لا
                      تستخدمها خلال النهار كوجبات خفيفة.
                    </li>
                    <li className="TopicDescription-font-arabic">
                      احرص! غالبًا ما تحتوي المنتجات التي يتم تناولها في وجبة
                      الإفطار على نسبة عالية من الملح. اقرأ دائمًا الملصقات
                      الموجودة على العبوات لتعرف مكونات الطعام الذي تحضره إلى
                      المائدة.
                    </li>
                    <li className="TopicDescription-font-arabic">
                      حتى في وجبة الإفطار، لا ينبغي إضافة السكر أو العسل أو
                      الفركتوز إلى المشروبات.
                    </li>
                    <li className="TopicDescription-font-arabic">
                      الإفطار أيضًا وقت مهم للترطيب! من المؤكد أن الحليب والقهوة
                      والشاي تساهم في تلبية احتياجاتك من الماء... ولكن من المهم
                      أيضًا شرب الماء على الإفطار.
                    </li>
                    <li className="TopicDescription-font-arabic">
                      الإفطار لحظة بهيجة! إذا كان بإمكانك أن تحاول تناوله على
                      المائدة، مع عائلتك لكي تقوم بتعليم الجميع هذه العادة
                      الجيدة والأساسية وبدء اليوم بأفضل طريقة.
                    </li>
                    <li className="TopicDescription-font-arabic">
                      إذا كنت لا تستطيع التخلي عن الحلويات في وجبة الإفطار،
                      فحاول تجنب الوجبات الخفيفة والبسكويت وقم بإعداد الكعك
                      والبسكويت المصنوع من الحبوب الكاملة في المنزل باستخدام زيت
                      الزيتون والقليل من السكر. هذه طريقة جيدة لتحسين وجبة
                      الإفطار.
                    </li>
                    <li className="TopicDescription-font-arabic">
                      حاول تنويع وجبة الإفطار قدر الإمكان! أيضًا، بالنسبة
                      للإفطار، من المهم تنويع ما تأكله وعدم الاستمتاع دائمًا
                      بنفس النوع من الطعام. اتبع قاعدة الموسمية في وجبة الإفطار
                      أيضًا، واختيار الفاكهة الموسمية الطازجة وتنويع الأطعمة
                      والمشروبات كل يوم.
                    </li>
                  </ul>
                </p>
              </div>
            )}
            <button className="back-arrow" onClick={goBack}>
              <FontAwesomeIcon icon={faHome} /> {t("Educational_Material_Text")}{" "}
            </button>
          </motion.div>
        </div>
      </div>
      <div className="footer-sticky">{!isMobile && <Footer />} </div>
    </div>
  );
}

export default EdMaterialTopic10;
