import React, { useState, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import jwt_decode from "jwt-decode";
import { motion } from "framer-motion";
import SideBar from "../../components/sideBar.js";
import Footer from "../../components/footer.js";
import "../../components/sideBar.css";
import "../../components/footer.css";
import "./statistics.css";
import { useTranslation } from "react-i18next";
import "chartjs-plugin-annotation";
import LogoutAfterInactivity from "../../components/logoutAfterInactivity.js";
import { Line, Bar, PolarArea, Pie } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  RadialLinearScale,
  ArcElement,
  Tooltip,
  Legend,
  plugins,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  RadialLinearScale,
  ArcElement,
  Tooltip,
  Legend
);

function Food_groups() {
  const [weeklyNPs, setWeeklyNPs] = useState([]);
  const [loading, setLoading] = useState();
  const navigate = useNavigate();
  const [selectedDayNumber, setSelectedDayNumber] = useState(0); // State to store the selected number
  const [selectedDay, setSelectedDay] = useState();
  const { t } = useTranslation();

  const [authTokens, setAuthTokens] = useState(() =>
    localStorage.getItem("authTokens")
      ? JSON.parse(localStorage.getItem("authTokens"))
      : null
  );
  const [user, setUser] = useState(() =>
    localStorage.getItem("authTokens")
      ? jwt_decode(localStorage.getItem("authTokens"))
      : null
  );
  const [userProfileId, setUserProfileId] = useState(() =>
    localStorage.getItem("adult") ? localStorage.getItem("adult") : null
  );

  const today = new Date();
  const currentDayOfWeek = today.getDay(); // Get the current day of the week (0-6, where 0 is Sunday)
  // console.log(currentDayOfWeek);

  // Calculate the date of the Monday and Sunday
  const monday = new Date(today);
  const sunday = new Date(today);
  const nextMonday = new Date(today);

  monday.setDate(today.getDate() - currentDayOfWeek + 1);
  sunday.setDate(today.getDate() - currentDayOfWeek + 7);
  nextMonday.setDate(today.getDate() - currentDayOfWeek + 8);

  // Format the Monday and Sunday date
  const m_year = monday.getFullYear();
  const m_month = String(monday.getMonth() + 1).padStart(2, "0");
  const m_day = String(monday.getDate()).padStart(2, "0");

  const formattedMonday = `${m_year}-${m_month}-${m_day}`;

  const [isMobile, setIsMobile] = useState(false);
  useEffect(() => {
    if (user == null || user.is_active == false || userProfileId == null) {
      navigate("/");
    } else {
      const handleResize = () => {
        setIsMobile(window.innerWidth <= 768);
      };

      handleResize(); // Initial check

      window.addEventListener("resize", handleResize);
      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }
  }, []);

  useEffect(() => {
    if (user == null || user.is_active == false || userProfileId == null) {
      navigate("/");
    } else {
      setSelectedDayNumber(currentDayOfWeek);
      setSelectedDay(dayMap[currentDayOfWeek]);

      const fetchData = async () => {
        if (user == null) {
          navigate("/");
        } else {
          let timer;
          try {
            timer = setTimeout(() => {
              setLoading(true);
              window.scrollTo({
                top: 0,
                behavior: "smooth",
              });
            }, 200);
            const responseWeeklyNPs = await axios.get(
              `${userProfileId}/${formattedMonday}/getWeeklyNPs`
            );
            setWeeklyNPs(responseWeeklyNPs.data);
            //console.log(responseWeeklyNPs.data);
          } catch (error) {
            //console.error(error);
          } finally {
            clearTimeout(timer);
            setLoading(false);
          }
        }
      };
      fetchData();
    }
  }, []);

  const dayMap = {
    1: "Monday",
    2: "Tuesday",
    3: "Wednesday",
    4: "Thursday",
    5: "Friday",
    6: "Saturday",
    7: "Sunday",
  };

  const handleDaySelection = (day) => {
    // Map the selected day to a number
    let number = 0;
    switch (day) {
      case "Monday":
        number = 1;
        day = "Monday";
        break;
      case "Tuesday":
        number = 2;
        day = "Tuesday";
        break;
      case "Wednesday":
        number = 3;
        day = "Wednesday";
        break;
      case "Thursday":
        number = 4;
        day = "Thursday";
        break;
      case "Friday":
        number = 5;
        day = "Friday";
        break;
      case "Saturday":
        number = 6;
        day = "Saturday";
        break;
      case "Sunday":
        number = 7;
        day = "Sunday";
        break;
      default:
        number = 0;
    }

    setSelectedDayNumber(number);
    setSelectedDay(day);
  };

  const label1 = t("white_meat");
  const label2 = t("red_meat");
  const label3 = t("pork");
  const label4 = t("Fish/Seafood");
  const label5 = t("pulses");
  const label6 = t("Dairy");
  const label7 = t("Eggs");
  const label8 = t("pasta");
  const label9 = t("rice");
  const label10 = t("tubers");
  const label11 = t("soups");
  const label12 = t("cereals");
  const label13 = t("Fruits");
  const label14 = t("Nuts");
  const label15 = t("R_veg");
  const label16 = t("C_veg");
  const label17 = t("protein_mix");

  const data4 = {
    labels: [
      label1,
      label2,
      label3,
      label4,
      label5,
      label6,
      label7,
      label8,
      label9,
      label10,
      label11,
      label12,
      label13,
      label14,
      label15,
      label16,
      label17,
    ],
    datasets: [
      {
        label: "#",
        data: [
          ...Object.entries(weeklyNPs)
            ?.filter(([key, value]) => key === selectedDayNumber.toString())
            .map(([key, value]) => value.white_meat),
          ...Object.entries(weeklyNPs)
            ?.filter(([key, value]) => key === selectedDayNumber.toString())
            .map(([key, value]) => value.red_meat),
          ...Object.entries(weeklyNPs)
            ?.filter(([key, value]) => key === selectedDayNumber.toString())
            .map(([key, value]) => value.pork),
          ...Object.entries(weeklyNPs)
            ?.filter(([key, value]) => key === selectedDayNumber.toString())
            .map(([key, value]) => value.fish),
          ...Object.entries(weeklyNPs)
            ?.filter(([key, value]) => key === selectedDayNumber.toString())
            .map(([key, value]) => value.pulses),
          ...Object.entries(weeklyNPs)
            ?.filter(([key, value]) => key === selectedDayNumber.toString())
            .map(([key, value]) => value.dairy),
          ...Object.entries(weeklyNPs)
            ?.filter(([key, value]) => key === selectedDayNumber.toString())
            .map(([key, value]) => value.eggs),
          ...Object.entries(weeklyNPs)
            ?.filter(([key, value]) => key === selectedDayNumber.toString())
            .map(([key, value]) => value.pasta),
          ...Object.entries(weeklyNPs)
            ?.filter(([key, value]) => key === selectedDayNumber.toString())
            .map(([key, value]) => value.rice),
          ...Object.entries(weeklyNPs)
            ?.filter(([key, value]) => key === selectedDayNumber.toString())
            .map(([key, value]) => value.tubers),
          ...Object.entries(weeklyNPs)
            ?.filter(([key, value]) => key === selectedDayNumber.toString())
            .map(([key, value]) => value.soups),
          ...Object.entries(weeklyNPs)
            ?.filter(([key, value]) => key === selectedDayNumber.toString())
            .map(([key, value]) => value.cereals),
          ...Object.entries(weeklyNPs)
            ?.filter(([key, value]) => key === selectedDayNumber.toString())
            .map(([key, value]) => value.fruit),
          ...Object.entries(weeklyNPs)
            ?.filter(([key, value]) => key === selectedDayNumber.toString())
            .map(([key, value]) => value.nuts),
          ...Object.entries(weeklyNPs)
            ?.filter(([key, value]) => key === selectedDayNumber.toString())
            .map(([key, value]) => value.raw_vegetables),
          ...Object.entries(weeklyNPs)
            ?.filter(([key, value]) => key === selectedDayNumber.toString())
            .map(([key, value]) => value.cooked_vegetables),
          ...Object.entries(weeklyNPs)
            ?.filter(([key, value]) => key === selectedDayNumber.toString())
            .map(([key, value]) => value.protein_mix),
        ],
        backgroundColor: [
          "rgba(133, 240, 129, 0.6)",
          "rgba(242, 211, 109, 0.6)",
          "rgba(238, 240, 125, 0.6)",
          "rgba(126, 200, 227, 0.6)",
          "rgba(209, 138, 210, 0.6)",
          "rgba(255, 154, 139, 0.6)",
          "rgba(163, 163, 163, 0.6)",
          "rgba(255, 204, 41, 0.6)",
          "rgba(148, 200, 61, 0.6)",
          "rgba(245, 144, 66, 0.6)",
          "rgba(78, 115, 177, 0.6)",
          "rgba(231, 74, 59, 0.6)",
          "rgba(241, 91, 108, 0.6)",
          "rgba(182, 139, 70, 0.6)",
          "rgba(97, 192, 191, 0.6)",
          "rgba(87, 187, 138, 0.6)",
          "rgba(207, 219, 0, 0.6)",
        ],

        hoverOffset: 7,
      },
    ],
  };

  const config4 = {
    type: "pie",
    options: {
      scales: {
        y: {
          beginAtZero: true,
        },
      },
    },
    plugins: {
      legend: {
        display: true,
        position: "right",
      },
      maintainAspectRatio: false,
    },
  };

  LogoutAfterInactivity();

  return (
    <div>
      <SideBar />
      <div className="rightpart">
        {loading && (
          <div class="text-center">
            <div class="spinner-border" role="status">
              <span class="sr-only">{t("Loading")}...</span>
            </div>
          </div>
        )}
        <br></br>
        <br></br>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1, ease: "easeOut" }}
        >
          <div className="title-font" style={{ textAlign: "center" }}>
            {t("food_categories")}
          </div>
          <div className="desc-font" style={{ textAlign: "center" }}>
            {t("food_categories_desc")}
          </div>
          <br></br>
          <br></br>
          <div className="dropdown-container">
            <div className="dropdown">
              {/* <button className="dropbtn">{t(`${selectedDay}`)}</button> */}
              <button
                className="btn btn-link dropdown-toggle"
                style={{
                  backgroundColor: "pink", // Set your desired background color
                  textDecoration: "none",
                  color: "black",
                }}
              >
                {t(`${selectedDay}`)}
              </button>
              <div className="dropdown-content">
                <a href="#" onClick={() => handleDaySelection("Monday")}>
                  {t("Monday")}
                </a>
                <a href="#" onClick={() => handleDaySelection("Tuesday")}>
                  {t("Tuesday")}
                </a>
                <a href="#" onClick={() => handleDaySelection("Wednesday")}>
                  {t("Wednesday")}
                </a>
                <a href="#" onClick={() => handleDaySelection("Thursday")}>
                  {t("Thursday")}
                </a>
                <a href="#" onClick={() => handleDaySelection("Friday")}>
                  {t("Friday")}
                </a>
                <a href="#" onClick={() => handleDaySelection("Saturday")}>
                  {t("Saturday")}
                </a>
                <a href="#" onClick={() => handleDaySelection("Sunday")}>
                  {t("Sunday")}
                </a>
              </div>
            </div>
          </div>
          <br></br>
          <br></br>
          <div className="chart-container">
            <div className="pie-area-chart">
              <Pie data={data4} config={config4} />
            </div>
          </div>
        </motion.div>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <div className="footer-fixed-2">{!isMobile && <Footer />} </div>
      </div>
    </div>
  );
}

export default Food_groups;
