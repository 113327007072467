import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";
import { Alert } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.css";
import "./RegisterUserProfilePage.css";
import Footer2 from "../components/footer2.js";
import jwt_decode from "jwt-decode";
import Footer from "../components/footer";
import image from "../images/graphics/STH - LOGO.png";
import backgroundImage from "../images/graphics/about.jpg";
import { useTranslation } from "react-i18next";
import LogoutAfterInactivity from "../components/logoutAfterInactivity";
import { motion } from "framer-motion";

function RegisterUserProfilePage() {
  const [height, setHeight] = useState("");
  const [weight, setWeight] = useState("");
  const [gender, setGender] = useState("");
  const [yearOfBirth, setYearOfBirth] = useState("");
  const [PAL, setPAL] = useState("");
  // const [halal, setHalal] = useState(false);
  const [diary, setDiary] = useState(false);
  // const [eggs, setEggs] = useState(false);
  // const [fish, setFish] = useState(false);
  // const [nuts, setNuts] = useState(false);
  const [country, setCountry] = useState("");
  const [spain, setSpain] = useState(false);
  const [turkey, setTurkey] = useState(false);
  const [morocco, setMorocco] = useState(false);
  const [countryLanguageCode, setCountryLanguageCode] = useState("");
  const location = useLocation();
  const [infoMessage, setInfoMessage] = useState("");
  const [isMobile, setIsMobile] = useState(false);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const [authTokens, setAuthTokens] = useState(() =>
    localStorage.getItem("authTokens")
      ? JSON.parse(localStorage.getItem("authTokens"))
      : null
  );
  const [user, setUser] = useState(() =>
    localStorage.getItem("authTokens")
      ? jwt_decode(localStorage.getItem("authTokens"))
      : null
  );
  const { t } = useTranslation();

  const languages = {
    en: {
      name: t("English"),
    },
    fr: {
      name: t("French"),
    },
    sp: {
      name: t("Spanish"),
    },
    tr: {
      name: t("Turkish"),
    },
  };

  useEffect(() => {
    if (user === null || user.is_active === false) {
      navigate("/");
    } else {
      if (location.state && location.state.infoMessage) {
        setInfoMessage(location.state.infoMessage);
      }
    }
  }, []);

  useEffect(() => {
    if (user === null || user.is_active === false) {
      navigate("/");
    } else {
      handleCuisineLanguage();
    }
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    let timer;
    try {
      timer = setTimeout(() => {
        setLoading(true);
        window.scrollTo({
          top: 0,
          behavior: "smooth", // Use "smooth" for smooth scrolling behavior
        });
      }, 1000); // 1000 milliseconds (1 second)
      const response = await axios.post("IdUserProfile/" + user.user_id + "/", {
        height: height,
        weight: weight,
        gender: gender,
        yob: yearOfBirth,
        pal: PAL,
        // halal: halal,
        diary: diary,
        // eggs: eggs,
        // fish: fish,
        // nuts: nuts,
        country: country,
        countryLanguageCode: countryLanguageCode,
      });

      if (response.status === 200) {
        try {
          const response4 = await axios.post(
            "userProfileHistory/" + user.user_id + "/",
            {
              height: height,
              weight: weight,
              gender: gender,
              yob: yearOfBirth,
              pal: PAL,
              // halal: halal,
              diary: diary,
              // eggs: eggs,
              // fish: fish,
              // nuts: nuts,
              country: country,
              countryLanguageCode: countryLanguageCode,
            }
          );
          if (response4.status === 200) {
            navigate("/home");
          }
        } catch (error) {
          // console.error("Error in form submission:", error);
        }
      }
    } catch (error) {
      // console.error("Error in form submission:", error);
    } finally {
      clearTimeout(timer);
      setLoading(false);
    }
  };

  // Function to handle button click
  const handleCuisineLanguage = () => {
    // Perform action based on the selected country
    switch (country) {
      case "Spain":
        // Action for Spain cuisine
        setSpain(true);
        setTurkey(false);
        // setMorocco(false);
        break;
      case "Turkey":
        // Action for Turkey cuisine
        setSpain(false);
        setTurkey(true);
        // setMorocco(false);
        break;
      // case "Morocco":
      //   // Action for Morocco cuisine
      //   setSpain(false);
      //   setTurkey(false);
      //   setMorocco(true);
      //   break;
    }
  };

  LogoutAfterInactivity();

  return (
    <div className="back-color">
      <div className="page-container">
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {infoMessage && <Alert variant="warning">{infoMessage}</Alert>}
        </div>
        <motion.div
          initial={{ y: "100%" }}
          animate={{ y: "0%" }}
          transition={{ duration: 1.5, ease: "easeOut" }}
          className="user-page"
        >
          <img src={image} style={{ maxWidth: "100%", height: "auto" }} />
          <form onSubmit={handleSubmit}>
            <br></br>

            <div className="new-user-profile-grid">
              <div>
                <div className="ruser-profile-font">
                  {t("Physical_Characteristics")}{" "}
                </div>
                <br></br>
                <div>
                  <label className="ruser-font">{t("Gender")}:</label>
                  <label style={{ display: "block" }}>
                    {" "}
                    <input
                      type="radio"
                      name="gender"
                      value="male"
                      checked={gender === "male"}
                      onChange={(event) => setGender(event.target.value)}
                      className="ruser-font"
                      required
                    />{" "}
                    {t("Male")}
                  </label>
                  <label style={{ display: "block" }}>
                    <input
                      type="radio"
                      name="gender"
                      value="female"
                      checked={gender === "female"}
                      onChange={(event) => setGender(event.target.value)}
                      className="ruser-font"
                      required
                    />{" "}
                    {t("Female")}
                  </label>
                </div>
                <br></br>
                <div>
                  <label className="ruser-font">{t("Year_of_Birth")}</label>
                  <input
                    type="number"
                    name="yearOfBirth"
                    value={yearOfBirth}
                    onChange={(event) => setYearOfBirth(event.target.value)}
                    className="upline-field"
                    min="1900"
                    max="2030"
                    required
                  />
                </div>
                <div>
                  <label className="ruser-font">{t("Height_(m)")}</label>
                  <input
                    type="number"
                    name="height"
                    value={height}
                    onChange={(event) => setHeight(event.target.value)}
                    className="upline-field"
                    min="0.00"
                    max="3.00"
                    step="0.01"
                    required
                  />
                </div>
                <div>
                  <label className="ruser-font">{t("Weight_(kg)")}</label>
                  <input
                    type="number"
                    name="weight"
                    value={weight}
                    onChange={(event) => setWeight(event.target.value)}
                    className="upline-field"
                    min="0"
                    max="400"
                    step="0.1"
                    required
                  />
                </div>

                <br></br>
                <div>
                  <label className="ruser-profile-font">
                    {t("Physical_Activity_Level_(PAL)")}:
                  </label>
                  <label style={{ display: "block" }}>
                    <input
                      type="radio"
                      name="PAL"
                      value="Sedentary"
                      checked={PAL === "Sedentary"}
                      onChange={(event) => {
                        setPAL(event.target.value);
                      }}
                      required
                    />{" "}
                    {t("Sedentary")}{" "}
                    <em style={{ fontSize: "small" }}>{t("Sedentary_info")}</em>
                  </label>
                  <label style={{ display: "block" }}>
                    <input
                      type="radio"
                      name="PAL"
                      value="Lightly active"
                      checked={PAL === "Lightly active"}
                      onChange={(event) => {
                        setPAL(event.target.value);
                      }}
                      required
                    />{" "}
                    {t("Lightly active")}{" "}
                    <em style={{ fontSize: "small" }}>
                      {t("Lightly_active_info")}
                    </em>
                  </label>
                  <label style={{ display: "block" }}>
                    <input
                      type="radio"
                      name="PAL"
                      value="Moderately active"
                      checked={PAL === "Moderately active"}
                      onChange={(event) => {
                        setPAL(event.target.value);
                      }}
                      required
                    />{" "}
                    {t("Moderately active")}{" "}
                    <em style={{ fontSize: "small" }}>
                      {t("Moderately_active_info")}
                    </em>
                  </label>
                  <label style={{ display: "block" }}>
                    <input
                      type="radio"
                      name="PAL"
                      value="Very active"
                      checked={PAL === "Very active"}
                      onChange={(event) => {
                        setPAL(event.target.value);
                      }}
                      required
                    />{" "}
                    {t("Very active")}{" "}
                    <em style={{ fontSize: "small" }}>
                      {t("Very_active_info")}
                    </em>
                  </label>
                  <label style={{ display: "block" }}>
                    <input
                      type="radio"
                      name="PAL"
                      value="Extra active"
                      checked={PAL === "Extra active"}
                      onChange={(event) => {
                        setPAL(event.target.value);
                      }}
                      required
                    />{" "}
                    {t("Extra active")}{" "}
                    <em style={{ fontSize: "small" }}>
                      {t("Extra_active_info")}
                    </em>
                  </label>
                </div>
              </div>
              <div>
                <div>
                  <div>
                    <label className="ruser-profile-font">
                      {t("Allergies")}
                    </label>
                  </div>
                  <br></br>
                  <div className="allergies-section">
                    <div className="checkbox-options">
                      <div>
                        <label>
                          <input
                            type="checkbox"
                            checked={diary}
                            onChange={(event) => setDiary(!diary)}
                          />{" "}
                          {t("Dairy")}
                        </label>
                      </div>
                      {/* <div>
                        <label>
                          <input
                            type="checkbox"
                            checked={eggs}
                            onChange={(event) => setEggs(!eggs)}
                          />{" "}
                          {t("Eggs")}
                        </label>
                      </div> */}
                      {/* <div>
                        <label>
                          <input
                            type="checkbox"
                            checked={fish}
                            onChange={(event) => setFish(!fish)}
                          />{" "}
                          {t("Fish/Seafood")}
                        </label>
                      </div> */}
                      {/* <div>
                        <label>
                          <input
                            type="checkbox"
                            checked={nuts}
                            onChange={(event) => setFish(!nuts)}
                          />{" "}
                          {t("Nuts")}
                        </label>
                      </div> */}
                    </div>
                  </div>
                </div>
                <br></br>
                {/* <div>
                  <label className="ruser-profile-font">
                    {t("Dietary_Choices")}
                  </label>
                </div> */}
                {/* <br></br>
                <div className="allergies-section">
                  <div className="checkbox-options">
                    <label>
                      <input
                        type="checkbox"
                        checked={halal}
                        onChange={(event) => setHalal(!halal)}
                      />{" "}
                      {t("Halal")}
                    </label>
                  </div>
                </div> */}
                {/* <br></br> */}
                <div>
                  <label className="user-profile-font">
                    {t("Choose_national_cuisine")}:
                  </label>
                </div>
                <label style={{ display: "block" }}>
                  <input
                    type="radio"
                    name="country"
                    value="Spain"
                    checked={country === "Spain"}
                    onChange={(event) => {
                      const userConfirmed = window.confirm(
                        t("country_update_message")
                      );
                      if (userConfirmed) {
                        setCountry(event.target.value);
                      }
                    }}
                    onClick={handleCuisineLanguage}
                    className="user-font"
                    required
                  />{" "}
                  {t("Spain")}
                </label>
                <label style={{ display: "block" }}>
                  <input
                    type="radio"
                    name="country"
                    value="Turkey"
                    checked={country === "Turkey"}
                    onChange={(event) => {
                      const userConfirmed = window.confirm(
                        t("country_update_message")
                      );
                      if (userConfirmed) {
                        setCountry(event.target.value);
                      }
                    }}
                    onClick={handleCuisineLanguage}
                    className="user-font"
                    required
                  />{" "}
                  {t("Turkey")}
                </label>
                {/* <label style={{ display: "block" }}>
                  <input
                    type="radio"
                    name="country"
                    value="Morocco"
                    checked={country === "Morocco"}
                    onChange={(event) => {
                      const userConfirmed = window.confirm(
                        t("country_update_message")
                      );
                      if (userConfirmed) {
                        setCountry(event.target.value);
                      }
                    }}
                    onClick={handleCuisineLanguage}
                    className="user-font"
                    disabled
                  />{" "}
                  {t("Morocco")}
                </label> */}
                <br></br>
                <div>
                  {country && (
                    <div>
                      <label className="user-profile-font">
                        {t("daily_plans")}:
                      </label>
                      {spain && (
                        <label style={{ display: "block" }}>
                          <input
                            type="radio"
                            name="countryLanguage"
                            value="sp"
                            onChange={(event) => {
                              setCountryLanguageCode(event.target.value);
                            }}
                            className="user-font"
                            required
                          />{" "}
                          {languages["sp"]?.name || ""}
                        </label>
                      )}
                      {turkey && (
                        <label style={{ display: "block" }}>
                          <input
                            type="radio"
                            name="countryLanguage"
                            value="tr"
                            onChange={(event) => {
                              setCountryLanguageCode(event.target.value);
                            }}
                            className="user-font"
                            required
                          />{" "}
                          {languages["tr"]?.name || ""}
                        </label>
                      )}
                      {/* {morocco && (
                        <label style={{ display: "block" }}>
                          <input
                            type="radio"
                            name="countryLanguage"
                            value="fr"
                            onChange={(event) => {
                              setCountryLanguageCode(event.target.value);
                            }}
                            className="user-font"
                            required
                          />{" "}
                          {languages["fr"]?.name || ""}
                        </label>
                      )} */}
                      <label style={{ display: "block" }}>
                        <input
                          type="radio"
                          name="countryLanguage"
                          value="en"
                          onChange={(event) => {
                            setCountryLanguageCode(event.target.value);
                          }}
                          className="user-font"
                          required
                        />{" "}
                        {t("English")}
                      </label>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <br></br>
            <br></br>
            <div>
              <input
                type="submit"
                value={t("Register")}
                className="button"
                style={{ float: "right", textDecoration: "none" }}
              />
            </div>
          </form>
        </motion.div>
      </div>

      <div className="footer-sticky">{!isMobile && <Footer />}</div>
    </div>
  );
}

export default RegisterUserProfilePage;

{
  /* <div
        className="body"
        style={{
          backgroundImage: `url(${backgroundImage})`,
          backgroundSize: "cover", // This will resize the image to cover the entire element
          backgroundRepeat: "no-repeat", // This will prevent the image from repeating
          backgroundPosition: "center", // This will position the image at the center of the element
        }}
      >
</div> */
}
