import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import LoginPage from "./pages/loginPage";
import RegisterPage from "./pages/registerPage";
import NetflixPage from "./pages/netflixPage";
import HomePage from "./pages/homePage";
import UserProfilePage from "./pages/userProfilePage";
import ForgotPasswordPage from "./pages/resetpassword/forgotPasswordPage";
import ResetPasswordPage from "./pages/resetpassword/resetPasswordPage";
import ResetPasswordMessage from "./pages/resetpassword/resetPasswordMessage";
import AdultsForm from "./pages/adultsRegistration/adultsForm";
import RegisterUserProfilePage from "./pages/RegisterUserProfilePage";
import AboutPage from "./pages/aboutPage";
import Stats from "./pages/statistics";
import Food_groups from "./pages/charts/food_groups";
import Energy from "./pages/charts/energy";
import Micronutrients from "./pages/charts/micronutrients";
import Macronutrients from "./pages/charts/macronutrients";
import Weight from "./pages/charts/weight";
import ActivationPage from "./pages/activation/activationPage";
import ActivationMessage from "./pages/activation/activationMessage";
import ExpirePage from "./pages/activation/expirePage";
import ExpirePassworPage from "./pages/resetpassword/expirePasswordPage";
import TestAPI from "./pages/testAPI";
import ChildHomePage from "./pages/childHomePage";
import ChildProfilePage from "./pages/childProfilePage";
import AboutPage2 from "./pages/aboutPage2";
import ManageProfiles from "./pages/manageProfiles";
import GamePage from "./pages/game";
import GamePage2 from "./pages/game2";
import MaterialPage from "./pages/material";
import MaterialPage2 from "./pages/material2";

import EdMaterialTopic0 from "./pages/Topics/EdMaterial0Page.js";
import EdMaterialTopic1 from "./pages/Topics/EdMaterial1Page.js";
import EdMaterialTopic2 from "./pages/Topics/EdMaterial2Page.js";
import EdMaterialTopic3 from "./pages/Topics/EdMaterial3Page.js";
import EdMaterialTopic4 from "./pages/Topics/EdMaterial4Page.js";
import EdMaterialTopic5 from "./pages/Topics/EdMaterial5Page.js";
import EdMaterialTopic6 from "./pages/Topics/EdMaterial6Page.js";
import EdMaterialTopic7 from "./pages/Topics/EdMaterial7Page.js";
import EdMaterialTopic8 from "./pages/Topics/EdMaterial8Page.js";
import EdMaterialTopic9 from "./pages/Topics/EdMaterial9Page.js";
import EdMaterialTopic10 from "./pages/Topics/EdMaterial10Page.js";
import EdMaterialTopic11 from "./pages/Topics/EdMaterial11Page.js";
import EdMaterialTopic12 from "./pages/Topics/EdMaterial12Page.js";
import EdMaterialTopic13 from "./pages/Topics/EdMaterial13Page.js";
import EdMaterialTopic14 from "./pages/Topics/EdMaterial14Page.js";
import EdMaterialTopic15 from "./pages/Topics/EdMaterial15Page.js";
import EdMaterialTopic16 from "./pages/Topics/EdMaterial16Page.js";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<LoginPage />} />
        <Route path="/register" element={<RegisterPage />} />
        <Route path="/accounts" element={<NetflixPage />} />
        {/* <Route path="/manageProfiles" element={<ManageProfiles />} /> */}

        <Route exact path="/home" element={<HomePage />} />
        <Route path="/childHome" element={<ChildHomePage />} />
        <Route path="form" element={<AdultsForm />} />
        <Route path="/rUserProfile" element={<RegisterUserProfilePage />} />
        <Route path="/userProfile" element={<UserProfilePage />} />
        <Route path="/childProfile" element={<ChildProfilePage />} />
        <Route path="/about" element={<AboutPage />} />
        <Route path="/about2" element={<AboutPage2 />} />
        <Route path="/educationalMaterials" element={<MaterialPage />} />
        <Route path="/educationalMaterials2" element={<MaterialPage2 />} />
        <Route path="/digitamiGame" element={<GamePage />} />
        <Route path="/digitamiGame2" element={<GamePage2 />} />

        <Route path="/stats" element={<Stats />} />
        <Route path="/charts/food_groups" element={<Food_groups />} />
        <Route path="/charts/energy" element={<Energy />} />
        <Route path="/charts/micronutrients" element={<Micronutrients />} />
        <Route path="/charts/macronutrients" element={<Macronutrients />} />
        <Route path="/charts/weight" element={<Weight />} />

        <Route path="/forgotPassword" element={<ForgotPasswordPage />} />
        <Route path="/resetpassword/:token" element={<ResetPasswordPage />} />
        <Route
          path="/resetpasswordmessage"
          element={<ResetPasswordMessage />}
        />

        <Route
          path="/activationMessage/:token"
          element={<ActivationMessage />}
        />
        <Route path="/activationPage" element={<ActivationPage />} />
        <Route path="/expirePage" element={<ExpirePage />} />
        <Route path="/expirePasswordPage" element={<ExpirePassworPage />} />

        <Route path="/testAPI" element={<TestAPI />} />

        <Route path="/Topic0" element={<EdMaterialTopic0 />} />
        <Route path="/Topic1" element={<EdMaterialTopic1 />} />
        <Route path="/Topic2" element={<EdMaterialTopic2 />} />
        <Route path="/Topic3" element={<EdMaterialTopic3 />} />
        <Route path="/Topic4" element={<EdMaterialTopic4 />} />
        <Route path="/Topic5" element={<EdMaterialTopic5 />} />
        <Route path="/Topic6" element={<EdMaterialTopic6 />} />
        <Route path="/Topic7" element={<EdMaterialTopic7 />} />
        <Route path="/Topic8" element={<EdMaterialTopic8 />} />
        <Route path="/Topic9" element={<EdMaterialTopic9 />} />
        <Route path="/Topic10" element={<EdMaterialTopic10 />} />
        <Route path="/Topic11" element={<EdMaterialTopic11 />} />
        <Route path="/Topic12" element={<EdMaterialTopic12 />} />
        <Route path="/Topic13" element={<EdMaterialTopic13 />} />
        <Route path="/Topic14" element={<EdMaterialTopic14 />} />
        <Route path="/Topic15" element={<EdMaterialTopic15 />} />
        <Route path="/Topic16" element={<EdMaterialTopic16 />} />
      </Routes>
    </Router>
  );
}

export default App;
